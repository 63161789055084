@font-face {
  font-family: 'Calibri Light';
  src: url('../fonts/Calibri-Light.eot');
  src: url('../fonts/Calibri-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Calibri-Light.woff2') format('woff2'),
  url('../fonts/Calibri-Light.woff') format('woff'),
  url('../fonts/Calibri-Light.ttf') format('truetype'),
  url('../fonts/Calibri-Light.svg#Calibri-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri Regular';
  src: url('../fonts/Calibri.eot');
  src: url('../fonts/Calibri.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Calibri.woff2') format('woff2'),
  url('../fonts/Calibri.woff') format('woff'),
  url('../fonts/Calibri.ttf') format('truetype'),
  url('../fonts/Calibri.svg#Calibri') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri Bold';
  src: url('../fonts/Calibri-Bold.eot');
  src: url('../fonts/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Calibri-Bold.woff2') format('woff2'),
  url('../fonts/Calibri-Bold.woff') format('woff'),
  url('../fonts/Calibri-Bold.ttf') format('truetype'),
  url('../fonts/Calibri-Bold.svg#Calibri-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$calibri_light: 'Calibri Light';
$calibri_regular: 'Calibri Regular';
$calibri_bold: 'Calibri Bold';