//Template "News"
.template-news {
  &__news {
    position: relative;
    z-index: 2;
  }
}

.news {
  &__image {
    @include toRem(height, 411);

    .news-others-items & {
      @include toRem(height, 198);
    }
  }
}

.layouts {
  #layout {
    &-template-news {
      &-0 {
        top: 250px;
        left: 87%;
      }

      &-1 {
        top: 850px;
        left: -90px;

        @include media-breakpoint-up(xxl) {
          top: 900px;
          left: -20px;
        }
      }

      &-2 {
        bottom: 200px;
        right: 20px;
      }
    }
  }
}