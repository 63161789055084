// Bloc "Informations 1"
.infos_1 {
  &__infos-info {
    &-icon {
      @include toRem(width, 70);
      @include toRem(height, 70);
    }

    &-text {
      position: relative;

      &:before {
        content: '';
        position: relative;
        display: inline-flex;
        background-color: $orange;
        border-radius: 50%;
        @include toRem(width, 5);
        @include toRem(height, 5);
        @include toRem(top, -2);
        @include toRem(margin-right, 5);
      }
    }
  }
}