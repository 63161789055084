// Bloc "Grille de logos"
.logos_grid,
.logos_grid_2{
  &__logos {
    &-item {
      width: 100%;

      @include media-breakpoint-up(md) {
        @include toRem(height, 190);
        width: 50%;
      }

      @include media-breakpoint-up(xl) {
        width: 20%;
      }

      img {
        @include toRem(max-height, 70);

        @include media-breakpoint-up(md) {
          @include toRem(max-height, 120);
        }
      }
    }
  }
}

.logos_grid_2 {
  &__logos {
    &-item {
      width: 100%;

      @include media-breakpoint-up(md) {
        @include toRem(height, 190);
        width: 50%;
      }

      @include media-breakpoint-up(xl) {
        width: 25%;
      }

      img {
        @include toRem(max-height, 70);

        @include media-breakpoint-up(md) {
          @include toRem(max-height, 120);
        }
      }
    }
  }
}