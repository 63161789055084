// Bloc "Slider de projets"
.projects_slider {
  &__items {
    display: flex;
  }

  &__item {
    &-box {
      &-text {
        position: relative;

        &:before {
          content: '';
          position: relative;
          display: inline-flex;
          @include toRem(top, -3);
          @include toRem(margin-right, 5);
          @include toRem(width, 5);
          @include toRem(height, 5);
          border-radius: 50%;
          background-color: $orange;
        }
      }
    }
  }
}