// Bloc "Label"
.label {
  &__image {
    @include toRem(width, 185);
    @include toRem(height, 185);
    box-shadow: 0 4px 8px transparentize($black, .85);

    img {
      @include toRem(width, 150);
      @include toRem(height, 150);
    }
  }
}