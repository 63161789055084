//Single "Training"
.training {
  &-header {
    &__description {
      em {
        @include toRem(font-size, 16);
        color: $gray;
      }
    }

    &__video {
      @include toRem(height, 415);

      @include media-breakpoint-up(xxl) {
        @include toRem(height, 480);
      }

      .plyr {
        height: 100%;
        border: 0;
        border-radius: var(--bs-border-radius-sm);

        .plyr__video-wrapper {
          padding-top: 0;
          height: 100%;
        }
      }
    }
  }

  &-testimonials {
    &__items-item {
      .plyr,
      &-image {
        @include toRem(height, 485);
      }

      .plyr {
        border: 0;
        width: 100%;

        .plyr__control {
          background: $orange;
          opacity: 1;
          @include transition(background 600ms);

          @include media-breakpoint-up(lg) {
            padding: calc(var(--plyr-control-spacing, 10px) * 2.25);
          }

          svg {
            @include media-breakpoint-up(lg) {
              height: var(--plyr-control-icon-size, 35px);
              width: var(--plyr-control-icon-size, 35px);
            }
          }

          &:hover {
            background: $blue;
          }
        }

        &.plyr--playing {
          .plyr__poster {
            display: none;
          }
        }

        .plyr__progress__container,
        .plyr__time,
        .plyr__volume,
        .plyr__menu {
          display: none;
        }

        .plyr__video-wrapper {
          padding-bottom: 0;
          padding-top: 0;
          height: 100%;

          .plyr__poster {
            background-size: cover;
            transform: scale(1.01);
          }
        }
      }
    }

    @include media-breakpoint-up(xl) {
      &__slider-nav {
        position: relative;
        @include toRem(top, -175);
      }
    }
  }

  &__choice_establishment {
    display: none;
    overflow: auto;
    @include toRem(top, 35);
    @include toRem(height, 80);

    &.active {
      display: block;
    }
  }
}

.more_establishment {
  &.active {
    .icon {
      @include transform(rotate(45deg));
    }
  }

  .icon {
    @include transition(transform 225ms);
  }
}