//Template "Contact"
.template-contact {
  form {
    .row {
      @include toRem(margin-bottom, 40);

      label {
        position: relative;
        display: inline-flex;
        @include toRem(font-size, 18);
        @include toRem(margin-bottom, 10);

        > sup {
          position: relative;
          @include toRem(top, 13);
          @include toRem(margin-left, 2);
          @include toRem(font-size, 18);
        }
      }

      > * > p {
        margin-bottom: 0;
        overflow: hidden;
      }

      > .col-12 > p {
        display: flex;
        flex-direction: column;

        .wpcf7-form-control-wrap {
          width: 100%;

          input {
            position: relative;
            width: 100%;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid #707070;
            outline: 0;

            &,
            &::placeholder {
              color: $gray;
              @include toRem(font-size, 18);
            }

            &[type="checkbox"] {
              @include toRem(width, 19);
              @include toRem(height, 19);
              @include toRem(top, 2);
              @include toRem(margin-right, 15);
              border-radius: 3px;
            }
          }
        }
      }

      &.civility {
        @include toRem(margin-bottom, 45);
        font-family: $calibri_bold;

        > .col-12 > p {
          flex-direction: row;
        }

        label {
          margin-bottom: 0;
          font-family: $calibri_regular;
        }

        .wpcf7-radio {
          .wpcf7-list-item {
            @include toRem(margin-left, 35);

            label {
              display: flex;
              align-items: center;
            }

            input {
              position: relative;
              appearance: none;
              outline: 0;
              @include toRem(top, -1);
              @include toRem(margin-right, 15);
              border: 0;
              @include toRem(height, 11);

              &:checked {
                &:after {
                  opacity: 1;
                }
              }

              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: inline-flex;
                @include toRem(width, 11);
                height: 100%;
                background-color: $white;
                border: 1px solid $black;
                border-radius: 50%;
              }

              &:after {
                content: '';
                position: absolute;
                @include toRem(top, 3);
                @include toRem(left, 3);
                opacity: 0;
                @include toRem(width, 5);
                @include toRem(height, 5);
                background-color: $black;
                border-radius: 50%;
              }
            }
          }
        }
      }

      &.select-wrapper {
        border-bottom: 1px solid #707070;
        background-repeat: no-repeat;
        background-position: top 5px right calc(var(--bs-gutter-x) * 0.5);
        background-size: 17px;
        background-image: url("../img/icons/icon-arrow-down-black.svg");
      }

      &.other,
      &.establishment-name,
      &.society-name,
      &.siret {
        border-bottom: 1px solid #707070;
      }

      &.society-name,
      &.establishment-name {
        label {
          @include toRem(font-size, 16);
        }
      }

      &.object-message {
        border: 1px solid #707070;
        border-radius: 7px;
        padding: 15px;
        align-items: center;

        label {
          margin-bottom: 0;
        }
      }

      &.message textarea {
        width: 100%;
        resize: none;
        padding: 25px 20px;
        @include toRem(height, 242);
        border: 1px solid #707070;
        border-radius: 7px;

        &,
        &::placeholder {
          color: $gray;
          @include toRem(font-size, 18);
        }
      }

      /*&.file-wrapper {
        .wpcf7-form-control-wrap {
          border: 1px solid #707070;
          border-radius: 7px;
          height: 77px;
          display: flex;
          flex-wrap: nowrap;
          width: 100%;

          &::before {
            content: 'Ajouter une pièce jointe';
            display: inline-flex;
            align-items: center;
            background: #EAEAEA;
            border-right: 1px solid #707070;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            padding: 0 20px;
            height: 100%;
            width: 220px;
            outline: none;
            white-space: nowrap;
            -webkit-user-select: none;
            cursor: pointer;
            font-size: 18px;
            color: $black;
            pointer-events: none;
            z-index: 0;
            position: relative;
          }

          .wpcf7-file {
            padding-top: 21px;
            padding-left: 97px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            border: 0;

            &::-webkit-file-upload-button {
              visibility: hidden;
            }
          }
        }
      }*/

      .wpcf7-text {
        outline: 0;
        border: 0;
      }

      .wpcf7-select,
      .wpcf7-text {
        color: $gray;
        @include toRem(font-size, 18);
      }

      .wpcf7-select {
        padding-right: 3rem !important;
        width: 100% !important;
      }

      .wpcf7-acceptance {
        .wpcf7-list-item {
          margin: 0;

          input[name="acceptance"] {
            position: relative;
            appearance: none;
            outline: 0;
            @include toRem(top, -1);
            @include toRem(margin-right, 8);
            border: 0;

            &:checked {
              &:after {
                transform: scale(1) translate(-50%, -50%);
              }
            }

            &:before {
              content: '';
              position: relative;
              display: inline-flex;
              @include toRem(width, 19);
              @include toRem(height, 19);
              background-color: $white;
              border: 1px solid $black;
              border-radius: 3px;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform-origin: center;
              transform: scale(0) translate(-50%, -50%);
              @include toRem(width, 19);
              @include toRem(height, 19);
              background-image: url("../img/icons/check.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: 12px;
              background-color: $black;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
}