// Bloc "Logos"
.logos {
  &__logos {
    &-logo {
      @include toRem(width, 110);
      @include toRem(height, 110);

      @include media-breakpoint-up(md) {
        @include toRem(width, 200);
        @include toRem(height, 200);
      }
    }
  }
}