//Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        ipad: 1025px,
        xl: 1280px,
        laptop: 1281px,
        xxl: 1440px,
        big_laptop: 1441px,
        laptop_xxl: 1700px
);

//Containers
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1200px,
        xl: 1440px,
        xxl: 1600px
);

//Spacers
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer*.25),
                        2: ($spacer*.4),
                        3: ($spacer*.8),
                        35: ($spacer*1.25),
                        4: ($spacer*1.875),
                        45: ($spacer*2.25),
                        5: ($spacer*3),
                        55: ($spacer*3.5),
                        6: ($spacer*3.85),
                        65: ($spacer*5),
                        68: ($spacer*5.5),
                        7: ($spacer*6.5),
                        8: ($spacer*8),
                        9: ($spacer*10),
                        95: ($spacer*12),
                        10: ($spacer*14),
                        11: ($spacer*21),
                        12: ($spacer*16),
                        13: ($spacer*17),
                        14: ($spacer*18),
                        15: ($spacer*19),
                        16: ($spacer*20),
                ),
                $spacers
);

//Font-sizes
$display-font-sizes: (
        1: 5rem,
        2: 4.5rem,
        3: 3.9375rem,
        4: 3.4375rem,
        5: 3rem,
        6: 2.5rem,
        7: 2rem,
        8: 1.625rem,
        9: 1.35rem,
        10: 0.75rem,
        11: 1.625rem,
);

//Position
$position-values: (
        0: 0,
        20: 20%,
        50: 50%,
        100: 100%
);

//Width
$utilities: (
        "width": (
                property: width,
                class: w,
                values: (
                        25: 25%,
                        50: 50%,
                        65: 65%,
                        70: 70%,
                        75: 75%,
                        85: 85%,
                        100: 100%,
                        120: 120%,
                        auto: auto
                )
        ),
        "height": (
                property: height,
                class: h,
                values: (
                        25: 25%,
                        50: 50%,
                        70: 70%,
                        75: 75%,
                        85: 85%,
                        100: 100%,
                        auto: auto
                )
        ),
);

//Line-height
$line-height-base: 1.65;
$line-height-sm: 1;
$line-height-lg: 1.65;

//Buttons
.nav-link, a {
  color: black;
  text-decoration: none;
  @include transition(all 225ms);
}

.btn-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: left;
  padding: 0;
  outline: 0;
  text-decoration: none;
  @include toRem(font-size, 20);
  font-family: $calibri_bold;
  color: $black;
  border-radius: 0;
  @include transition(color 225ms);

  @include media-breakpoint-down(md) {
    @include toRem(font-size, 14);
  }

  .icon {
    position: relative;
    @include toRem(top, -2);
    @include toRem(width, 17);
    @include toRem(height, 17);
    @include transition(transform 225ms);

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      circle {
        stroke: $orange;
      }

      path {
        stroke: $orange;
      }
    }
  }

  &-underline {
    font-family: $calibri_regular;
    display: flex;
    justify-content: space-between;
    @include toRem(font-size, 18);
    @include toRem(padding-bottom, 10);
    border-bottom: 1px solid $black;

    @include media-breakpoint-down(md) {
      @include toRem(font-size, 14);
    }

    .icon {
      @include toRem(width, 20);
      @include toRem(height, 20);
    }

    &:focus,
    &:active,
    &:focus-visible,
    &:hover {
      border-bottom: 1px solid $black !important;
    }
  }

  &-download {
    &:focus,
    &:active,
    &:focus-visible,
    &:hover {
      .icon {
        transform: rotate(0) translateY(4px) !important;
      }
    }

  }

  &:focus,
  &:active,
  &:focus-visible,
  &:hover {
    color: $orange !important;

    .icon {
      @include transform(rotate(45deg));
    }
  }

  &-icon {
    &-big {
      .icon {
        @include toRem(width, 48);
        @include toRem(height, 48);

        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;

          circle {
            stroke: $green;
          }

          path {
            stroke: $green !important;
          }
        }
      }
    }

    &-white {
      color: $white;

      .icon {
        svg {
          circle {
            stroke: $white;
          }

          path {
            stroke: $white !important;
          }
        }
      }
    }

    &-black {
      .icon {
        svg {
          circle {
            stroke: $black;
          }

          path {
            stroke: $black !important;
          }
        }
      }
    }

    &-orange {
      .icon {
        svg {
          circle {
            stroke: $orange;
          }

          path {
            stroke: $orange !important;
          }
        }
      }
    }

    &-blue {
      .icon {
        svg {
          circle {
            stroke: $blue;
          }

          path {
            stroke: $blue !important;
          }
        }
      }
    }
  }

  &-hover {
    &-orange {
      &:hover {
        color: $orange !important;

        .icon {
          svg {
            circle {
              stroke: $orange !important;
            }

            path {
              stroke: $orange !important;
            }
          }
        }
      }
    }

    &-black {
      &:hover {
        color: $black !important;

        .icon {
          svg {
            circle {
              stroke: $black !important;
            }

            path {
              stroke: $black !important;
            }
          }
        }
      }
    }
  }
}

.btn-primary {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: $orange;
  border-color: $orange;
  border-radius: 27px;
  font-family: $calibri_bold;
  @include toRem(font-size, 20);
  @include toRem(padding-left, 24);
  @include toRem(padding-right, 24);
  @include toRem(height, 54);
  @include transition(all 225ms);

  @include media-breakpoint-down(md) {
    @include toRem(font-size, 14);
  }

  &:focus,
  &:active,
  &:focus-visible,
  &:hover {
    box-shadow: none !important;
    background-color: $white !important;
    border-color: $black !important;
    color: $black !important;

    .icon-before {
      svg {
        path {
          fill: $black !important;
        }
      }
    }

    .icon {
      svg {
        path {
          stroke: $black !important;
        }
      }
    }
  }

  .icon-before {
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .icon {
    position: relative;
    @include toRem(width, 14);
    @include toRem(height, 14);
    @include toRem(top, -2);

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      path {
        stroke: $white;
        @include transition(stroke 225ms);
      }
    }
  }

  &-border {
    color: $black;
    background-color: $white;
    border: 1px solid $black;

    .icon {
      svg {
        path {
          stroke: $black;
        }
      }
    }

    &:focus,
    &:active,
    &:focus-visible,
    &:hover {
      background-color: $black !important;
      color: $white !important;

      .icon {
        svg {
          path {
            stroke: $white !important;
          }
        }
      }
    }
  }
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.btn-special {
  &:hover {
    > .icon {
      @include toRem(width, 110);
    }
  }

  > .icon {
    overflow: hidden;
    @include toRem(width, 33);
    @include toRem(height, 33);
    @include toRem(margin-right, 20);
    transform: none !important;
    @include transition(width 1s cubic-bezier(.29, 1.4, .44, .96));
    background-image: url("../img/icons/arrow-right-long-white.svg");
    background-repeat: no-repeat;
    background-position: center right;
  }
}