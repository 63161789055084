//Template "Master page"
.template-master-page {
  &__image {
    width: 100%;
    height: auto;

    @include media-breakpoint-up(xl) {
      @include toRem(width, 585);
      @include toRem(height, 585);
    }
  }
}