//Colors
$white: #fff;
$orange: #F6922A;
$orange2: #F7A754;
$orange3: #F9BD7F;
$blue: #5471BA;
$blue2: #9EB3E6;
$blue3: #E7EBF5;
$green: #648267;
$black: #000;
$gray: #898989;
$gray_light: #D9D9D9;
$gray2: #A8A8A8;
$gray3: #F5F5F5;
$gray4: #BFBFBF;
