//Template "I am"
.template-iam {
  .icon-35 {
    @include toRem(width, 35);
    @include toRem(height, 35);

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__custom-pages {
    a {
      .icon {
        @include transition(transform 225ms);
      }

      &:hover {
        .icon {
          @include transform(rotate(45deg));
        }
      }
    }
  }

  .layouts {
    #layout-template-iam {
      &-0 {
        left: -11%;
        top: 320px;

        @include media-breakpoint-up(xxl) {
          left: -5%;
        }
      }

      &-1 {
        left: 95%;
        top: 200px;
      }
    }
  }
}