// Bloc "Slider"
.slider {
  &__items {
    @include media-breakpoint-down(md) {
      display: flex;
    }
  }

  &__item {
    @include media-breakpoint-up(md) {
      @include toRem(height, 320);
    }
  }
}