// Bloc "Informations 4"
.infos_4 {
  &__infos-info {
    @include media-breakpoint-up(lg) {
      width: 28%;
    }

    &-icon {
      @include toRem(width, 80);
      @include toRem(height, 80);
    }

    &-text {
      position: relative;

      &:before {
        content: '';
        position: relative;
        display: inline-flex;
        background-color: $blue;
        border-radius: 50%;
        @include toRem(width, 5);
        @include toRem(height, 5);
        @include toRem(top, -2);
        @include toRem(margin-right, 5);
      }
    }
  }
}