* {
  @include antialiasing;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 2.5em !important;

  @include media-breakpoint-up(xl) {
    --bs-gutter-x: 6em !important;
  }
}

:root {
  --bs-border-radius: 0.75rem;
  --bs-border-radius-sm: 0.438rem;
}

body {
  min-width: 320px;
  background-color: $white;
  color: $black;
  @include toRem(font-size, 16);
  font-family: $calibri_regular;

  &.open-menu {
    overflow: hidden;
  }
}

body,
.site-container {
  overflow-x: hidden;
}

a {
  &, &.simple-link {
    &:hover {
      color: $orange !important;
    }
  }
}

@include media-breakpoint-down(ipad) {
  a {
    &, &.simple-link {
      &:hover {
        color: $black !important;
      }
    }
  }
}

select, button {
  appearance: none;
  border: 0;
  box-shadow: none;
  background: none;
  padding: 0;
  outline: 0;
}

.anim-scale-img {
  overflow: hidden;

  img {
    @include transition(all 600ms);
  }

  &:hover {
    img {
      @include transform(scale(1.05));
    }
  }
}

.card {
  --bs-card-border-radius: 0;
}

.field {
  &__title {
    &.active,
    &.is-active {
      > span,
      strong, strong > p {
        background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 0) calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px);

        @include media-breakpoint-up(lg) {
          background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 0) calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px);
        }
      }
    }

    &-global {
      z-index: 0;

      > span {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        @include toRem(padding-left, 2);
        @include toRem(padding-right, 2);

        &.remove-after {
          &::after {
            content: none;
          }
        }

        > p {
          position: relative;
          display: inline-flex;
          @include toRem(margin-bottom, -10);
          @include toRem(padding-left, 2);
          @include toRem(padding-right, 2);

          &::after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            @include toRem(bottom, 10);
            background-color: $orange2;
            overflow: hidden;
            width: 100%;
            transform-origin: left;
            @include transform(scaleX(0));
            @include transition(transform 1.25s);
            @include toRem(height, 11);
            @include border-radius(4px);

            @include media-breakpoint-up(lg) {
              @include toRem(height, 20);
            }
          }
        }

        &::after {
          content: '';
          position: absolute;
          z-index: -1;
          left: 0;
          @include toRem(bottom, 14);
          background-color: $orange2;
          overflow: hidden;
          width: 100%;
          transform-origin: left;
          @include transform(scaleX(0));
          @include transition(transform 1.25s);
          @include toRem(height, 11);
          @include border-radius(4px);

          @include media-breakpoint-up(lg) {
            @include toRem(height, 20);
          }
        }
      }
    }

    &-white {
      color: $white;
    }

    &, strong {
      text-transform: uppercase;
      font-family: $calibri_bold;
      font-weight: normal;
      @include toRem(font-size, 30);

      @include media-breakpoint-up(lg) {
        @include toRem(font-size, 55);
      }

      @include media-breakpoint-up(big_laptop) {
        @include toRem(font-size, 64);
      }
    }

    &.text-no-uppercase {
      &, strong {
        text-transform: none !important;
      }
    }

    strong, strong > p {
      display: inline;

      --underline-width-scale: calc(var(--underline-width) / var(--underline-intrinsic-width));
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      background-repeat: no-repeat;
      background-image: linear-gradient(0, $orange2, $orange2);
      background-position-y: calc(100% - var(--underline-offset-y) * -2);
      background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 200) calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px);
      @include transition(background-size 1.5s);

      @include media-breakpoint-up(lg) {
        background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 200) calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px);
      }
    }

    &-submenu,
    &-item {
      color: $black;
      position: relative;
      display: inline-flex;
      font-family: $calibri_bold;
      font-weight: normal;
      @include toRem(padding-left, 2);
      @include toRem(padding-right, 2);
      @include toRem(font-size, 32);

      @include media-breakpoint-up(lg) {
        @include toRem(font-size, 64);
      }

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        @include toRem(bottom, 14);
        background-color: $orange2;
        overflow: hidden;
        width: 100%;
        transform-origin: left;
        @include toRem(height, 11);
        @include border-radius(4px);

        @include media-breakpoint-up(lg) {
          @include toRem(height, 20);
        }
      }
    }
  }

  &__link {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: $calibri_bold;
    font-weight: normal;
    @include toRem(padding-left, 2);
    @include toRem(padding-right, 2);
    @include toRem(font-size, 32);

    @include media-breakpoint-up(lg) {
      @include toRem(font-size, 45);
    }

    @include media-breakpoint-up(big_laptop) {
      @include toRem(font-size, 64);
    }

    &, > span:not(.icon) {
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        @include toRem(bottom, 6);
        background-color: $orange2;
        overflow: hidden;
        width: 100%;
        transform-origin: left;
        @include transform(scaleX(0));
        @include transition(transform 1.25s);
        @include toRem(height, 11);
        @include border-radius(4px);

        @include media-breakpoint-up(lg) {
          @include toRem(height, 20);
        }
      }
    }

    > span:not(.icon) {
      position: relative;
      display: inline-flex;
    }

    &:hover {
      color: $black !important;

      &, > span {
        &::after {
          @include transform(scaleX(1));
        }
      }
    }
  }

  &__text {
    &-gray {
      color: $gray;
    }

    &-20 {
      @include toRem(font-size, 20);
    }

    > p:last-child {
      margin-bottom: 0;
    }

    &.strong-orange {
      strong {
        color: $orange;
      }
    }
  }
}

.fs {
  &-15 {
    @include toRem(font-size, 15);
  }

  &-16 {
    @include toRem(font-size, 16);
  }

  &-18 {
    @include toRem(font-size, 18);
  }

  &-19 {
    @include toRem(font-size, 19);
  }

  &-20 {
    @include toRem(font-size, 16);

    @include media-breakpoint-up(md) {
      @include toRem(font-size, 20);
    }
  }

  &-23 {
    @include toRem(font-size, 12);

    @include media-breakpoint-up(md) {
      @include toRem(font-size, 23);
    }
  }

  &-26 {
    @include toRem(font-size, 20);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 26);
    }
  }

  &-30 {
    @include toRem(font-size, 24);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 30);
    }
  }

  &-38 {
    @include toRem(font-size, 38);
  }

  &-60 {
    @include toRem(font-size, 40);

    @include media-breakpoint-up(xl) {
      @include toRem(font-size, 55);
    }

    @include media-breakpoint-up(xxl) {
      @include toRem(font-size, 60);
    }
  }

  &-64 {
    @include toRem(font-size, 64);
  }

  &-100 {
    @include toRem(font-size, 50);

    @include media-breakpoint-up(md) {
      @include toRem(font-size, 100);
    }
  }
}

.fw-bold {
  font-family: $calibri_bold !important;
  font-weight: bold !important;
}

@include media-breakpoint-down(lg) {
  .vh-100 {
    height: auto !important;

    &.map__wrapper {
      height: 100vh !important;
    }
  }
}

.social-medias__icon {
  @include toRem(width, 21);
  @include toRem(height, 21);

  body.open-menu & {
    @include toRem(width, 34);
    @include toRem(height, 34);
  }

  .footer & {
    @include toRem(width, 28);
    @include toRem(height, 28);
  }

  .icon-svg {
    @include transition(border-color 225ms);

    svg {
      @include toRem(width, 10);
      @include toRem(height, 10);
      object-fit: contain;

      body.open-menu & {
        @include toRem(width, 18);
        @include toRem(height, 18);

        path {
          fill: $black;
        }
      }


      .footer & {
        @include toRem(width, 14);
        @include toRem(height, 14);
      }

      path {
        fill: $white;
        @include transition(fill 225ms);
      }
    }

    &:hover {
      border-color: $orange !important;

      svg path {
        fill: $orange;
      }
    }
  }
}

.list__lang {
  &:hover {
    .list__lang-drop {
      opacity: 1 !important;
      pointer-events: auto !important;

      .item__lang {
        display: block;
      }
    }
  }

  .list__lang-drop {
    @include transform(translateX(-80%));
    @include toRem(top, 20);
  }

  > .item__lang {
    @include toRem(padding-right, 25);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 15px;
    background-image: url("../img/icons/arrow-down-orange.svg");
    display: none;
    cursor: pointer;

    &.active {
      display: block;
    }
  }
}

.bg {
  &-blue {
    background-color: $blue;

    &-opacity {
      background-color: transparentize($blue, .78);
    }

    &-light {
      background-color: $blue3;
    }
  }

  &-orange {
    background-color: $orange;
  }

  &-orange-2 {
    background-color: $orange2;
  }

  &-orange-light {
    background-color: #FFF8F0;
  }

  &-gray-light {
    background-color: $gray_light;
  }

  &-gray-light-2 {
    background-color: $gray3;
  }
}

.text {
  &-orange {
    color: $orange;
  }

  &-orange-light {
    color: $orange3;
  }

  &-blue {
    color: $blue;
  }

  &-gray {
    color: $gray;
  }

  &-gray2 {
    color: $gray2;
  }

  &-gray3 {
    color: $gray4;
  }
}

//Layouts
.layouts {
  overflow: hidden;
  z-index: 1;
}

.layout {
  &-left {
    z-index: -1;
    width: 50vw;
    left: -10vw;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    @include transform(rotate(5deg));

    &:before {
      content: '';
      position: absolute;
      z-index: -2;
      @include toRem(top, 20);
      @include toRem(right, -20);
      border: 1px solid $orange;
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }

  &-square {
    position: absolute;
    z-index: -1;
    display: inline-flex;
    @include transform(rotate(45deg));

    @include media-breakpoint-up(lg) {
      &.active {
        &:before {
          @include transform(scale(0));
          @include transition(all 4s cubic-bezier(0.175, 0.885, 0.32, 1.275));
        }
      }
    }

    &__color {
      &-gray {
        background-color: transparentize($black, .94);
      }

      &-orange {
        background-color: $orange;
      }

      &-blue {
        background-color: $blue;
      }
    }

    &__image {
      &-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include transform(scale(1.6) rotate(-45deg));
      }
    }

    &__shadow {
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        border: 1px solid;
        width: 100%;
        height: 100%;
        @include toRem(top, 20);
        @include toRem(border-radius, 20);
        @include transition(all 1.25s cubic-bezier(0.175, 0.885, 0.32, 1.275));
      }

      &-bg {
        &:before {
          border: 0 !important;
        }

        &-orange {
          &:before {
            background-color: $orange;
          }
        }
      }

      &-blue:before {
        border-color: $blue;
      }

      &-orange:before {
        border-color: $orange;
      }

      &-left:before {
        @include toRem(left, -25);
      }

      &-right:before {
        @include toRem(right, -25);
      }
    }

    &__size {
      &-1 {
        @include toRem(border-radius, 26);
        @include toRem(width, 162);
        @include toRem(height, 162);
      }

      &-2 {
        @include toRem(border-radius, 13);
        @include toRem(width, 93);
        @include toRem(height, 93);
      }

      &-3 {
        @include toRem(border-radius, 22);
        @include toRem(width, 132);
        @include toRem(height, 132);
      }

      &-4 {
        @include toRem(width, 142);
        @include toRem(height, 142);

        @include media-breakpoint-up(lg) {
          @include toRem(width, 368);
          @include toRem(height, 368);
        }

        &,
        .layout-square__image-wrapper,
        &:before {
          @include toRem(border-radius, 20);
        }

        &:before {
          @include toRem(top, 25);
          @include toRem(left, 15);
        }

        .layout-square__image-wrapper {
          img {
            object-position: left;

            @include media-breakpoint-up(lg) {
              object-position: -165px;
            }
          }
        }
      }

      &-5 {
        @include toRem(border-radius, 26);
        @include toRem(width, 282);
        @include toRem(height, 282);
      }

      &-6 {
        @include toRem(border-radius, 20);
        @include toRem(width, 334);
        @include toRem(height, 334);

        &:before {
          @include toRem(top, -20);
          @include toRem(right, -20);
        }
      }

      &-7 {
        @include toRem(border-radius, 25);
        @include toRem(width, 646);
        @include toRem(height, 646);

        &,
        .layout-square__image-wrapper,
        &:before {
          @include toRem(border-radius, 25);
        }

        .layout-square__image-wrapper {
          img {
            object-position: left;
          }
        }
      }

      &-8 {
        @include toRem(border-radius, 30);
        @include toRem(width, 334);
        @include toRem(height, 334);

        &,
        .layout-square__image-wrapper,
        &:before {
          @include toRem(border-radius, 30);
        }
      }

      &-9 {
        @include transform(rotate(30deg));
        @include toRem(border-radius, 14);
        @include toRem(width, 135);
        @include toRem(height, 135);
      }

      &-10 {
        @include toRem(border-radius, 14);
        @include toRem(width, 170);
        @include toRem(height, 170);
      }

      &-11 {
        @include toRem(border-radius, 14);
        @include toRem(width, 135);
        @include toRem(height, 135);

        &:before {
          @include toRem(left, -15);
        }
      }

      &-12 {
        @include transform(rotate(30deg));
        @include toRem(border-radius, 14);
        @include toRem(width, 250);
        @include toRem(height, 250);
      }

      &-13 {
        @include transform(rotate(-10deg));
        @include toRem(border-radius, 10);
        @include toRem(width, 120);
        @include toRem(height, 120);
      }

      &-14 {
        @include transform(rotate(30deg));
        @include toRem(width, 285);
        @include toRem(height, 285);

        &,
        .layout-square__image-wrapper,
        &:before {
          @include toRem(border-radius, 20);
        }

        .layout-square__image-wrapper {
          img {
            object-position: right;
          }
        }
      }

      &-15 {
        @include toRem(border-radius, 26);
        @include toRem(width, 211);
        @include toRem(height, 211);
      }

      &-16 {
        @include transform(rotate(30deg));
        @include toRem(border-radius, 20);
        @include toRem(width, 250);
        @include toRem(height, 250);
      }

      &-17 {
        @include transform(rotate(30deg));
        @include toRem(border-radius, 14);
        @include toRem(width, 170);
        @include toRem(height, 170);
      }
    }
  }
}

a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none;
}

.gm-bundled-control {
  bottom: 110px !important;
  right: 70px !important;
}

.gm-style {
  font-family: $calibri_regular !important;

  .gm-style-iw {
    font-weight: 400 !important;
    font-size: 16px !important;
  }
}

.gm-style-iw {
  border-radius: 0 !important;
  padding: 20px !important;
  min-width: 300px !important;
  box-shadow: 0 3px 6px transparentize($black, 0.84) !important;

  .gm-ui-hover-effect {
    top: 0 !important;
    right: -5px !important;

    span {
      width: 20px !important;
      height: 20px !important;
      padding: 5px;
      margin: 0 !important;
    }
  }

  .gm-style-iw-d {
    max-height: 100% !important;
    overflow: visible !important;
    word-break: break-all;

    div {
      display: flex;
      flex-direction: column;
    }
  }
}

.gmnoprint {
  > div {
    box-shadow: none !important;
    background: none !important;

    > div {
      background: none !important;
    }

    > button {
      margin: 5px 0 !important;
      background-color: $white !important;
      border-radius: 50%;
      box-shadow: 0 3px 6px transparentize($black, 0.84) !important;
      @include transition(all 225ms);

      &:hover {
        background-color: $orange !important;

        &:first-child {
          &:before {
            background-image: url("../img/icons/plus-white.svg");
          }
        }

        &:last-child {
          &:before {
            background-image: url("../img/icons/less-white.svg");
          }
        }
      }

      &:before {
        content: '';
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 19px;
        @include toRem(width, 19);
        @include toRem(height, 19);
      }

      &:first-child {
        &:before {
          background-image: url("../img/icons/plus.svg");
        }
      }

      &:last-child {
        &:before {
          background-image: url("../img/icons/less.svg");
        }
      }

      > img {
        display: none !important;
      }
    }
  }
}

.border {
  &-black {
    --bs-border-color: #000;
  }

  &-gray {
    --bs-border-color: #898989;
  }

  &-gray-light {
    --bs-border-color: #D9D9D9;
  }

  &-orange {
    --bs-border-color: #F6922A;
  }
}

.scrollbar-track-y {
  width: 20px !important;
  background-color: $white !important;
  border-left: 1px solid transparentize($black, .84);

  .scrollbar-thumb {
    background-color: $orange !important;
    width: 100% !important;
    border-radius: 0 !important;
  }
}

.shadow-border {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    background-color: $white;
    border: 1px solid;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    @include transform(rotate(-5deg));
  }

  &-blue:before {
    border-color: $blue;
  }

  &-orange:before {
    border-color: $orange;
  }
}

@include media-breakpoint-up(lg) {
  .column-count-2 {
    column-count: 2;
  }
}

@include media-breakpoint-up(xl) {
  .column-gap-xl {
    column-gap: 200px;
  }
}

.plyr {
  width: 100%;
  @include toRem(height, 373);
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid $white;

  .plyr__control--overlaid {
    background: $orange;
    padding: calc(var(--plyr-control-spacing, 10px) * 2.5);

    &:hover {
      background: $black !important;
    }

    svg {
      height: var(--plyr-control-icon-size, 30px);
      width: var(--plyr-control-icon-size, 30px);
    }
  }

  .plyr__video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 0;
    height: 0;

    .plyr__poster {
      background-size: cover;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .deco-image {
    position: relative;
    @include toRem(width, 587);
    @include toRem(height, 587);

    &:before,
    &:after {
      content: '';
      position: absolute;
      border-radius: 14px;
      overflow: hidden;
    }

    &:before {
      z-index: -1;
      transform: rotate(70deg);
      top: 70px;
      left: -100px;
      border: 1px solid $black;
      @include toRem(width, 655);
      @include toRem(height, 520);
    }

    &:after {
      z-index: -2;
      transform: rotate(100deg);
      top: -70px;
      left: 120px;
      background-color: $orange;
      @include toRem(width, 635);
      @include toRem(height, 580);
    }
  }

  .deco-duplicate-color-rotate {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      @include toRem(top, -60);
      left: 0;
      @include transform(rotate(15deg));
      border-radius: var(--bs-border-radius-sm);
      overflow: hidden;
      z-index: -1;
      background-color: $orange;
      width: 100%;
      height: 100%;

      @include media-breakpoint-up(xl) {
        @include toRem(width, 480);
        @include toRem(height, 440);
      }

      @include media-breakpoint-up(xxl) {
        @include toRem(top, 20);
        @include toRem(width, 480);
        @include toRem(height, 440);
      }
    }
  }

  .deco-duplicate-border {
    position: relative;

    &:hover {
      &:before {
        top: 0;
        left: 0;
      }
    }

    &:before {
      content: '';
      position: absolute;
      @include toRem(top, 20);
      @include toRem(left, -30);
      border-radius: var(--bs-border-radius-sm);
      overflow: hidden;
      z-index: -1;
      border: 1px solid $black;
      width: 100%;
      height: 100%;
      @include transition(all 600ms);
    }
  }
}

@include media-breakpoint-up(xl) {
  .deco-duplicate-color {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      @include toRem(top, 50);
      @include toRem(left, -70);
      border-radius: var(--bs-border-radius-sm);
      overflow: hidden;
      z-index: -1;
      background-color: $orange2;
      width: 100%;
      height: 100%;
    }

    &.is-active {
      &:before {
        @include transform(scale(0));
        @include transition(all 4s cubic-bezier(0.175, 0.885, 0.32, 1.275));
      }
    }

    &-bis {
      &:before {
        border-radius: var(--bs-border-radius-xl);
      }
    }

    &-right {
      &:before {
        left: auto;
        @include toRem(top, 35);
        @include toRem(right, -35);
        @include toRem(width, 630);
      }
    }
  }
}

b, strong {
  font-family: $calibri_bold !important;
  font-weight: bold !important;
}

.view-more,
.view-less {
  @include transition(color 225ms);

  &.hover-white {
    &:hover {
      color: $white;
    }
  }
}

select option {
  &:checked {
    background-color: transparent !important;
    color: $black !important;
  }
}

.selected-filters__item {
  cursor: pointer;
  border: 1px solid transparent;
  @include transition(border-color 600ms);

  &.level_studies,
  &.level_studies_trainings {
    &:hover {
      border-color: $blue;
    }
  }

  &.sectors {
    &:hover {
      border-color: $orange;
    }
  }
}

.bullet-deco {
  position: relative;

  &:after {
    content: '';
    position: relative;
    display: inline-flex;
    background-color: $orange;
    border-radius: 50%;
    @include toRem(width, 10);
    @include toRem(height, 10);
    @include toRem(top, -2);
    @include toRem(margin-left, 15);
  }
}

.job {
  @include toRem(min-height, 400);

  @include media-breakpoint-down(md) {
    &__image {
      height: 200px !important;
    }
  }
}

@include media-breakpoint-up(xl) {
  .w-xl-85 {
    width: 85% !important;
  }
}

:root {
  /* The intrinsic width of the underline stroke (in pixels). This is
   * the same as the height of the cap images. Don't specify the
   * units! This is because of some of the calculations we do later on. */
  --underline-intrinsic-width: 8;

  /* The actual width of the underline stroke we want to render (in pixels).
   * You can modify this, and the sizing and positioning should be calculated
   * accordingly. Again, Don't specify the units! */
  --underline-width: 9;

  /* The colour used to draw the underline. It should match the colour
   * used in the cap images... unfortunately we can't modify the SVG
   * fill via CSS because it's a background image. */
  --underline-color: #F7A754;

  /* We need to know the width of the cap images so that we
   * can position everything on the x axis accordingly. */
  --underline-cap-width: 4px;

  /* The border is positioned relative to the bottom of the line.
   * We can move it upwards a little to create an overlap effect. */
  --underline-offset-y: -2px;

  /* The padding to add to the x axis. By default, the caps would be
   * aligned with the beginning and end of the line. */
  --underline-padding-x: 0.12em;
}

.slider-trainings__items {
  .training {
    &__image {
      @include toRem(height, 180);

      @include media-breakpoint-up(md) {
        @include toRem(height, 330);
      }
    }
  }
}

.toggle-content {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $gray;
    transform-origin: left;
    @include transform(scaleX(0));
    @include transition(transform 600ms);
  }

  &:hover {
    &:before {
      @include transform(scaleX(1));
    }
  }
}

.slider-button {
  &:hover {
    svg {
      circle {
        fill: $orange;
      }

      path {
        stroke: $white;
      }
    }
  }

  svg {
    circle {
      @include transition(fill 225ms);
    }

    path {
      @include transition(stroke 225ms);
    }
  }
}

.slider-trainings,
.slider-jobs {
  .container {
    @include media-breakpoint-up(big_laptop) {
      --bs-gutter-x: 2em !important;
    }
  }
}

#go-to-iam-page,
#go-to-iam-page-mobile {
  &[disabled] {
    pointer-events: none;
    opacity: .5;
    background-color: $orange;
    border-color: $orange;
  }
}

.selectric {
  border: 0 !important;
  background: transparent !important;

  .label {
    margin: 0 38px 0 0 !important;
    font-size: 18px !important;
    color: $gray !important;
  }

  .button {
    display: none !important;
  }
}

.selectric-wrapper.selectric-multiple .selectric-items li.selected {
  padding-left: 30px;
  background-color: $orange;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxOSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgNy41TDcgMTNMMTcgMiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  background-size: 14px;
  background-position: center left 10px;
  background-repeat: no-repeat;
  color: #ffffff;
}

.selectric-wrapper.selectric-multiple .selectric-items li.selected:hover {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuNSAxM0wxMi41IDIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0yIDIuNUwxMyAxMi41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
  background-color: $gray;
  background-position: center left 10px;
  background-size: 14px;
}