//Navigation
.navigation-mini {
  z-index: 6;

  .simple-link {
    &:hover {
      .icon {
        @include transform(scale(1.35));
      }
    }
  }

  .icon {
    top: -1px;
    position: relative;
    @include transition(transform 600ms);
  }
}

.navigation-top {
  z-index: 5;
  @include toRem(height, 134);

  .menu-item {
    &:hover {
      &.menu-item-has-children {
        > .item-link > .icon {
          @include transform(rotate(45deg));
        }
      }
    }

    &.menu-item-has-children {
      > .item-link {
        > .icon {
          display: inline-flex;
          @include toRem(margin-left, 8);
          @include toRem(width, 16);
          @include toRem(height, 16);
          @include transition(transform 225ms);

          body.open-menu & {
            @include toRem(margin-left, 12);
            @include toRem(width, 24);
            @include toRem(height, 24);
          }

          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      &:hover {
        > .item-link {
          position: relative;
          z-index: 1;
          cursor: pointer;
        }

        .navigation-top__submenu {
          opacity: 1 !important;
          @include transform(translateY(0));
          pointer-events: auto !important;
        }
      }

      &.active {
        > .nav-drop {
          max-height: 50vh;
          transition: max-height .6s ease-in;
        }
      }

      > .nav-drop {
        position: relative;
        z-index: 0;

        @include media-breakpoint-down(xl) {
          max-height: 0;
          transition: max-height .3s ease-out;
          overflow: hidden;
        }
      }
    }
  }

  &__lang-mobile {
    position: relative;
    z-index: 5;

    .item__lang {
      &, span {
        color: $black !important;
      }
    }

    body.open-menu & {
      display: flex !important;
    }
  }

  #navigation-top__logo {
    position: relative;
    z-index: 1;
    display: flex;
    width: 150px;
    height: auto;

    @include media-breakpoint-up(xl) {
      @include toRem(width, 220);
      @include toRem(height, 134);
    }

    body.open-menu & {
      display: none !important;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__submenu {
    @include toRem(top, 100);
    @include toRem(height, 800);
    @include transform(translateY(30px));
    @include transition(opacity 225ms, transform 1s cubic-bezier(.29, 1.4, .44, .96));
    box-shadow: 0 3px 6px transparentize($black, .84);
    z-index: 1000;

    .menu-item {
      &:hover {
        &.menu-item-specific {
          > .icon-sm {
            @include toRem(width, 66);
          }

          > .item-link {
            font-family: $calibri_bold;

            &:hover {
              color: $black !important;
            }
          }
        }

        > .item-link {
          padding-left: 130px;

          .anim-underline {
            color: $black !important;
            background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 0) calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px);

            @include media-breakpoint-up(lg) {
              background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 0) calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px);
            }
          }

          .icon {
            @include toRem(width, 110);
          }
        }
      }

      &.menu-item-specific {
        > .icon-sm {
          width: 0;
        }
      }

      > .item-link {
        display: inline;
        position: relative;
        padding-left: 45px;
        @include transition(padding-left 1s cubic-bezier(.29, 1.4, .44, .96));

        .anim-underline {
          display: inline;
          font-family: $calibri_bold;
          font-weight: normal;
          @include toRem(padding-left, 2);
          @include toRem(padding-right, 2);
          @include toRem(font-size, 32);

          @include media-breakpoint-up(lg) {
            @include toRem(font-size, 45);
          }

          @include media-breakpoint-up(big_laptop) {
            @include toRem(font-size, 64);
          }

          --underline-width-scale: calc(var(--underline-width) / var(--underline-intrinsic-width));
          -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
          background-repeat: no-repeat;
          background-image: linear-gradient(0, $orange2, $orange2);
          background-position-y: calc(100% - var(--underline-offset-y) * -2);
          background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 200) calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px);
          @include transition(background-size 1.5s);

          @include media-breakpoint-up(lg) {
            background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 200) calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px);
          }
        }

        .icon {
          position: absolute;
          left: 0;
          @include toRem(top, 4);
          overflow: hidden;
          @include toRem(width, 33);
          @include toRem(height, 33);
          @include toRem(margin-right, 20);
          @include transition(width 1s cubic-bezier(.29, 1.4, .44, .96));
          background-image: url("../img/icons/arrow-right-long.svg");
          background-repeat: no-repeat;
          background-position: center right;
        }
      }

      > .icon-sm {
        overflow: hidden;
        @include toRem(width, 33);
        @include toRem(height, 33);
        @include toRem(margin-right, 20);
        @include transition(width 1s cubic-bezier(.29, 1.4, .44, .96));
        background-image: url("../img/icons/arrow-right-long-sm.svg");
        background-repeat: no-repeat;
        background-position: center right;
      }
    }

    .sectors {
      @include toRem(margin-left, -20);
    }
  }
}

//Burger Menu
#burger-menu {
  position: relative;
  z-index: 1;
  @include toRem(width, 35);
  @include toRem(height, 35);
  @include transform(rotate(0deg));
  @include transition(all 600ms ease-in-out);
  cursor: pointer;

  &.open span {
    &:nth-child(1) {
      @include toRem(top, 10);
      width: 0;
      left: 50%;
    }

    &:nth-child(2) {
      @include transform(rotate(45deg));
    }

    &:nth-child(3) {
      @include transform(rotate(-45deg));
    }

    &:nth-child(4) {
      @include toRem(top, 10);
      width: 0;
      left: 50%;
    }
  }

  span {
    display: block;
    position: absolute;
    @include toRem(height, 2);
    width: 100%;
    background-color: $black;
    opacity: 1;
    left: 0;
    @include transform(rotate(0deg));
    transition: .25s ease-in-out;

    &:nth-child(1) {
      @include toRem(top, 5);
    }

    &:nth-child(2),
    &:nth-child(3) {
      @include toRem(top, 17);
    }

    &:nth-child(4) {
      @include toRem(top, 29);
    }
  }
}

#site-navigation-top__mobile {
  z-index: 0;
  @include transform(translateX(-100%));
  @include transition(transform 600ms cubic-bezier(.42, 0, .58, 1));

  &.open {
    pointer-events: auto !important;
    @include transform(translateX(0));
  }

  .item-menu {
    &.menu-item-has-children {

    }
  }
}

//Header
.header {
  z-index: 0;

  &#site-header-home {

    .layouts {
      left: -80px !important;
      overflow: visible;

      @include media-breakpoint-up(big_laptop) {
        left: 0 !important;
        overflow: hidden;
      }

      #layout-header {
        &-0 {
          display: none;
          left: 37%;
          top: -135px;

          @include media-breakpoint-up(xl) {
            display: block;
          }
        }

        &-1 {
          display: none;
          left: 90%;
          top: -80px;

          @include media-breakpoint-up(xl) {
            display: block;
          }
        }

        &-2 {
          top: 220px;
          left: 30px;

          @include media-breakpoint-up(xl) {
            top: 200px;
            left: auto;
            right: -45px;
          }
        }

        &-3 {
          display: none;
          right: -100px;
          top: 540px;

          @include media-breakpoint-up(xl) {
            display: block;
          }
        }

        &-4 {
          top: 240px;
          right: -120px;

          @include media-breakpoint-up(md) {
            top: 200px;
          }

          @include media-breakpoint-up(lg) {
            right: -350px;
          }

          @include media-breakpoint-up(xl) {
            right: -300px;
            top: 680px;
          }
        }

        &-5 {
          display: none;
          top: -150px;
          left: -65px;

          @include media-breakpoint-up(xl) {
            display: block;
          }

          @include media-breakpoint-up(big_laptop) {
            left: -80px;
          }

          @include media-breakpoint-up(laptop_xxl) {
            left: -20px;
          }
        }

        &-6 {
          display: none;
          top: 40px;
          left: -375px;

          @include media-breakpoint-up(xl) {
            display: block;
          }

          @include media-breakpoint-up(big_laptop) {
            top: 25px;
            left: -380px;
          }

          @include media-breakpoint-up(laptop_xxl) {
            top: 50px;
            left: -300px;
          }
        }

        &-7 {
          display: none;
          top: 335px;
          left: -225px;

          @include media-breakpoint-up(xl) {
            display: block;
            left: -280px;
          }

          @include media-breakpoint-up(laptop_xxl) {
            top: 340px;
            left: -170px;
          }
        }

        &-8 {
          display: none;
          top: 260px;
          left: 310px;

          @include media-breakpoint-up(xl) {
            display: block;
          }

          @include media-breakpoint-up(big_laptop) {
            left: 350px;
          }
        }

        &-9 {
          display: none;
          top: 950px;
          left: -340px;

          @include media-breakpoint-up(xl) {
            display: block;
          }

          @include media-breakpoint-up(big_laptop) {
            left: -380px;
            top: 960px;
          }

          @include media-breakpoint-up(laptop_xxl) {
            left: -290px;
          }
        }

        &-10 {
          display: none;
          top: 960px;
          left: 210px;

          @include media-breakpoint-up(xl) {
            display: block;
          }

          @include media-breakpoint-up(big_laptop) {
            top: 965px;
            left: 130px;
          }

          @include media-breakpoint-up(laptop_xxl) {
            left: 250px;
          }
        }
      }
    }

    .words {
      > .word {
        position: relative;


        &:last-child {
          &:after {
            content: none;
          }
        }

        &:after {
          content: '';
          position: absolute;
          @include toRem(top, 7);
          right: -2px;
          @include toRem(width, 4);
          @include toRem(height, 4);
          border-radius: 50%;
          background-color: $orange;

          @include media-breakpoint-up(md) {
            @include toRem(top, 12);
            right: 0;
            @include toRem(width, 6);
            @include toRem(height, 6);
          }
        }
      }
    }

    .fake-form {
      select {
        @include toRem(padding-right, 30);
        background-image: url("../img/icons/arrow-down.svg");
        background-size: 20px;
        background-position: center right;
        background-repeat: no-repeat;
      }
    }

    //Push
    .push__wrapper {
      position: relative;

      @include media-breakpoint-down(md) {
        transform: scale(1.25);
      }

      @include media-breakpoint-up(big_laptop) {
        @include toRem(right, -70);
      }

      .push {
        position: relative;
        @include toRem(width, 733);
        @include toRem(height, 543);
        background-image: url("../img/global/bg-push.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @include media-breakpoint-down(md) {
          width: 100%;
          height: 300px;
        }

        &:hover {
          .btn-link-icon-big {
            color: $green !important;
          }

          .icon {
            @include transform(rotate(45deg));

            svg {
              circle {
                fill: $green;
              }

              path {
                stroke: $white;
              }
            }
          }
        }

        .wrapper {
          position: absolute;
          @include transform(rotate(6.5deg) translate(-40%, -50%));
          top: 50%;
          left: 50%;
          @include toRem(padding-top, 30);
          @include toRem(padding-left, 100);

          @include media-breakpoint-down(md) {
            padding-top: 0;
            padding-left: 0;
            transform: scale(0.6) rotate(6.5deg) translate(-50%, -70%);
            width: 70%;
          }
        }
      }
    }
  }
}

#form-search-iam-page {
  @include media-breakpoint-down(md) {
    font-family: $calibri_bold;
    color: $black !important;
  }
}