//Animations
.anim-from {
  &-left,
  &-right {
    opacity: 0;

    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transition: all 1s #{$i * .08}s cubic-bezier(.29, 1.4, .44, .96);
      }
    }

    &.is-active {
      opacity: 1;
      @include transform(translateX(0));
    }
  }

  &-left {
    @include transform(translateX(-100%));
  }

  &-right {
    @include transform(translateX(100%));
  }
}