//Single "News"
.news-header {
  position: relative;
  z-index: 2;

  &__image {
    @include toRem(height, 411);
  }
}

.layouts {
  #layout {
    &-single-news {
      &-0 {
        top: 250px;
        left: 95%;
      }

      &-1 {
        top: 850px;
        left: -90px;

        @include media-breakpoint-up(xxl) {
          top: 900px;
          left: -20px;
        }
      }
    }
  }
}