//Single "Establishment"
.establishment {
  &__header__buttons{
    @include media-breakpoint-down(xl) {
      width: 100% !important;
    }
  }

  &-testimonials {
    &__items-item {
      .plyr,
      &-image {
        @include toRem(height, 485);
      }

      .plyr {
        border: 0;
        width: 100%;

        .plyr__control {
          background: $orange;
          opacity: 1;
          @include transition(background 600ms);

          @include media-breakpoint-up(lg) {
            padding: calc(var(--plyr-control-spacing, 10px) * 2.25);
          }

          svg {
            @include media-breakpoint-up(lg) {
              height: var(--plyr-control-icon-size, 35px);
              width: var(--plyr-control-icon-size, 35px);
            }
          }

          &:hover {
            background: $blue;
          }
        }

        &.plyr--playing {
          .plyr__poster {
            display: none;
          }
        }

        .plyr__progress__container,
        .plyr__time,
        .plyr__volume,
        .plyr__menu {
          display: none;
        }

        .plyr__video-wrapper {
          padding-bottom: 0;
          padding-top: 0;
          height: 100%;

          .plyr__poster {
            background-size: cover;
            transform: scale(1.01);
          }
        }
      }
    }

    &__slider-nav {
      position: relative;
      top: auto;

      @include media-breakpoint-up(lg) {
        @include toRem(top, -50);
      }

      @include media-breakpoint-up(xl) {
        @include toRem(top, -175);
      }
    }
  }

  &__push {
    &-logo {
      @include toRem(width, 242);
      @include toRem(height, 242);
      @include toRem(right, -100);
      box-shadow: 0 4px 8px transparentize($black, .85);

      img {
        @include toRem(width, 150);
        @include toRem(height, 150);
      }
    }
  }

  &__others {
    &-item {
      @include toRem(height, 235);
    }
  }

  &__schema{
    .icon{
      @include media-breakpoint-down(lg) {
        @include transform(rotate(90deg));
      }
    }
  }
}

#layout-establishment__schema {
  &-0 {
    left: -17%;
    top: 0;

    @include media-breakpoint-up(xxl) {
      left: -10%;
      top: 50px;
    }
  }

  &-1 {
    left: 5%;
    top: 620px;

    @include media-breakpoint-up(xxl) {
      left: 10%;
    }
  }

  &-2 {
    left: 94%;
    top: 500px;

    @include media-breakpoint-up(xxl) {
      left: 90%;
    }
  }
}