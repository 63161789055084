//Template "Where are we"
.template-where-are-we {
  .tab-pane {
    display: none !important;

    &.active {
      display: block !important;
    }
  }

  ul.nav-tabs {
    border: 0;

    > li {
      &.active {
        a {
          svg * {
            fill: $orange;
          }
        }
      }
    }
  }

  &__map-wrapper{

  }
}

.filters-establishments {
  position: relative;
  z-index: 1;

  &__select-wrapper {
    padding-right: calc(var(--bs-gutter-x) * 0.9);
    padding-left: calc(var(--bs-gutter-x) * 0.9);

    .selectric-wrapper {
      top: -32px;
    }

    .icon {
      position: relative;
      @include toRem(top, 6);
    }

    select {
      cursor: pointer;
      @include toRem(font-size, 16);
    }

    &.js-select {
      select {
        overflow: hidden;
        @include toRem(top, 33);
      }
    }
  }
}