//Breadcrumb
.breadcrumb {
  &.before-white {
    ul {
      li {
        &:after {
          background-color: $white;
        }
      }
    }
  }

  ul {
    li {
      position: relative;
      @include toRem(padding-right, 10);
      @include toRem(margin-right, 10);
      @include toRem(font-size, 16);

      &:last-child {
        padding: 0;
        margin: 0;

        &:after {
          content: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        @include toRem(top, 9);
        @include toRem(right, -2);
        @include toRem(width, 5);
        @include toRem(height, 5);
        background-color: $gray;
        border-radius: 50%;
      }
    }
  }
}