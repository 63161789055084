//Training
.training {
  &__list {
    li {
      position: relative;
      @include toRem(padding-left, 8);

      &:after {
        content: '';
        position: absolute;
        @include toRem(top, 10);
        @include toRem(left, -2);
        @include toRem(width, 5);
        @include toRem(height, 5);
        background-color: $orange;
        border-radius: 50%;
      }
    }
  }

  &__choice_establishment-wrapper {
    position: relative;
    z-index: 5;
  }

  &__image {
    @include toRem(height, 415);

    @include media-breakpoint-down(md) {
      height: 200px !important;
    }
  }

  &__link {
    @include media-breakpoint-down(lg) {
      position: relative !important;
    }
  }
}

.more_establishment {
  @include media-breakpoint-down(md) {
    font-size: 14px;
    text-align: left;

    &:focus,
    &:visited {
      color: $black !important;
    }
  }
}

.job {
  &__image {
    height: 100%;

    .slider-jobs & {
      @include toRem(height, 380);
    }
  }
}