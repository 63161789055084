//Page home - trainings
.trainings {
  .initial-trainings {
    &__level_studies {
      &-item {
        > a {
          @include toRem(min-width, 230);
        }
      }
    }
  }

  #initial-trainings__image-2 {
    @include transform(rotate(-5deg));
  }

  &__title {
    @include media-breakpoint-down(md) {
      text-align: left !important;
    }
  }
}

//Page home - news
.news {
  &__item {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.news-home {
  &__link {
    @include media-breakpoint-down(xl) {
      color: $orange !important;

      .icon {
        svg {
          circle, path {
            stroke: $orange;
          }
        }
      }
    }
  }

  &__title {
    @include media-breakpoint-down(xl) {
      color: $black !important;
    }
  }
}

//Page home - campus life
.campus-life {
  position: relative;

  #campus-life__push-2 {
    position: relative;
    z-index: 1;
  }

  #layout-campus-life {
    &-1 {
      z-index: 0;
      @include toRem(top, -50);
      @include toRem(left, 450);
    }

    &-2 {
      z-index: 0;
      @include toRem(bottom, 300);
      @include toRem(left, -150);

      @include media-breakpoint-up(xxl) {
        @include toRem(bottom, 400);
        @include toRem(left, -50);
      }
    }

    &-3 {
      z-index: 0;
      @include toRem(bottom, 150);
      right: 0;

      @include media-breakpoint-up(xxl) {
        @include toRem(right, 100);
      }
    }
  }

  &__video {
    > .plyr {
      width: 100%;
      @include toRem(height, 373);
      border-radius: 16px;
      overflow: hidden;
      border: 0;

      .plyr__video-wrapper {
        position: relative;
        height: 100%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

//Page home - network
.network {
  &__array {
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(xl) {
      * {
        border: 0 !important;
      }
    }

    @include media-breakpoint-up(xl) {
      @include toRem(height, 493);
    }
  }

  &__our-partners {
    &-cta {
      @include toRem(top, -10);
    }

    &-title {
      position: relative;
      z-index: 1;
    }
  }

  #layout-network {
    z-index: 0;
    @include toRem(top, -100);
    @include toRem(right, -275);

    @include media-breakpoint-up(md) {
      @include toRem(top, -100);
      @include toRem(left, -105);
    }
  }
}

//Page home - next steps
.next-steps {
  &__bg {
    z-index: -1;
    left: -10%;
    @include transform(rotate(1deg));
  }

  .field__title {
    strong {
      color: $orange;
    }
  }

  &__item {
    &:hover {
      .next-steps__icon {
        svg {
          @include transform(rotate(45deg));
        }
      }
    }
  }

  &__icon {
    left: 0;
    @include toRem(bottom, 25);

    svg {
      @include transition(transform 225ms);
    }
  }
}

.key-figures {
  &__item {
    margin: 0 65px;
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      margin: 0 20px;
    }
  }

  &__title {
    @include media-breakpoint-down(md) {
      text-align: left !important;
    }
  }
}

#marquee > div,
.js-marquee {
  display: flex;
  align-items: center;
}