// Bloc "Double colonne (coloré)"
.double_column_color {
  &__rich-text {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      @include toRem(margin-bottom, 15);

      @include media-breakpoint-up(md) {
        column-count: 2;
      }

      li {
        position: relative;
        @include toRem(margin-bottom, 18);
        @include toRem(padding-left, 10);

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          @include toRem(top, 10);
          @include toRem(width, 5);
          @include toRem(height, 5);
          border-radius: 50%;
          background-color: $orange;
        }

        strong {
          font-family: $calibri_bold;
          font-weight: bold;
        }
      }
    }
  }

  .layouts {
    #layout {
      &-double_column_color {
        &-0 {
          bottom: 60px;
          right: -80px;

          @include media-breakpoint-up(xxl) {
            right: 0;
          }
        }
      }
    }
  }
}