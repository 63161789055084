//Template "Initial Trainings"
.template-initial-trainings {
  &__items {
    position: relative;
    z-index: 2;

    .training {
      &__image {
        @include toRem(height, 415);

        @include media-breakpoint-down(md) {
          height: 200px !important;
        }
      }

      .more_establishment {
        &.active {
          .icon {
            @include transform(rotate(45deg));
          }
        }

        .icon {
          @include transition(transform 225ms);
        }
      }

      &__choice_establishment {
        display: none;
        overflow: auto;
        @include toRem(top, 35);
        @include toRem(height, 80);

        &.active {
          display: block;
        }
      }
    }
  }
}

.layouts {
  #site-header-base & {
    @include toRem(height, 280);
  }

  #layout {
    &-header {
      &-0 {
        top: -100px;
        left: -120px;

        @include media-breakpoint-up(xxl) {
          left: 0;
        }
      }

      &-1 {
        top: -10px;
        left: 99%;

        @include media-breakpoint-up(xxl) {
          left: 94%;
        }
      }
    }

    &-template-initial-trainings {
      &-0 {
        left: 93%;
        top: 900px;
      }

      &-1 {
        top: 550px;
        left: -200px;

        @include media-breakpoint-up(xxl) {
          top: 650px;
          left: -150px;
        }
      }

      &-2 {
        top: 920px;
        left: -40px;

        @include media-breakpoint-up(xxl) {
          left: 80px;
        }
      }
    }
  }
}

.filters-trainings {
  position: relative;
  z-index: 3;

  &__text {
    @include media-breakpoint-down(md) {
      font-size: 11px !important;
    }
  }

  &__form {
    @include media-breakpoint-up(md) {
      box-shadow: 0 4px 8px transparentize($black, .85);
    }
  }

  &__select-wrapper {
    padding-right: calc(var(--bs-gutter-x) * 0.9);
    padding-left: calc(var(--bs-gutter-x) * 0.9);

    .icon {
      position: relative;
      z-index: 2;
      pointer-events: none;
      @include toRem(top, 42);

      svg path {
        stroke: $black;
      }
    }

    select {
      cursor: pointer;
    }

    &.js-select {
      select {
        overflow: hidden;
        @include toRem(top, 35);
      }
    }
  }
}