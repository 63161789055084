//Single "Job"
.job {
  &-header {
    &__description {
      em {
        @include toRem(font-size, 16);
        color: $gray;
      }
    }

    &__video {
      @include toRem(height, 415);

      .plyr {
        height: 100%;
        border: 0;
        border-radius: var(--bs-border-radius-sm);

        .plyr__video-wrapper {
          padding-top: 0;
          height: 100%;
        }
      }
    }
  }
}