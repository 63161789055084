// Footer
.pre-footer {
  &__animate-title {
    @include toRem(margin-bottom, -4);
    color: $orange;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 20s linear infinite;
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }
}

#footer__logo {
  width: 150px;
  height: auto;

  @include media-breakpoint-up(xl) {
    @include toRem(width, 342);
    @include toRem(height, 208);
  }
}

.footer {
  &__bg {
    z-index: -1;
    transform: scaleX(1.3);
  }

  &__links {
    @include media-breakpoint-down(md) {
      border: 0 !important;
    }
  }

  .simple-link {
    @include media-breakpoint-down(sm) {
      text-align: center !important;
    }
  }

  #menu-footer {
    @include media-breakpoint-up(xl) {
      &-2 {
        .menu-item:not(:last-child) {
          @include toRem(margin-bottom, 25);
        }
      }

      &-3 {
        .menu-item:not(:last-child) {
          @include toRem(margin-bottom, 55);
        }
      }
    }

    &-4 {
      .menu-item {
        @include media-breakpoint-up(xl) {
          &:last-child {
            @include toRem(margin-top, 71);
          }
        }

        &.icon-arrow {
          .item-link {
            @include toRem(padding-right, 45);
            background-image: url("../img/icons/arrow-rotate.svg");
            background-size: 20px;
            background-position: top 7px right;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  > .container > .row > .col-12 {
    position: relative;
    margin-bottom: 30px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $black;
      width: 100%;
      height: 1px;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        bottom: auto;
        transform: translateY(-58%);
        left: auto;
        right: 0;
        background-color: $black;
        width: 1px;
        height: 80%;
      }
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }

  &__links {
    @include media-breakpoint-down(lg) {
      padding-bottom: 30px;
      border: 0 !important;
      border-bottom: 1px solid $white !important;
    }
  }

  .item-link {
    &[href="#"] {
      pointer-events: none;
    }
  }
}