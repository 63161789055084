// Bloc "Double colonne"
.double_column {
  &__action {
    @include transition(color 225ms);

    @include media-breakpoint-up(ipad) {
      &:hover {
        color: $orange;
      }
    }

    @include media-breakpoint-down(ipad) {
      color: $black;

      &:hover {
        color: $black;
      }
    }
  }
}