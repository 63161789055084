// Bloc "Double colonne (listes)"
.double_column_lists {
  &__image {
    @include media-breakpoint-up(xl) {
      @include toRem(height, 565);
    }
  }

  &__items {
    &-item {
      > p {
        position: relative;

        &:before {
          content: '';
          position: relative;
          display: inline-flex;
          @include toRem(top, -3);
          @include toRem(margin-right, 5);
          @include toRem(width, 5);
          @include toRem(height, 5);
          border-radius: 50%;
          background-color: $black;
        }
      }
    }
  }

  &__box {
    &-item {
      position: relative;

      &:before {
        content: '';
        position: relative;
        display: inline-flex;
        @include toRem(top, -3);
        @include toRem(margin-right, 5);
        @include toRem(width, 5);
        @include toRem(height, 5);
        border-radius: 50%;
        background-color: $black;
      }
    }
  }
}