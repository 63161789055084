//Template "Jobs"
.template-jobs {
  &__items {
    position: relative;
    z-index: 2;
  }
}

.layouts {
  #layout {
    &-template-jobs {
      &-0 {
        top: 450px;
        left: -250px;
      }

      &-1 {
        top: 820px;
        left: -40px;

        @include media-breakpoint-up(xxl) {
          left: 80px;
        }
      }
    }
  }
}

.filters-jobs {
  position: relative;
  z-index: 3;

  &__form {
    @include media-breakpoint-up(md) {
      box-shadow: 0 4px 8px transparentize($black, .85);
    }
  }

  &__select-wrapper {
    padding-right: calc(var(--bs-gutter-x) * 0.9);
    padding-left: calc(var(--bs-gutter-x) * 0.9);

    .icon {
      position: relative;
      z-index: 2;
      pointer-events: none;
      @include toRem(top, 42);

      svg path {
        stroke: $black;
      }
    }

    select {
      cursor: pointer;
    }

    &.js-select {
      select {
        overflow: hidden;
        @include toRem(top, 35);
      }
    }
  }
}