// Bloc "Black push"
.black_push {
  a {
    @include transition(background-color 600ms);

    &:hover {
      background-color: $orange2 !important;

      .black_push {
        &__icon {
          @include transform(rotate(45deg));
        }

        &__image {
          svg {
            path {
              fill: $black;
            }
          }
        }

      }
    }
  }

  &__link {
    @include toRem(min-height, 335);
  }

  &__icon {
    @include toRem(right, 70);
    @include toRem(bottom, 70);
    @include transition(transform 225ms);

    @include media-breakpoint-down(md) {
      @include toRem(width, 70);
      @include toRem(height, 70);
    }
  }

  &__image {
    @include toRem(right, 250);
    @include toRem(bottom, -8);

    svg {
      path {
        @include transition(fill 225ms);
      }
    }
  }
}