@charset "UTF-8";
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

@font-face {
  font-family: "Calibri Light";
  src: url("../fonts/Calibri-Light.eot");
  src: url("../fonts/Calibri-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri-Light.woff2") format("woff2"), url("../fonts/Calibri-Light.woff") format("woff"), url("../fonts/Calibri-Light.ttf") format("truetype"), url("../fonts/Calibri-Light.svg#Calibri-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Calibri Regular";
  src: url("../fonts/Calibri.eot");
  src: url("../fonts/Calibri.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri.woff2") format("woff2"), url("../fonts/Calibri.woff") format("woff"), url("../fonts/Calibri.ttf") format("truetype"), url("../fonts/Calibri.svg#Calibri") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Calibri Bold";
  src: url("../fonts/Calibri-Bold.eot");
  src: url("../fonts/Calibri-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri-Bold.woff2") format("woff2"), url("../fonts/Calibri-Bold.woff") format("woff"), url("../fonts/Calibri-Bold.ttf") format("truetype"), url("../fonts/Calibri-Bold.svg#Calibri-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.nav-link, a {
  color: black;
  text-decoration: none;
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}

.btn-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: left;
  padding: 0;
  outline: 0;
  text-decoration: none;
  font-size: 1.25rem;
  font-family: "Calibri Bold";
  color: #000;
  border-radius: 0;
  -moz-transition: color 225ms;
  -o-transition: color 225ms;
  -webkit-transition: color 225ms;
  transition: color 225ms;
}
@media (max-width: 767.98px) {
  .btn-link {
    font-size: 0.875rem;
  }
}
.btn-link .icon {
  position: relative;
  top: -0.125rem;
  width: 1.0625rem;
  height: 1.0625rem;
  -moz-transition: transform 225ms;
  -o-transition: transform 225ms;
  -webkit-transition: transform 225ms;
  transition: transform 225ms;
}
.btn-link .icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.btn-link .icon svg circle {
  stroke: #F6922A;
}
.btn-link .icon svg path {
  stroke: #F6922A;
}
.btn-link-underline {
  font-family: "Calibri Regular";
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid #000;
}
@media (max-width: 767.98px) {
  .btn-link-underline {
    font-size: 0.875rem;
  }
}
.btn-link-underline .icon {
  width: 1.25rem;
  height: 1.25rem;
}
.btn-link-underline:focus, .btn-link-underline:active, .btn-link-underline:focus-visible, .btn-link-underline:hover {
  border-bottom: 1px solid #000 !important;
}
.btn-link-download:focus .icon, .btn-link-download:active .icon, .btn-link-download:focus-visible .icon, .btn-link-download:hover .icon {
  transform: rotate(0) translateY(4px) !important;
}
.btn-link:focus, .btn-link:active, .btn-link:focus-visible, .btn-link:hover {
  color: #F6922A !important;
}
.btn-link:focus .icon, .btn-link:active .icon, .btn-link:focus-visible .icon, .btn-link:hover .icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.btn-link-icon-big .icon {
  width: 3rem;
  height: 3rem;
}
.btn-link-icon-big .icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.btn-link-icon-big .icon svg circle {
  stroke: #648267;
}
.btn-link-icon-big .icon svg path {
  stroke: #648267 !important;
}
.btn-link-icon-white {
  color: #fff;
}
.btn-link-icon-white .icon svg circle {
  stroke: #fff;
}
.btn-link-icon-white .icon svg path {
  stroke: #fff !important;
}
.btn-link-icon-black .icon svg circle {
  stroke: #000;
}
.btn-link-icon-black .icon svg path {
  stroke: #000 !important;
}
.btn-link-icon-orange .icon svg circle {
  stroke: #F6922A;
}
.btn-link-icon-orange .icon svg path {
  stroke: #F6922A !important;
}
.btn-link-icon-blue .icon svg circle {
  stroke: #5471BA;
}
.btn-link-icon-blue .icon svg path {
  stroke: #5471BA !important;
}
.btn-link-hover-orange:hover {
  color: #F6922A !important;
}
.btn-link-hover-orange:hover .icon svg circle {
  stroke: #F6922A !important;
}
.btn-link-hover-orange:hover .icon svg path {
  stroke: #F6922A !important;
}
.btn-link-hover-black:hover {
  color: #000 !important;
}
.btn-link-hover-black:hover .icon svg circle {
  stroke: #000 !important;
}
.btn-link-hover-black:hover .icon svg path {
  stroke: #000 !important;
}

.btn-primary {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F6922A;
  border-color: #F6922A;
  border-radius: 27px;
  font-family: "Calibri Bold";
  font-size: 1.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 3.375rem;
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}
@media (max-width: 767.98px) {
  .btn-primary {
    font-size: 0.875rem;
  }
}
.btn-primary:focus, .btn-primary:active, .btn-primary:focus-visible, .btn-primary:hover {
  box-shadow: none !important;
  background-color: #fff !important;
  border-color: #000 !important;
  color: #000 !important;
}
.btn-primary:focus .icon-before svg path, .btn-primary:active .icon-before svg path, .btn-primary:focus-visible .icon-before svg path, .btn-primary:hover .icon-before svg path {
  fill: #000 !important;
}
.btn-primary:focus .icon svg path, .btn-primary:active .icon svg path, .btn-primary:focus-visible .icon svg path, .btn-primary:hover .icon svg path {
  stroke: #000 !important;
}
.btn-primary .icon-before svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.btn-primary .icon {
  position: relative;
  width: 0.875rem;
  height: 0.875rem;
  top: -0.125rem;
}
.btn-primary .icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.btn-primary .icon svg path {
  stroke: #fff;
  -moz-transition: stroke 225ms;
  -o-transition: stroke 225ms;
  -webkit-transition: stroke 225ms;
  transition: stroke 225ms;
}
.btn-primary-border {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}
.btn-primary-border .icon svg path {
  stroke: #000;
}
.btn-primary-border:focus, .btn-primary-border:active, .btn-primary-border:focus-visible, .btn-primary-border:hover {
  background-color: #000 !important;
  color: #fff !important;
}
.btn-primary-border:focus .icon svg path, .btn-primary-border:active .icon svg path, .btn-primary-border:focus-visible .icon svg path, .btn-primary-border:hover .icon svg path {
  stroke: #fff !important;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.btn-special:hover > .icon {
  width: 6.875rem;
}
.btn-special > .icon {
  overflow: hidden;
  width: 2.0625rem;
  height: 2.0625rem;
  margin-right: 1.25rem;
  transform: none !important;
  -moz-transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  -o-transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  -webkit-transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  background-image: url("../img/icons/arrow-right-long-white.svg");
  background-repeat: no-repeat;
  background-position: center right;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-20 {
  top: 20% !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-20 {
  bottom: 20% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-20 {
  left: 20% !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-20 {
  right: 20% !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-85 {
  width: 85% !important;
}

.w-100 {
  width: 100% !important;
}

.w-120 {
  width: 120% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-85 {
  height: 85% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.4rem !important;
}

.m-3 {
  margin: 0.8rem !important;
}

.m-35 {
  margin: 1.25rem !important;
}

.m-4 {
  margin: 1.875rem !important;
}

.m-45 {
  margin: 2.25rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-55 {
  margin: 3.5rem !important;
}

.m-6 {
  margin: 3.85rem !important;
}

.m-65 {
  margin: 5rem !important;
}

.m-68 {
  margin: 5.5rem !important;
}

.m-7 {
  margin: 6.5rem !important;
}

.m-8 {
  margin: 8rem !important;
}

.m-9 {
  margin: 10rem !important;
}

.m-95 {
  margin: 12rem !important;
}

.m-10 {
  margin: 14rem !important;
}

.m-11 {
  margin: 21rem !important;
}

.m-12 {
  margin: 16rem !important;
}

.m-13 {
  margin: 17rem !important;
}

.m-14 {
  margin: 18rem !important;
}

.m-15 {
  margin: 19rem !important;
}

.m-16 {
  margin: 20rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.4rem !important;
  margin-left: 0.4rem !important;
}

.mx-3 {
  margin-right: 0.8rem !important;
  margin-left: 0.8rem !important;
}

.mx-35 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-4 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-45 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-55 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-6 {
  margin-right: 3.85rem !important;
  margin-left: 3.85rem !important;
}

.mx-65 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-68 {
  margin-right: 5.5rem !important;
  margin-left: 5.5rem !important;
}

.mx-7 {
  margin-right: 6.5rem !important;
  margin-left: 6.5rem !important;
}

.mx-8 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-9 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-95 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-10 {
  margin-right: 14rem !important;
  margin-left: 14rem !important;
}

.mx-11 {
  margin-right: 21rem !important;
  margin-left: 21rem !important;
}

.mx-12 {
  margin-right: 16rem !important;
  margin-left: 16rem !important;
}

.mx-13 {
  margin-right: 17rem !important;
  margin-left: 17rem !important;
}

.mx-14 {
  margin-right: 18rem !important;
  margin-left: 18rem !important;
}

.mx-15 {
  margin-right: 19rem !important;
  margin-left: 19rem !important;
}

.mx-16 {
  margin-right: 20rem !important;
  margin-left: 20rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.4rem !important;
  margin-bottom: 0.4rem !important;
}

.my-3 {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important;
}

.my-35 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-4 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-45 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-55 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-6 {
  margin-top: 3.85rem !important;
  margin-bottom: 3.85rem !important;
}

.my-65 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-68 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important;
}

.my-7 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important;
}

.my-8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-9 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-95 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-10 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-11 {
  margin-top: 21rem !important;
  margin-bottom: 21rem !important;
}

.my-12 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-13 {
  margin-top: 17rem !important;
  margin-bottom: 17rem !important;
}

.my-14 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.my-15 {
  margin-top: 19rem !important;
  margin-bottom: 19rem !important;
}

.my-16 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.4rem !important;
}

.mt-3 {
  margin-top: 0.8rem !important;
}

.mt-35 {
  margin-top: 1.25rem !important;
}

.mt-4 {
  margin-top: 1.875rem !important;
}

.mt-45 {
  margin-top: 2.25rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-55 {
  margin-top: 3.5rem !important;
}

.mt-6 {
  margin-top: 3.85rem !important;
}

.mt-65 {
  margin-top: 5rem !important;
}

.mt-68 {
  margin-top: 5.5rem !important;
}

.mt-7 {
  margin-top: 6.5rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}

.mt-9 {
  margin-top: 10rem !important;
}

.mt-95 {
  margin-top: 12rem !important;
}

.mt-10 {
  margin-top: 14rem !important;
}

.mt-11 {
  margin-top: 21rem !important;
}

.mt-12 {
  margin-top: 16rem !important;
}

.mt-13 {
  margin-top: 17rem !important;
}

.mt-14 {
  margin-top: 18rem !important;
}

.mt-15 {
  margin-top: 19rem !important;
}

.mt-16 {
  margin-top: 20rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.4rem !important;
}

.me-3 {
  margin-right: 0.8rem !important;
}

.me-35 {
  margin-right: 1.25rem !important;
}

.me-4 {
  margin-right: 1.875rem !important;
}

.me-45 {
  margin-right: 2.25rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-55 {
  margin-right: 3.5rem !important;
}

.me-6 {
  margin-right: 3.85rem !important;
}

.me-65 {
  margin-right: 5rem !important;
}

.me-68 {
  margin-right: 5.5rem !important;
}

.me-7 {
  margin-right: 6.5rem !important;
}

.me-8 {
  margin-right: 8rem !important;
}

.me-9 {
  margin-right: 10rem !important;
}

.me-95 {
  margin-right: 12rem !important;
}

.me-10 {
  margin-right: 14rem !important;
}

.me-11 {
  margin-right: 21rem !important;
}

.me-12 {
  margin-right: 16rem !important;
}

.me-13 {
  margin-right: 17rem !important;
}

.me-14 {
  margin-right: 18rem !important;
}

.me-15 {
  margin-right: 19rem !important;
}

.me-16 {
  margin-right: 20rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.4rem !important;
}

.mb-3 {
  margin-bottom: 0.8rem !important;
}

.mb-35 {
  margin-bottom: 1.25rem !important;
}

.mb-4 {
  margin-bottom: 1.875rem !important;
}

.mb-45 {
  margin-bottom: 2.25rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-55 {
  margin-bottom: 3.5rem !important;
}

.mb-6 {
  margin-bottom: 3.85rem !important;
}

.mb-65 {
  margin-bottom: 5rem !important;
}

.mb-68 {
  margin-bottom: 5.5rem !important;
}

.mb-7 {
  margin-bottom: 6.5rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.mb-9 {
  margin-bottom: 10rem !important;
}

.mb-95 {
  margin-bottom: 12rem !important;
}

.mb-10 {
  margin-bottom: 14rem !important;
}

.mb-11 {
  margin-bottom: 21rem !important;
}

.mb-12 {
  margin-bottom: 16rem !important;
}

.mb-13 {
  margin-bottom: 17rem !important;
}

.mb-14 {
  margin-bottom: 18rem !important;
}

.mb-15 {
  margin-bottom: 19rem !important;
}

.mb-16 {
  margin-bottom: 20rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.4rem !important;
}

.ms-3 {
  margin-left: 0.8rem !important;
}

.ms-35 {
  margin-left: 1.25rem !important;
}

.ms-4 {
  margin-left: 1.875rem !important;
}

.ms-45 {
  margin-left: 2.25rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-55 {
  margin-left: 3.5rem !important;
}

.ms-6 {
  margin-left: 3.85rem !important;
}

.ms-65 {
  margin-left: 5rem !important;
}

.ms-68 {
  margin-left: 5.5rem !important;
}

.ms-7 {
  margin-left: 6.5rem !important;
}

.ms-8 {
  margin-left: 8rem !important;
}

.ms-9 {
  margin-left: 10rem !important;
}

.ms-95 {
  margin-left: 12rem !important;
}

.ms-10 {
  margin-left: 14rem !important;
}

.ms-11 {
  margin-left: 21rem !important;
}

.ms-12 {
  margin-left: 16rem !important;
}

.ms-13 {
  margin-left: 17rem !important;
}

.ms-14 {
  margin-left: 18rem !important;
}

.ms-15 {
  margin-left: 19rem !important;
}

.ms-16 {
  margin-left: 20rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.4rem !important;
}

.p-3 {
  padding: 0.8rem !important;
}

.p-35 {
  padding: 1.25rem !important;
}

.p-4 {
  padding: 1.875rem !important;
}

.p-45 {
  padding: 2.25rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-55 {
  padding: 3.5rem !important;
}

.p-6 {
  padding: 3.85rem !important;
}

.p-65 {
  padding: 5rem !important;
}

.p-68 {
  padding: 5.5rem !important;
}

.p-7 {
  padding: 6.5rem !important;
}

.p-8 {
  padding: 8rem !important;
}

.p-9 {
  padding: 10rem !important;
}

.p-95 {
  padding: 12rem !important;
}

.p-10 {
  padding: 14rem !important;
}

.p-11 {
  padding: 21rem !important;
}

.p-12 {
  padding: 16rem !important;
}

.p-13 {
  padding: 17rem !important;
}

.p-14 {
  padding: 18rem !important;
}

.p-15 {
  padding: 19rem !important;
}

.p-16 {
  padding: 20rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.4rem !important;
  padding-left: 0.4rem !important;
}

.px-3 {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important;
}

.px-35 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-4 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-45 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-55 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-6 {
  padding-right: 3.85rem !important;
  padding-left: 3.85rem !important;
}

.px-65 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-68 {
  padding-right: 5.5rem !important;
  padding-left: 5.5rem !important;
}

.px-7 {
  padding-right: 6.5rem !important;
  padding-left: 6.5rem !important;
}

.px-8 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-9 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-95 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.px-10 {
  padding-right: 14rem !important;
  padding-left: 14rem !important;
}

.px-11 {
  padding-right: 21rem !important;
  padding-left: 21rem !important;
}

.px-12 {
  padding-right: 16rem !important;
  padding-left: 16rem !important;
}

.px-13 {
  padding-right: 17rem !important;
  padding-left: 17rem !important;
}

.px-14 {
  padding-right: 18rem !important;
  padding-left: 18rem !important;
}

.px-15 {
  padding-right: 19rem !important;
  padding-left: 19rem !important;
}

.px-16 {
  padding-right: 20rem !important;
  padding-left: 20rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}

.py-3 {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.py-35 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-4 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-45 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-55 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-6 {
  padding-top: 3.85rem !important;
  padding-bottom: 3.85rem !important;
}

.py-65 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-68 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important;
}

.py-7 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important;
}

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-9 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-95 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-10 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-11 {
  padding-top: 21rem !important;
  padding-bottom: 21rem !important;
}

.py-12 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-13 {
  padding-top: 17rem !important;
  padding-bottom: 17rem !important;
}

.py-14 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.py-15 {
  padding-top: 19rem !important;
  padding-bottom: 19rem !important;
}

.py-16 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.4rem !important;
}

.pt-3 {
  padding-top: 0.8rem !important;
}

.pt-35 {
  padding-top: 1.25rem !important;
}

.pt-4 {
  padding-top: 1.875rem !important;
}

.pt-45 {
  padding-top: 2.25rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-55 {
  padding-top: 3.5rem !important;
}

.pt-6 {
  padding-top: 3.85rem !important;
}

.pt-65 {
  padding-top: 5rem !important;
}

.pt-68 {
  padding-top: 5.5rem !important;
}

.pt-7 {
  padding-top: 6.5rem !important;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pt-9 {
  padding-top: 10rem !important;
}

.pt-95 {
  padding-top: 12rem !important;
}

.pt-10 {
  padding-top: 14rem !important;
}

.pt-11 {
  padding-top: 21rem !important;
}

.pt-12 {
  padding-top: 16rem !important;
}

.pt-13 {
  padding-top: 17rem !important;
}

.pt-14 {
  padding-top: 18rem !important;
}

.pt-15 {
  padding-top: 19rem !important;
}

.pt-16 {
  padding-top: 20rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.4rem !important;
}

.pe-3 {
  padding-right: 0.8rem !important;
}

.pe-35 {
  padding-right: 1.25rem !important;
}

.pe-4 {
  padding-right: 1.875rem !important;
}

.pe-45 {
  padding-right: 2.25rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-55 {
  padding-right: 3.5rem !important;
}

.pe-6 {
  padding-right: 3.85rem !important;
}

.pe-65 {
  padding-right: 5rem !important;
}

.pe-68 {
  padding-right: 5.5rem !important;
}

.pe-7 {
  padding-right: 6.5rem !important;
}

.pe-8 {
  padding-right: 8rem !important;
}

.pe-9 {
  padding-right: 10rem !important;
}

.pe-95 {
  padding-right: 12rem !important;
}

.pe-10 {
  padding-right: 14rem !important;
}

.pe-11 {
  padding-right: 21rem !important;
}

.pe-12 {
  padding-right: 16rem !important;
}

.pe-13 {
  padding-right: 17rem !important;
}

.pe-14 {
  padding-right: 18rem !important;
}

.pe-15 {
  padding-right: 19rem !important;
}

.pe-16 {
  padding-right: 20rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.4rem !important;
}

.pb-3 {
  padding-bottom: 0.8rem !important;
}

.pb-35 {
  padding-bottom: 1.25rem !important;
}

.pb-4 {
  padding-bottom: 1.875rem !important;
}

.pb-45 {
  padding-bottom: 2.25rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-55 {
  padding-bottom: 3.5rem !important;
}

.pb-6 {
  padding-bottom: 3.85rem !important;
}

.pb-65 {
  padding-bottom: 5rem !important;
}

.pb-68 {
  padding-bottom: 5.5rem !important;
}

.pb-7 {
  padding-bottom: 6.5rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

.pb-9 {
  padding-bottom: 10rem !important;
}

.pb-95 {
  padding-bottom: 12rem !important;
}

.pb-10 {
  padding-bottom: 14rem !important;
}

.pb-11 {
  padding-bottom: 21rem !important;
}

.pb-12 {
  padding-bottom: 16rem !important;
}

.pb-13 {
  padding-bottom: 17rem !important;
}

.pb-14 {
  padding-bottom: 18rem !important;
}

.pb-15 {
  padding-bottom: 19rem !important;
}

.pb-16 {
  padding-bottom: 20rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.4rem !important;
}

.ps-3 {
  padding-left: 0.8rem !important;
}

.ps-35 {
  padding-left: 1.25rem !important;
}

.ps-4 {
  padding-left: 1.875rem !important;
}

.ps-45 {
  padding-left: 2.25rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-55 {
  padding-left: 3.5rem !important;
}

.ps-6 {
  padding-left: 3.85rem !important;
}

.ps-65 {
  padding-left: 5rem !important;
}

.ps-68 {
  padding-left: 5.5rem !important;
}

.ps-7 {
  padding-left: 6.5rem !important;
}

.ps-8 {
  padding-left: 8rem !important;
}

.ps-9 {
  padding-left: 10rem !important;
}

.ps-95 {
  padding-left: 12rem !important;
}

.ps-10 {
  padding-left: 14rem !important;
}

.ps-11 {
  padding-left: 21rem !important;
}

.ps-12 {
  padding-left: 16rem !important;
}

.ps-13 {
  padding-left: 17rem !important;
}

.ps-14 {
  padding-left: 18rem !important;
}

.ps-15 {
  padding-left: 19rem !important;
}

.ps-16 {
  padding-left: 20rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.4rem !important;
}

.gap-3 {
  gap: 0.8rem !important;
}

.gap-35 {
  gap: 1.25rem !important;
}

.gap-4 {
  gap: 1.875rem !important;
}

.gap-45 {
  gap: 2.25rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-55 {
  gap: 3.5rem !important;
}

.gap-6 {
  gap: 3.85rem !important;
}

.gap-65 {
  gap: 5rem !important;
}

.gap-68 {
  gap: 5.5rem !important;
}

.gap-7 {
  gap: 6.5rem !important;
}

.gap-8 {
  gap: 8rem !important;
}

.gap-9 {
  gap: 10rem !important;
}

.gap-95 {
  gap: 12rem !important;
}

.gap-10 {
  gap: 14rem !important;
}

.gap-11 {
  gap: 21rem !important;
}

.gap-12 {
  gap: 16rem !important;
}

.gap-13 {
  gap: 17rem !important;
}

.gap-14 {
  gap: 18rem !important;
}

.gap-15 {
  gap: 19rem !important;
}

.gap-16 {
  gap: 20rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1 !important;
}

.lh-base {
  line-height: 1.65 !important;
}

.lh-lg {
  line-height: 1.65 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.4rem !important;
  }
  .m-sm-3 {
    margin: 0.8rem !important;
  }
  .m-sm-35 {
    margin: 1.25rem !important;
  }
  .m-sm-4 {
    margin: 1.875rem !important;
  }
  .m-sm-45 {
    margin: 2.25rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-55 {
    margin: 3.5rem !important;
  }
  .m-sm-6 {
    margin: 3.85rem !important;
  }
  .m-sm-65 {
    margin: 5rem !important;
  }
  .m-sm-68 {
    margin: 5.5rem !important;
  }
  .m-sm-7 {
    margin: 6.5rem !important;
  }
  .m-sm-8 {
    margin: 8rem !important;
  }
  .m-sm-9 {
    margin: 10rem !important;
  }
  .m-sm-95 {
    margin: 12rem !important;
  }
  .m-sm-10 {
    margin: 14rem !important;
  }
  .m-sm-11 {
    margin: 21rem !important;
  }
  .m-sm-12 {
    margin: 16rem !important;
  }
  .m-sm-13 {
    margin: 17rem !important;
  }
  .m-sm-14 {
    margin: 18rem !important;
  }
  .m-sm-15 {
    margin: 19rem !important;
  }
  .m-sm-16 {
    margin: 20rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-sm-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-sm-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-sm-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-sm-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-sm-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-sm-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-sm-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-sm-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-sm-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-sm-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-sm-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-sm-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-sm-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-sm-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-sm-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-sm-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-sm-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-sm-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-sm-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-sm-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-sm-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-sm-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.4rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.8rem !important;
  }
  .mt-sm-35 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-45 {
    margin-top: 2.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-55 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-6 {
    margin-top: 3.85rem !important;
  }
  .mt-sm-65 {
    margin-top: 5rem !important;
  }
  .mt-sm-68 {
    margin-top: 5.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 6.5rem !important;
  }
  .mt-sm-8 {
    margin-top: 8rem !important;
  }
  .mt-sm-9 {
    margin-top: 10rem !important;
  }
  .mt-sm-95 {
    margin-top: 12rem !important;
  }
  .mt-sm-10 {
    margin-top: 14rem !important;
  }
  .mt-sm-11 {
    margin-top: 21rem !important;
  }
  .mt-sm-12 {
    margin-top: 16rem !important;
  }
  .mt-sm-13 {
    margin-top: 17rem !important;
  }
  .mt-sm-14 {
    margin-top: 18rem !important;
  }
  .mt-sm-15 {
    margin-top: 19rem !important;
  }
  .mt-sm-16 {
    margin-top: 20rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.4rem !important;
  }
  .me-sm-3 {
    margin-right: 0.8rem !important;
  }
  .me-sm-35 {
    margin-right: 1.25rem !important;
  }
  .me-sm-4 {
    margin-right: 1.875rem !important;
  }
  .me-sm-45 {
    margin-right: 2.25rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-55 {
    margin-right: 3.5rem !important;
  }
  .me-sm-6 {
    margin-right: 3.85rem !important;
  }
  .me-sm-65 {
    margin-right: 5rem !important;
  }
  .me-sm-68 {
    margin-right: 5.5rem !important;
  }
  .me-sm-7 {
    margin-right: 6.5rem !important;
  }
  .me-sm-8 {
    margin-right: 8rem !important;
  }
  .me-sm-9 {
    margin-right: 10rem !important;
  }
  .me-sm-95 {
    margin-right: 12rem !important;
  }
  .me-sm-10 {
    margin-right: 14rem !important;
  }
  .me-sm-11 {
    margin-right: 21rem !important;
  }
  .me-sm-12 {
    margin-right: 16rem !important;
  }
  .me-sm-13 {
    margin-right: 17rem !important;
  }
  .me-sm-14 {
    margin-right: 18rem !important;
  }
  .me-sm-15 {
    margin-right: 19rem !important;
  }
  .me-sm-16 {
    margin-right: 20rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-sm-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-sm-65 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-95 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 14rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 21rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 16rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 17rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 18rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 19rem !important;
  }
  .mb-sm-16 {
    margin-bottom: 20rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.4rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.8rem !important;
  }
  .ms-sm-35 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-45 {
    margin-left: 2.25rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-55 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-6 {
    margin-left: 3.85rem !important;
  }
  .ms-sm-65 {
    margin-left: 5rem !important;
  }
  .ms-sm-68 {
    margin-left: 5.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 6.5rem !important;
  }
  .ms-sm-8 {
    margin-left: 8rem !important;
  }
  .ms-sm-9 {
    margin-left: 10rem !important;
  }
  .ms-sm-95 {
    margin-left: 12rem !important;
  }
  .ms-sm-10 {
    margin-left: 14rem !important;
  }
  .ms-sm-11 {
    margin-left: 21rem !important;
  }
  .ms-sm-12 {
    margin-left: 16rem !important;
  }
  .ms-sm-13 {
    margin-left: 17rem !important;
  }
  .ms-sm-14 {
    margin-left: 18rem !important;
  }
  .ms-sm-15 {
    margin-left: 19rem !important;
  }
  .ms-sm-16 {
    margin-left: 20rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.4rem !important;
  }
  .p-sm-3 {
    padding: 0.8rem !important;
  }
  .p-sm-35 {
    padding: 1.25rem !important;
  }
  .p-sm-4 {
    padding: 1.875rem !important;
  }
  .p-sm-45 {
    padding: 2.25rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-55 {
    padding: 3.5rem !important;
  }
  .p-sm-6 {
    padding: 3.85rem !important;
  }
  .p-sm-65 {
    padding: 5rem !important;
  }
  .p-sm-68 {
    padding: 5.5rem !important;
  }
  .p-sm-7 {
    padding: 6.5rem !important;
  }
  .p-sm-8 {
    padding: 8rem !important;
  }
  .p-sm-9 {
    padding: 10rem !important;
  }
  .p-sm-95 {
    padding: 12rem !important;
  }
  .p-sm-10 {
    padding: 14rem !important;
  }
  .p-sm-11 {
    padding: 21rem !important;
  }
  .p-sm-12 {
    padding: 16rem !important;
  }
  .p-sm-13 {
    padding: 17rem !important;
  }
  .p-sm-14 {
    padding: 18rem !important;
  }
  .p-sm-15 {
    padding: 19rem !important;
  }
  .p-sm-16 {
    padding: 20rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-sm-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-sm-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-sm-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-sm-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-sm-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-sm-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-sm-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-sm-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-sm-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-sm-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-sm-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-sm-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-sm-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-sm-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-sm-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-sm-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-sm-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-sm-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-sm-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-sm-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-sm-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-sm-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-sm-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-sm-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-sm-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-sm-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.4rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.8rem !important;
  }
  .pt-sm-35 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-45 {
    padding-top: 2.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-55 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-6 {
    padding-top: 3.85rem !important;
  }
  .pt-sm-65 {
    padding-top: 5rem !important;
  }
  .pt-sm-68 {
    padding-top: 5.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 6.5rem !important;
  }
  .pt-sm-8 {
    padding-top: 8rem !important;
  }
  .pt-sm-9 {
    padding-top: 10rem !important;
  }
  .pt-sm-95 {
    padding-top: 12rem !important;
  }
  .pt-sm-10 {
    padding-top: 14rem !important;
  }
  .pt-sm-11 {
    padding-top: 21rem !important;
  }
  .pt-sm-12 {
    padding-top: 16rem !important;
  }
  .pt-sm-13 {
    padding-top: 17rem !important;
  }
  .pt-sm-14 {
    padding-top: 18rem !important;
  }
  .pt-sm-15 {
    padding-top: 19rem !important;
  }
  .pt-sm-16 {
    padding-top: 20rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.4rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.8rem !important;
  }
  .pe-sm-35 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-45 {
    padding-right: 2.25rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-55 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-6 {
    padding-right: 3.85rem !important;
  }
  .pe-sm-65 {
    padding-right: 5rem !important;
  }
  .pe-sm-68 {
    padding-right: 5.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 6.5rem !important;
  }
  .pe-sm-8 {
    padding-right: 8rem !important;
  }
  .pe-sm-9 {
    padding-right: 10rem !important;
  }
  .pe-sm-95 {
    padding-right: 12rem !important;
  }
  .pe-sm-10 {
    padding-right: 14rem !important;
  }
  .pe-sm-11 {
    padding-right: 21rem !important;
  }
  .pe-sm-12 {
    padding-right: 16rem !important;
  }
  .pe-sm-13 {
    padding-right: 17rem !important;
  }
  .pe-sm-14 {
    padding-right: 18rem !important;
  }
  .pe-sm-15 {
    padding-right: 19rem !important;
  }
  .pe-sm-16 {
    padding-right: 20rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-sm-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-sm-65 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-95 {
    padding-bottom: 12rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 14rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 21rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 16rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 17rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 18rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 19rem !important;
  }
  .pb-sm-16 {
    padding-bottom: 20rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.4rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.8rem !important;
  }
  .ps-sm-35 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-45 {
    padding-left: 2.25rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-55 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-6 {
    padding-left: 3.85rem !important;
  }
  .ps-sm-65 {
    padding-left: 5rem !important;
  }
  .ps-sm-68 {
    padding-left: 5.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 6.5rem !important;
  }
  .ps-sm-8 {
    padding-left: 8rem !important;
  }
  .ps-sm-9 {
    padding-left: 10rem !important;
  }
  .ps-sm-95 {
    padding-left: 12rem !important;
  }
  .ps-sm-10 {
    padding-left: 14rem !important;
  }
  .ps-sm-11 {
    padding-left: 21rem !important;
  }
  .ps-sm-12 {
    padding-left: 16rem !important;
  }
  .ps-sm-13 {
    padding-left: 17rem !important;
  }
  .ps-sm-14 {
    padding-left: 18rem !important;
  }
  .ps-sm-15 {
    padding-left: 19rem !important;
  }
  .ps-sm-16 {
    padding-left: 20rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.4rem !important;
  }
  .gap-sm-3 {
    gap: 0.8rem !important;
  }
  .gap-sm-35 {
    gap: 1.25rem !important;
  }
  .gap-sm-4 {
    gap: 1.875rem !important;
  }
  .gap-sm-45 {
    gap: 2.25rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-55 {
    gap: 3.5rem !important;
  }
  .gap-sm-6 {
    gap: 3.85rem !important;
  }
  .gap-sm-65 {
    gap: 5rem !important;
  }
  .gap-sm-68 {
    gap: 5.5rem !important;
  }
  .gap-sm-7 {
    gap: 6.5rem !important;
  }
  .gap-sm-8 {
    gap: 8rem !important;
  }
  .gap-sm-9 {
    gap: 10rem !important;
  }
  .gap-sm-95 {
    gap: 12rem !important;
  }
  .gap-sm-10 {
    gap: 14rem !important;
  }
  .gap-sm-11 {
    gap: 21rem !important;
  }
  .gap-sm-12 {
    gap: 16rem !important;
  }
  .gap-sm-13 {
    gap: 17rem !important;
  }
  .gap-sm-14 {
    gap: 18rem !important;
  }
  .gap-sm-15 {
    gap: 19rem !important;
  }
  .gap-sm-16 {
    gap: 20rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.4rem !important;
  }
  .m-md-3 {
    margin: 0.8rem !important;
  }
  .m-md-35 {
    margin: 1.25rem !important;
  }
  .m-md-4 {
    margin: 1.875rem !important;
  }
  .m-md-45 {
    margin: 2.25rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-55 {
    margin: 3.5rem !important;
  }
  .m-md-6 {
    margin: 3.85rem !important;
  }
  .m-md-65 {
    margin: 5rem !important;
  }
  .m-md-68 {
    margin: 5.5rem !important;
  }
  .m-md-7 {
    margin: 6.5rem !important;
  }
  .m-md-8 {
    margin: 8rem !important;
  }
  .m-md-9 {
    margin: 10rem !important;
  }
  .m-md-95 {
    margin: 12rem !important;
  }
  .m-md-10 {
    margin: 14rem !important;
  }
  .m-md-11 {
    margin: 21rem !important;
  }
  .m-md-12 {
    margin: 16rem !important;
  }
  .m-md-13 {
    margin: 17rem !important;
  }
  .m-md-14 {
    margin: 18rem !important;
  }
  .m-md-15 {
    margin: 19rem !important;
  }
  .m-md-16 {
    margin: 20rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-md-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-md-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-md-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-md-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-md-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-md-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-md-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-md-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-md-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-md-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-md-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-md-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-md-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-md-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-md-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-md-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-md-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-md-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-md-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-md-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-md-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-md-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-md-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-md-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.4rem !important;
  }
  .mt-md-3 {
    margin-top: 0.8rem !important;
  }
  .mt-md-35 {
    margin-top: 1.25rem !important;
  }
  .mt-md-4 {
    margin-top: 1.875rem !important;
  }
  .mt-md-45 {
    margin-top: 2.25rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-55 {
    margin-top: 3.5rem !important;
  }
  .mt-md-6 {
    margin-top: 3.85rem !important;
  }
  .mt-md-65 {
    margin-top: 5rem !important;
  }
  .mt-md-68 {
    margin-top: 5.5rem !important;
  }
  .mt-md-7 {
    margin-top: 6.5rem !important;
  }
  .mt-md-8 {
    margin-top: 8rem !important;
  }
  .mt-md-9 {
    margin-top: 10rem !important;
  }
  .mt-md-95 {
    margin-top: 12rem !important;
  }
  .mt-md-10 {
    margin-top: 14rem !important;
  }
  .mt-md-11 {
    margin-top: 21rem !important;
  }
  .mt-md-12 {
    margin-top: 16rem !important;
  }
  .mt-md-13 {
    margin-top: 17rem !important;
  }
  .mt-md-14 {
    margin-top: 18rem !important;
  }
  .mt-md-15 {
    margin-top: 19rem !important;
  }
  .mt-md-16 {
    margin-top: 20rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.4rem !important;
  }
  .me-md-3 {
    margin-right: 0.8rem !important;
  }
  .me-md-35 {
    margin-right: 1.25rem !important;
  }
  .me-md-4 {
    margin-right: 1.875rem !important;
  }
  .me-md-45 {
    margin-right: 2.25rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-55 {
    margin-right: 3.5rem !important;
  }
  .me-md-6 {
    margin-right: 3.85rem !important;
  }
  .me-md-65 {
    margin-right: 5rem !important;
  }
  .me-md-68 {
    margin-right: 5.5rem !important;
  }
  .me-md-7 {
    margin-right: 6.5rem !important;
  }
  .me-md-8 {
    margin-right: 8rem !important;
  }
  .me-md-9 {
    margin-right: 10rem !important;
  }
  .me-md-95 {
    margin-right: 12rem !important;
  }
  .me-md-10 {
    margin-right: 14rem !important;
  }
  .me-md-11 {
    margin-right: 21rem !important;
  }
  .me-md-12 {
    margin-right: 16rem !important;
  }
  .me-md-13 {
    margin-right: 17rem !important;
  }
  .me-md-14 {
    margin-right: 18rem !important;
  }
  .me-md-15 {
    margin-right: 19rem !important;
  }
  .me-md-16 {
    margin-right: 20rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-md-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-md-65 {
    margin-bottom: 5rem !important;
  }
  .mb-md-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 8rem !important;
  }
  .mb-md-9 {
    margin-bottom: 10rem !important;
  }
  .mb-md-95 {
    margin-bottom: 12rem !important;
  }
  .mb-md-10 {
    margin-bottom: 14rem !important;
  }
  .mb-md-11 {
    margin-bottom: 21rem !important;
  }
  .mb-md-12 {
    margin-bottom: 16rem !important;
  }
  .mb-md-13 {
    margin-bottom: 17rem !important;
  }
  .mb-md-14 {
    margin-bottom: 18rem !important;
  }
  .mb-md-15 {
    margin-bottom: 19rem !important;
  }
  .mb-md-16 {
    margin-bottom: 20rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.4rem !important;
  }
  .ms-md-3 {
    margin-left: 0.8rem !important;
  }
  .ms-md-35 {
    margin-left: 1.25rem !important;
  }
  .ms-md-4 {
    margin-left: 1.875rem !important;
  }
  .ms-md-45 {
    margin-left: 2.25rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-55 {
    margin-left: 3.5rem !important;
  }
  .ms-md-6 {
    margin-left: 3.85rem !important;
  }
  .ms-md-65 {
    margin-left: 5rem !important;
  }
  .ms-md-68 {
    margin-left: 5.5rem !important;
  }
  .ms-md-7 {
    margin-left: 6.5rem !important;
  }
  .ms-md-8 {
    margin-left: 8rem !important;
  }
  .ms-md-9 {
    margin-left: 10rem !important;
  }
  .ms-md-95 {
    margin-left: 12rem !important;
  }
  .ms-md-10 {
    margin-left: 14rem !important;
  }
  .ms-md-11 {
    margin-left: 21rem !important;
  }
  .ms-md-12 {
    margin-left: 16rem !important;
  }
  .ms-md-13 {
    margin-left: 17rem !important;
  }
  .ms-md-14 {
    margin-left: 18rem !important;
  }
  .ms-md-15 {
    margin-left: 19rem !important;
  }
  .ms-md-16 {
    margin-left: 20rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.4rem !important;
  }
  .p-md-3 {
    padding: 0.8rem !important;
  }
  .p-md-35 {
    padding: 1.25rem !important;
  }
  .p-md-4 {
    padding: 1.875rem !important;
  }
  .p-md-45 {
    padding: 2.25rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-55 {
    padding: 3.5rem !important;
  }
  .p-md-6 {
    padding: 3.85rem !important;
  }
  .p-md-65 {
    padding: 5rem !important;
  }
  .p-md-68 {
    padding: 5.5rem !important;
  }
  .p-md-7 {
    padding: 6.5rem !important;
  }
  .p-md-8 {
    padding: 8rem !important;
  }
  .p-md-9 {
    padding: 10rem !important;
  }
  .p-md-95 {
    padding: 12rem !important;
  }
  .p-md-10 {
    padding: 14rem !important;
  }
  .p-md-11 {
    padding: 21rem !important;
  }
  .p-md-12 {
    padding: 16rem !important;
  }
  .p-md-13 {
    padding: 17rem !important;
  }
  .p-md-14 {
    padding: 18rem !important;
  }
  .p-md-15 {
    padding: 19rem !important;
  }
  .p-md-16 {
    padding: 20rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-md-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-md-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-md-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-md-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-md-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-md-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-md-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-md-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-md-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-md-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-md-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-md-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-md-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-md-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-md-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-md-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-md-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-md-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-md-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-md-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-md-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-md-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-md-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-md-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-md-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-md-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.4rem !important;
  }
  .pt-md-3 {
    padding-top: 0.8rem !important;
  }
  .pt-md-35 {
    padding-top: 1.25rem !important;
  }
  .pt-md-4 {
    padding-top: 1.875rem !important;
  }
  .pt-md-45 {
    padding-top: 2.25rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-55 {
    padding-top: 3.5rem !important;
  }
  .pt-md-6 {
    padding-top: 3.85rem !important;
  }
  .pt-md-65 {
    padding-top: 5rem !important;
  }
  .pt-md-68 {
    padding-top: 5.5rem !important;
  }
  .pt-md-7 {
    padding-top: 6.5rem !important;
  }
  .pt-md-8 {
    padding-top: 8rem !important;
  }
  .pt-md-9 {
    padding-top: 10rem !important;
  }
  .pt-md-95 {
    padding-top: 12rem !important;
  }
  .pt-md-10 {
    padding-top: 14rem !important;
  }
  .pt-md-11 {
    padding-top: 21rem !important;
  }
  .pt-md-12 {
    padding-top: 16rem !important;
  }
  .pt-md-13 {
    padding-top: 17rem !important;
  }
  .pt-md-14 {
    padding-top: 18rem !important;
  }
  .pt-md-15 {
    padding-top: 19rem !important;
  }
  .pt-md-16 {
    padding-top: 20rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.4rem !important;
  }
  .pe-md-3 {
    padding-right: 0.8rem !important;
  }
  .pe-md-35 {
    padding-right: 1.25rem !important;
  }
  .pe-md-4 {
    padding-right: 1.875rem !important;
  }
  .pe-md-45 {
    padding-right: 2.25rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-55 {
    padding-right: 3.5rem !important;
  }
  .pe-md-6 {
    padding-right: 3.85rem !important;
  }
  .pe-md-65 {
    padding-right: 5rem !important;
  }
  .pe-md-68 {
    padding-right: 5.5rem !important;
  }
  .pe-md-7 {
    padding-right: 6.5rem !important;
  }
  .pe-md-8 {
    padding-right: 8rem !important;
  }
  .pe-md-9 {
    padding-right: 10rem !important;
  }
  .pe-md-95 {
    padding-right: 12rem !important;
  }
  .pe-md-10 {
    padding-right: 14rem !important;
  }
  .pe-md-11 {
    padding-right: 21rem !important;
  }
  .pe-md-12 {
    padding-right: 16rem !important;
  }
  .pe-md-13 {
    padding-right: 17rem !important;
  }
  .pe-md-14 {
    padding-right: 18rem !important;
  }
  .pe-md-15 {
    padding-right: 19rem !important;
  }
  .pe-md-16 {
    padding-right: 20rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-md-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-md-65 {
    padding-bottom: 5rem !important;
  }
  .pb-md-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-md-8 {
    padding-bottom: 8rem !important;
  }
  .pb-md-9 {
    padding-bottom: 10rem !important;
  }
  .pb-md-95 {
    padding-bottom: 12rem !important;
  }
  .pb-md-10 {
    padding-bottom: 14rem !important;
  }
  .pb-md-11 {
    padding-bottom: 21rem !important;
  }
  .pb-md-12 {
    padding-bottom: 16rem !important;
  }
  .pb-md-13 {
    padding-bottom: 17rem !important;
  }
  .pb-md-14 {
    padding-bottom: 18rem !important;
  }
  .pb-md-15 {
    padding-bottom: 19rem !important;
  }
  .pb-md-16 {
    padding-bottom: 20rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.4rem !important;
  }
  .ps-md-3 {
    padding-left: 0.8rem !important;
  }
  .ps-md-35 {
    padding-left: 1.25rem !important;
  }
  .ps-md-4 {
    padding-left: 1.875rem !important;
  }
  .ps-md-45 {
    padding-left: 2.25rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-55 {
    padding-left: 3.5rem !important;
  }
  .ps-md-6 {
    padding-left: 3.85rem !important;
  }
  .ps-md-65 {
    padding-left: 5rem !important;
  }
  .ps-md-68 {
    padding-left: 5.5rem !important;
  }
  .ps-md-7 {
    padding-left: 6.5rem !important;
  }
  .ps-md-8 {
    padding-left: 8rem !important;
  }
  .ps-md-9 {
    padding-left: 10rem !important;
  }
  .ps-md-95 {
    padding-left: 12rem !important;
  }
  .ps-md-10 {
    padding-left: 14rem !important;
  }
  .ps-md-11 {
    padding-left: 21rem !important;
  }
  .ps-md-12 {
    padding-left: 16rem !important;
  }
  .ps-md-13 {
    padding-left: 17rem !important;
  }
  .ps-md-14 {
    padding-left: 18rem !important;
  }
  .ps-md-15 {
    padding-left: 19rem !important;
  }
  .ps-md-16 {
    padding-left: 20rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.4rem !important;
  }
  .gap-md-3 {
    gap: 0.8rem !important;
  }
  .gap-md-35 {
    gap: 1.25rem !important;
  }
  .gap-md-4 {
    gap: 1.875rem !important;
  }
  .gap-md-45 {
    gap: 2.25rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-55 {
    gap: 3.5rem !important;
  }
  .gap-md-6 {
    gap: 3.85rem !important;
  }
  .gap-md-65 {
    gap: 5rem !important;
  }
  .gap-md-68 {
    gap: 5.5rem !important;
  }
  .gap-md-7 {
    gap: 6.5rem !important;
  }
  .gap-md-8 {
    gap: 8rem !important;
  }
  .gap-md-9 {
    gap: 10rem !important;
  }
  .gap-md-95 {
    gap: 12rem !important;
  }
  .gap-md-10 {
    gap: 14rem !important;
  }
  .gap-md-11 {
    gap: 21rem !important;
  }
  .gap-md-12 {
    gap: 16rem !important;
  }
  .gap-md-13 {
    gap: 17rem !important;
  }
  .gap-md-14 {
    gap: 18rem !important;
  }
  .gap-md-15 {
    gap: 19rem !important;
  }
  .gap-md-16 {
    gap: 20rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.4rem !important;
  }
  .m-lg-3 {
    margin: 0.8rem !important;
  }
  .m-lg-35 {
    margin: 1.25rem !important;
  }
  .m-lg-4 {
    margin: 1.875rem !important;
  }
  .m-lg-45 {
    margin: 2.25rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-55 {
    margin: 3.5rem !important;
  }
  .m-lg-6 {
    margin: 3.85rem !important;
  }
  .m-lg-65 {
    margin: 5rem !important;
  }
  .m-lg-68 {
    margin: 5.5rem !important;
  }
  .m-lg-7 {
    margin: 6.5rem !important;
  }
  .m-lg-8 {
    margin: 8rem !important;
  }
  .m-lg-9 {
    margin: 10rem !important;
  }
  .m-lg-95 {
    margin: 12rem !important;
  }
  .m-lg-10 {
    margin: 14rem !important;
  }
  .m-lg-11 {
    margin: 21rem !important;
  }
  .m-lg-12 {
    margin: 16rem !important;
  }
  .m-lg-13 {
    margin: 17rem !important;
  }
  .m-lg-14 {
    margin: 18rem !important;
  }
  .m-lg-15 {
    margin: 19rem !important;
  }
  .m-lg-16 {
    margin: 20rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-lg-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-lg-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-lg-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-lg-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-lg-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-lg-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-lg-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-lg-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-lg-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-lg-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-lg-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-lg-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-lg-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-lg-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-lg-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-lg-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-lg-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-lg-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-lg-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-lg-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-lg-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-lg-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.4rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.8rem !important;
  }
  .mt-lg-35 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-45 {
    margin-top: 2.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-55 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-6 {
    margin-top: 3.85rem !important;
  }
  .mt-lg-65 {
    margin-top: 5rem !important;
  }
  .mt-lg-68 {
    margin-top: 5.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 6.5rem !important;
  }
  .mt-lg-8 {
    margin-top: 8rem !important;
  }
  .mt-lg-9 {
    margin-top: 10rem !important;
  }
  .mt-lg-95 {
    margin-top: 12rem !important;
  }
  .mt-lg-10 {
    margin-top: 14rem !important;
  }
  .mt-lg-11 {
    margin-top: 21rem !important;
  }
  .mt-lg-12 {
    margin-top: 16rem !important;
  }
  .mt-lg-13 {
    margin-top: 17rem !important;
  }
  .mt-lg-14 {
    margin-top: 18rem !important;
  }
  .mt-lg-15 {
    margin-top: 19rem !important;
  }
  .mt-lg-16 {
    margin-top: 20rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.4rem !important;
  }
  .me-lg-3 {
    margin-right: 0.8rem !important;
  }
  .me-lg-35 {
    margin-right: 1.25rem !important;
  }
  .me-lg-4 {
    margin-right: 1.875rem !important;
  }
  .me-lg-45 {
    margin-right: 2.25rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-55 {
    margin-right: 3.5rem !important;
  }
  .me-lg-6 {
    margin-right: 3.85rem !important;
  }
  .me-lg-65 {
    margin-right: 5rem !important;
  }
  .me-lg-68 {
    margin-right: 5.5rem !important;
  }
  .me-lg-7 {
    margin-right: 6.5rem !important;
  }
  .me-lg-8 {
    margin-right: 8rem !important;
  }
  .me-lg-9 {
    margin-right: 10rem !important;
  }
  .me-lg-95 {
    margin-right: 12rem !important;
  }
  .me-lg-10 {
    margin-right: 14rem !important;
  }
  .me-lg-11 {
    margin-right: 21rem !important;
  }
  .me-lg-12 {
    margin-right: 16rem !important;
  }
  .me-lg-13 {
    margin-right: 17rem !important;
  }
  .me-lg-14 {
    margin-right: 18rem !important;
  }
  .me-lg-15 {
    margin-right: 19rem !important;
  }
  .me-lg-16 {
    margin-right: 20rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-lg-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-lg-65 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-95 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 14rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 21rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 16rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 17rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 18rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 19rem !important;
  }
  .mb-lg-16 {
    margin-bottom: 20rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.4rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.8rem !important;
  }
  .ms-lg-35 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-45 {
    margin-left: 2.25rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-55 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-6 {
    margin-left: 3.85rem !important;
  }
  .ms-lg-65 {
    margin-left: 5rem !important;
  }
  .ms-lg-68 {
    margin-left: 5.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 6.5rem !important;
  }
  .ms-lg-8 {
    margin-left: 8rem !important;
  }
  .ms-lg-9 {
    margin-left: 10rem !important;
  }
  .ms-lg-95 {
    margin-left: 12rem !important;
  }
  .ms-lg-10 {
    margin-left: 14rem !important;
  }
  .ms-lg-11 {
    margin-left: 21rem !important;
  }
  .ms-lg-12 {
    margin-left: 16rem !important;
  }
  .ms-lg-13 {
    margin-left: 17rem !important;
  }
  .ms-lg-14 {
    margin-left: 18rem !important;
  }
  .ms-lg-15 {
    margin-left: 19rem !important;
  }
  .ms-lg-16 {
    margin-left: 20rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.4rem !important;
  }
  .p-lg-3 {
    padding: 0.8rem !important;
  }
  .p-lg-35 {
    padding: 1.25rem !important;
  }
  .p-lg-4 {
    padding: 1.875rem !important;
  }
  .p-lg-45 {
    padding: 2.25rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-55 {
    padding: 3.5rem !important;
  }
  .p-lg-6 {
    padding: 3.85rem !important;
  }
  .p-lg-65 {
    padding: 5rem !important;
  }
  .p-lg-68 {
    padding: 5.5rem !important;
  }
  .p-lg-7 {
    padding: 6.5rem !important;
  }
  .p-lg-8 {
    padding: 8rem !important;
  }
  .p-lg-9 {
    padding: 10rem !important;
  }
  .p-lg-95 {
    padding: 12rem !important;
  }
  .p-lg-10 {
    padding: 14rem !important;
  }
  .p-lg-11 {
    padding: 21rem !important;
  }
  .p-lg-12 {
    padding: 16rem !important;
  }
  .p-lg-13 {
    padding: 17rem !important;
  }
  .p-lg-14 {
    padding: 18rem !important;
  }
  .p-lg-15 {
    padding: 19rem !important;
  }
  .p-lg-16 {
    padding: 20rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-lg-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-lg-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-lg-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-lg-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-lg-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-lg-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-lg-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-lg-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-lg-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-lg-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-lg-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-lg-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-lg-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-lg-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-lg-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-lg-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-lg-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-lg-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-lg-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-lg-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-lg-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-lg-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-lg-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-lg-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-lg-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-lg-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.4rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.8rem !important;
  }
  .pt-lg-35 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-45 {
    padding-top: 2.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-55 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-6 {
    padding-top: 3.85rem !important;
  }
  .pt-lg-65 {
    padding-top: 5rem !important;
  }
  .pt-lg-68 {
    padding-top: 5.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 6.5rem !important;
  }
  .pt-lg-8 {
    padding-top: 8rem !important;
  }
  .pt-lg-9 {
    padding-top: 10rem !important;
  }
  .pt-lg-95 {
    padding-top: 12rem !important;
  }
  .pt-lg-10 {
    padding-top: 14rem !important;
  }
  .pt-lg-11 {
    padding-top: 21rem !important;
  }
  .pt-lg-12 {
    padding-top: 16rem !important;
  }
  .pt-lg-13 {
    padding-top: 17rem !important;
  }
  .pt-lg-14 {
    padding-top: 18rem !important;
  }
  .pt-lg-15 {
    padding-top: 19rem !important;
  }
  .pt-lg-16 {
    padding-top: 20rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.4rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.8rem !important;
  }
  .pe-lg-35 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-45 {
    padding-right: 2.25rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-55 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-6 {
    padding-right: 3.85rem !important;
  }
  .pe-lg-65 {
    padding-right: 5rem !important;
  }
  .pe-lg-68 {
    padding-right: 5.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 6.5rem !important;
  }
  .pe-lg-8 {
    padding-right: 8rem !important;
  }
  .pe-lg-9 {
    padding-right: 10rem !important;
  }
  .pe-lg-95 {
    padding-right: 12rem !important;
  }
  .pe-lg-10 {
    padding-right: 14rem !important;
  }
  .pe-lg-11 {
    padding-right: 21rem !important;
  }
  .pe-lg-12 {
    padding-right: 16rem !important;
  }
  .pe-lg-13 {
    padding-right: 17rem !important;
  }
  .pe-lg-14 {
    padding-right: 18rem !important;
  }
  .pe-lg-15 {
    padding-right: 19rem !important;
  }
  .pe-lg-16 {
    padding-right: 20rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-lg-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-lg-65 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-95 {
    padding-bottom: 12rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 14rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 21rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 16rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 17rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 18rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 19rem !important;
  }
  .pb-lg-16 {
    padding-bottom: 20rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.4rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.8rem !important;
  }
  .ps-lg-35 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-45 {
    padding-left: 2.25rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-55 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-6 {
    padding-left: 3.85rem !important;
  }
  .ps-lg-65 {
    padding-left: 5rem !important;
  }
  .ps-lg-68 {
    padding-left: 5.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 6.5rem !important;
  }
  .ps-lg-8 {
    padding-left: 8rem !important;
  }
  .ps-lg-9 {
    padding-left: 10rem !important;
  }
  .ps-lg-95 {
    padding-left: 12rem !important;
  }
  .ps-lg-10 {
    padding-left: 14rem !important;
  }
  .ps-lg-11 {
    padding-left: 21rem !important;
  }
  .ps-lg-12 {
    padding-left: 16rem !important;
  }
  .ps-lg-13 {
    padding-left: 17rem !important;
  }
  .ps-lg-14 {
    padding-left: 18rem !important;
  }
  .ps-lg-15 {
    padding-left: 19rem !important;
  }
  .ps-lg-16 {
    padding-left: 20rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.4rem !important;
  }
  .gap-lg-3 {
    gap: 0.8rem !important;
  }
  .gap-lg-35 {
    gap: 1.25rem !important;
  }
  .gap-lg-4 {
    gap: 1.875rem !important;
  }
  .gap-lg-45 {
    gap: 2.25rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-55 {
    gap: 3.5rem !important;
  }
  .gap-lg-6 {
    gap: 3.85rem !important;
  }
  .gap-lg-65 {
    gap: 5rem !important;
  }
  .gap-lg-68 {
    gap: 5.5rem !important;
  }
  .gap-lg-7 {
    gap: 6.5rem !important;
  }
  .gap-lg-8 {
    gap: 8rem !important;
  }
  .gap-lg-9 {
    gap: 10rem !important;
  }
  .gap-lg-95 {
    gap: 12rem !important;
  }
  .gap-lg-10 {
    gap: 14rem !important;
  }
  .gap-lg-11 {
    gap: 21rem !important;
  }
  .gap-lg-12 {
    gap: 16rem !important;
  }
  .gap-lg-13 {
    gap: 17rem !important;
  }
  .gap-lg-14 {
    gap: 18rem !important;
  }
  .gap-lg-15 {
    gap: 19rem !important;
  }
  .gap-lg-16 {
    gap: 20rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1025px) {
  .float-ipad-start {
    float: left !important;
  }
  .float-ipad-end {
    float: right !important;
  }
  .float-ipad-none {
    float: none !important;
  }
  .d-ipad-inline {
    display: inline !important;
  }
  .d-ipad-inline-block {
    display: inline-block !important;
  }
  .d-ipad-block {
    display: block !important;
  }
  .d-ipad-grid {
    display: grid !important;
  }
  .d-ipad-table {
    display: table !important;
  }
  .d-ipad-table-row {
    display: table-row !important;
  }
  .d-ipad-table-cell {
    display: table-cell !important;
  }
  .d-ipad-flex {
    display: flex !important;
  }
  .d-ipad-inline-flex {
    display: inline-flex !important;
  }
  .d-ipad-none {
    display: none !important;
  }
  .flex-ipad-fill {
    flex: 1 1 auto !important;
  }
  .flex-ipad-row {
    flex-direction: row !important;
  }
  .flex-ipad-column {
    flex-direction: column !important;
  }
  .flex-ipad-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-ipad-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-ipad-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-ipad-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-ipad-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-ipad-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-ipad-wrap {
    flex-wrap: wrap !important;
  }
  .flex-ipad-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-ipad-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-ipad-start {
    justify-content: flex-start !important;
  }
  .justify-content-ipad-end {
    justify-content: flex-end !important;
  }
  .justify-content-ipad-center {
    justify-content: center !important;
  }
  .justify-content-ipad-between {
    justify-content: space-between !important;
  }
  .justify-content-ipad-around {
    justify-content: space-around !important;
  }
  .justify-content-ipad-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-ipad-start {
    align-items: flex-start !important;
  }
  .align-items-ipad-end {
    align-items: flex-end !important;
  }
  .align-items-ipad-center {
    align-items: center !important;
  }
  .align-items-ipad-baseline {
    align-items: baseline !important;
  }
  .align-items-ipad-stretch {
    align-items: stretch !important;
  }
  .align-content-ipad-start {
    align-content: flex-start !important;
  }
  .align-content-ipad-end {
    align-content: flex-end !important;
  }
  .align-content-ipad-center {
    align-content: center !important;
  }
  .align-content-ipad-between {
    align-content: space-between !important;
  }
  .align-content-ipad-around {
    align-content: space-around !important;
  }
  .align-content-ipad-stretch {
    align-content: stretch !important;
  }
  .align-self-ipad-auto {
    align-self: auto !important;
  }
  .align-self-ipad-start {
    align-self: flex-start !important;
  }
  .align-self-ipad-end {
    align-self: flex-end !important;
  }
  .align-self-ipad-center {
    align-self: center !important;
  }
  .align-self-ipad-baseline {
    align-self: baseline !important;
  }
  .align-self-ipad-stretch {
    align-self: stretch !important;
  }
  .order-ipad-first {
    order: -1 !important;
  }
  .order-ipad-0 {
    order: 0 !important;
  }
  .order-ipad-1 {
    order: 1 !important;
  }
  .order-ipad-2 {
    order: 2 !important;
  }
  .order-ipad-3 {
    order: 3 !important;
  }
  .order-ipad-4 {
    order: 4 !important;
  }
  .order-ipad-5 {
    order: 5 !important;
  }
  .order-ipad-last {
    order: 6 !important;
  }
  .m-ipad-0 {
    margin: 0 !important;
  }
  .m-ipad-1 {
    margin: 0.25rem !important;
  }
  .m-ipad-2 {
    margin: 0.4rem !important;
  }
  .m-ipad-3 {
    margin: 0.8rem !important;
  }
  .m-ipad-35 {
    margin: 1.25rem !important;
  }
  .m-ipad-4 {
    margin: 1.875rem !important;
  }
  .m-ipad-45 {
    margin: 2.25rem !important;
  }
  .m-ipad-5 {
    margin: 3rem !important;
  }
  .m-ipad-55 {
    margin: 3.5rem !important;
  }
  .m-ipad-6 {
    margin: 3.85rem !important;
  }
  .m-ipad-65 {
    margin: 5rem !important;
  }
  .m-ipad-68 {
    margin: 5.5rem !important;
  }
  .m-ipad-7 {
    margin: 6.5rem !important;
  }
  .m-ipad-8 {
    margin: 8rem !important;
  }
  .m-ipad-9 {
    margin: 10rem !important;
  }
  .m-ipad-95 {
    margin: 12rem !important;
  }
  .m-ipad-10 {
    margin: 14rem !important;
  }
  .m-ipad-11 {
    margin: 21rem !important;
  }
  .m-ipad-12 {
    margin: 16rem !important;
  }
  .m-ipad-13 {
    margin: 17rem !important;
  }
  .m-ipad-14 {
    margin: 18rem !important;
  }
  .m-ipad-15 {
    margin: 19rem !important;
  }
  .m-ipad-16 {
    margin: 20rem !important;
  }
  .m-ipad-auto {
    margin: auto !important;
  }
  .mx-ipad-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-ipad-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-ipad-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-ipad-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-ipad-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-ipad-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-ipad-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-ipad-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-ipad-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-ipad-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-ipad-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-ipad-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-ipad-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-ipad-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-ipad-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-ipad-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-ipad-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-ipad-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-ipad-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-ipad-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-ipad-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-ipad-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-ipad-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-ipad-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-ipad-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-ipad-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-ipad-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-ipad-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-ipad-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-ipad-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-ipad-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-ipad-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-ipad-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-ipad-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-ipad-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-ipad-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-ipad-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-ipad-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-ipad-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-ipad-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-ipad-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-ipad-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-ipad-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-ipad-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-ipad-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-ipad-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-ipad-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-ipad-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-ipad-0 {
    margin-top: 0 !important;
  }
  .mt-ipad-1 {
    margin-top: 0.25rem !important;
  }
  .mt-ipad-2 {
    margin-top: 0.4rem !important;
  }
  .mt-ipad-3 {
    margin-top: 0.8rem !important;
  }
  .mt-ipad-35 {
    margin-top: 1.25rem !important;
  }
  .mt-ipad-4 {
    margin-top: 1.875rem !important;
  }
  .mt-ipad-45 {
    margin-top: 2.25rem !important;
  }
  .mt-ipad-5 {
    margin-top: 3rem !important;
  }
  .mt-ipad-55 {
    margin-top: 3.5rem !important;
  }
  .mt-ipad-6 {
    margin-top: 3.85rem !important;
  }
  .mt-ipad-65 {
    margin-top: 5rem !important;
  }
  .mt-ipad-68 {
    margin-top: 5.5rem !important;
  }
  .mt-ipad-7 {
    margin-top: 6.5rem !important;
  }
  .mt-ipad-8 {
    margin-top: 8rem !important;
  }
  .mt-ipad-9 {
    margin-top: 10rem !important;
  }
  .mt-ipad-95 {
    margin-top: 12rem !important;
  }
  .mt-ipad-10 {
    margin-top: 14rem !important;
  }
  .mt-ipad-11 {
    margin-top: 21rem !important;
  }
  .mt-ipad-12 {
    margin-top: 16rem !important;
  }
  .mt-ipad-13 {
    margin-top: 17rem !important;
  }
  .mt-ipad-14 {
    margin-top: 18rem !important;
  }
  .mt-ipad-15 {
    margin-top: 19rem !important;
  }
  .mt-ipad-16 {
    margin-top: 20rem !important;
  }
  .mt-ipad-auto {
    margin-top: auto !important;
  }
  .me-ipad-0 {
    margin-right: 0 !important;
  }
  .me-ipad-1 {
    margin-right: 0.25rem !important;
  }
  .me-ipad-2 {
    margin-right: 0.4rem !important;
  }
  .me-ipad-3 {
    margin-right: 0.8rem !important;
  }
  .me-ipad-35 {
    margin-right: 1.25rem !important;
  }
  .me-ipad-4 {
    margin-right: 1.875rem !important;
  }
  .me-ipad-45 {
    margin-right: 2.25rem !important;
  }
  .me-ipad-5 {
    margin-right: 3rem !important;
  }
  .me-ipad-55 {
    margin-right: 3.5rem !important;
  }
  .me-ipad-6 {
    margin-right: 3.85rem !important;
  }
  .me-ipad-65 {
    margin-right: 5rem !important;
  }
  .me-ipad-68 {
    margin-right: 5.5rem !important;
  }
  .me-ipad-7 {
    margin-right: 6.5rem !important;
  }
  .me-ipad-8 {
    margin-right: 8rem !important;
  }
  .me-ipad-9 {
    margin-right: 10rem !important;
  }
  .me-ipad-95 {
    margin-right: 12rem !important;
  }
  .me-ipad-10 {
    margin-right: 14rem !important;
  }
  .me-ipad-11 {
    margin-right: 21rem !important;
  }
  .me-ipad-12 {
    margin-right: 16rem !important;
  }
  .me-ipad-13 {
    margin-right: 17rem !important;
  }
  .me-ipad-14 {
    margin-right: 18rem !important;
  }
  .me-ipad-15 {
    margin-right: 19rem !important;
  }
  .me-ipad-16 {
    margin-right: 20rem !important;
  }
  .me-ipad-auto {
    margin-right: auto !important;
  }
  .mb-ipad-0 {
    margin-bottom: 0 !important;
  }
  .mb-ipad-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-ipad-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-ipad-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-ipad-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-ipad-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-ipad-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-ipad-5 {
    margin-bottom: 3rem !important;
  }
  .mb-ipad-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-ipad-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-ipad-65 {
    margin-bottom: 5rem !important;
  }
  .mb-ipad-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-ipad-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-ipad-8 {
    margin-bottom: 8rem !important;
  }
  .mb-ipad-9 {
    margin-bottom: 10rem !important;
  }
  .mb-ipad-95 {
    margin-bottom: 12rem !important;
  }
  .mb-ipad-10 {
    margin-bottom: 14rem !important;
  }
  .mb-ipad-11 {
    margin-bottom: 21rem !important;
  }
  .mb-ipad-12 {
    margin-bottom: 16rem !important;
  }
  .mb-ipad-13 {
    margin-bottom: 17rem !important;
  }
  .mb-ipad-14 {
    margin-bottom: 18rem !important;
  }
  .mb-ipad-15 {
    margin-bottom: 19rem !important;
  }
  .mb-ipad-16 {
    margin-bottom: 20rem !important;
  }
  .mb-ipad-auto {
    margin-bottom: auto !important;
  }
  .ms-ipad-0 {
    margin-left: 0 !important;
  }
  .ms-ipad-1 {
    margin-left: 0.25rem !important;
  }
  .ms-ipad-2 {
    margin-left: 0.4rem !important;
  }
  .ms-ipad-3 {
    margin-left: 0.8rem !important;
  }
  .ms-ipad-35 {
    margin-left: 1.25rem !important;
  }
  .ms-ipad-4 {
    margin-left: 1.875rem !important;
  }
  .ms-ipad-45 {
    margin-left: 2.25rem !important;
  }
  .ms-ipad-5 {
    margin-left: 3rem !important;
  }
  .ms-ipad-55 {
    margin-left: 3.5rem !important;
  }
  .ms-ipad-6 {
    margin-left: 3.85rem !important;
  }
  .ms-ipad-65 {
    margin-left: 5rem !important;
  }
  .ms-ipad-68 {
    margin-left: 5.5rem !important;
  }
  .ms-ipad-7 {
    margin-left: 6.5rem !important;
  }
  .ms-ipad-8 {
    margin-left: 8rem !important;
  }
  .ms-ipad-9 {
    margin-left: 10rem !important;
  }
  .ms-ipad-95 {
    margin-left: 12rem !important;
  }
  .ms-ipad-10 {
    margin-left: 14rem !important;
  }
  .ms-ipad-11 {
    margin-left: 21rem !important;
  }
  .ms-ipad-12 {
    margin-left: 16rem !important;
  }
  .ms-ipad-13 {
    margin-left: 17rem !important;
  }
  .ms-ipad-14 {
    margin-left: 18rem !important;
  }
  .ms-ipad-15 {
    margin-left: 19rem !important;
  }
  .ms-ipad-16 {
    margin-left: 20rem !important;
  }
  .ms-ipad-auto {
    margin-left: auto !important;
  }
  .p-ipad-0 {
    padding: 0 !important;
  }
  .p-ipad-1 {
    padding: 0.25rem !important;
  }
  .p-ipad-2 {
    padding: 0.4rem !important;
  }
  .p-ipad-3 {
    padding: 0.8rem !important;
  }
  .p-ipad-35 {
    padding: 1.25rem !important;
  }
  .p-ipad-4 {
    padding: 1.875rem !important;
  }
  .p-ipad-45 {
    padding: 2.25rem !important;
  }
  .p-ipad-5 {
    padding: 3rem !important;
  }
  .p-ipad-55 {
    padding: 3.5rem !important;
  }
  .p-ipad-6 {
    padding: 3.85rem !important;
  }
  .p-ipad-65 {
    padding: 5rem !important;
  }
  .p-ipad-68 {
    padding: 5.5rem !important;
  }
  .p-ipad-7 {
    padding: 6.5rem !important;
  }
  .p-ipad-8 {
    padding: 8rem !important;
  }
  .p-ipad-9 {
    padding: 10rem !important;
  }
  .p-ipad-95 {
    padding: 12rem !important;
  }
  .p-ipad-10 {
    padding: 14rem !important;
  }
  .p-ipad-11 {
    padding: 21rem !important;
  }
  .p-ipad-12 {
    padding: 16rem !important;
  }
  .p-ipad-13 {
    padding: 17rem !important;
  }
  .p-ipad-14 {
    padding: 18rem !important;
  }
  .p-ipad-15 {
    padding: 19rem !important;
  }
  .p-ipad-16 {
    padding: 20rem !important;
  }
  .px-ipad-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-ipad-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-ipad-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-ipad-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-ipad-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-ipad-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-ipad-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-ipad-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-ipad-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-ipad-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-ipad-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-ipad-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-ipad-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-ipad-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-ipad-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-ipad-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-ipad-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-ipad-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-ipad-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-ipad-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-ipad-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-ipad-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-ipad-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .py-ipad-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-ipad-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-ipad-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-ipad-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-ipad-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-ipad-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-ipad-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-ipad-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-ipad-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-ipad-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-ipad-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-ipad-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-ipad-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-ipad-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-ipad-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-ipad-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-ipad-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-ipad-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-ipad-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-ipad-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-ipad-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-ipad-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-ipad-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .pt-ipad-0 {
    padding-top: 0 !important;
  }
  .pt-ipad-1 {
    padding-top: 0.25rem !important;
  }
  .pt-ipad-2 {
    padding-top: 0.4rem !important;
  }
  .pt-ipad-3 {
    padding-top: 0.8rem !important;
  }
  .pt-ipad-35 {
    padding-top: 1.25rem !important;
  }
  .pt-ipad-4 {
    padding-top: 1.875rem !important;
  }
  .pt-ipad-45 {
    padding-top: 2.25rem !important;
  }
  .pt-ipad-5 {
    padding-top: 3rem !important;
  }
  .pt-ipad-55 {
    padding-top: 3.5rem !important;
  }
  .pt-ipad-6 {
    padding-top: 3.85rem !important;
  }
  .pt-ipad-65 {
    padding-top: 5rem !important;
  }
  .pt-ipad-68 {
    padding-top: 5.5rem !important;
  }
  .pt-ipad-7 {
    padding-top: 6.5rem !important;
  }
  .pt-ipad-8 {
    padding-top: 8rem !important;
  }
  .pt-ipad-9 {
    padding-top: 10rem !important;
  }
  .pt-ipad-95 {
    padding-top: 12rem !important;
  }
  .pt-ipad-10 {
    padding-top: 14rem !important;
  }
  .pt-ipad-11 {
    padding-top: 21rem !important;
  }
  .pt-ipad-12 {
    padding-top: 16rem !important;
  }
  .pt-ipad-13 {
    padding-top: 17rem !important;
  }
  .pt-ipad-14 {
    padding-top: 18rem !important;
  }
  .pt-ipad-15 {
    padding-top: 19rem !important;
  }
  .pt-ipad-16 {
    padding-top: 20rem !important;
  }
  .pe-ipad-0 {
    padding-right: 0 !important;
  }
  .pe-ipad-1 {
    padding-right: 0.25rem !important;
  }
  .pe-ipad-2 {
    padding-right: 0.4rem !important;
  }
  .pe-ipad-3 {
    padding-right: 0.8rem !important;
  }
  .pe-ipad-35 {
    padding-right: 1.25rem !important;
  }
  .pe-ipad-4 {
    padding-right: 1.875rem !important;
  }
  .pe-ipad-45 {
    padding-right: 2.25rem !important;
  }
  .pe-ipad-5 {
    padding-right: 3rem !important;
  }
  .pe-ipad-55 {
    padding-right: 3.5rem !important;
  }
  .pe-ipad-6 {
    padding-right: 3.85rem !important;
  }
  .pe-ipad-65 {
    padding-right: 5rem !important;
  }
  .pe-ipad-68 {
    padding-right: 5.5rem !important;
  }
  .pe-ipad-7 {
    padding-right: 6.5rem !important;
  }
  .pe-ipad-8 {
    padding-right: 8rem !important;
  }
  .pe-ipad-9 {
    padding-right: 10rem !important;
  }
  .pe-ipad-95 {
    padding-right: 12rem !important;
  }
  .pe-ipad-10 {
    padding-right: 14rem !important;
  }
  .pe-ipad-11 {
    padding-right: 21rem !important;
  }
  .pe-ipad-12 {
    padding-right: 16rem !important;
  }
  .pe-ipad-13 {
    padding-right: 17rem !important;
  }
  .pe-ipad-14 {
    padding-right: 18rem !important;
  }
  .pe-ipad-15 {
    padding-right: 19rem !important;
  }
  .pe-ipad-16 {
    padding-right: 20rem !important;
  }
  .pb-ipad-0 {
    padding-bottom: 0 !important;
  }
  .pb-ipad-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-ipad-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-ipad-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-ipad-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-ipad-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-ipad-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-ipad-5 {
    padding-bottom: 3rem !important;
  }
  .pb-ipad-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-ipad-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-ipad-65 {
    padding-bottom: 5rem !important;
  }
  .pb-ipad-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-ipad-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-ipad-8 {
    padding-bottom: 8rem !important;
  }
  .pb-ipad-9 {
    padding-bottom: 10rem !important;
  }
  .pb-ipad-95 {
    padding-bottom: 12rem !important;
  }
  .pb-ipad-10 {
    padding-bottom: 14rem !important;
  }
  .pb-ipad-11 {
    padding-bottom: 21rem !important;
  }
  .pb-ipad-12 {
    padding-bottom: 16rem !important;
  }
  .pb-ipad-13 {
    padding-bottom: 17rem !important;
  }
  .pb-ipad-14 {
    padding-bottom: 18rem !important;
  }
  .pb-ipad-15 {
    padding-bottom: 19rem !important;
  }
  .pb-ipad-16 {
    padding-bottom: 20rem !important;
  }
  .ps-ipad-0 {
    padding-left: 0 !important;
  }
  .ps-ipad-1 {
    padding-left: 0.25rem !important;
  }
  .ps-ipad-2 {
    padding-left: 0.4rem !important;
  }
  .ps-ipad-3 {
    padding-left: 0.8rem !important;
  }
  .ps-ipad-35 {
    padding-left: 1.25rem !important;
  }
  .ps-ipad-4 {
    padding-left: 1.875rem !important;
  }
  .ps-ipad-45 {
    padding-left: 2.25rem !important;
  }
  .ps-ipad-5 {
    padding-left: 3rem !important;
  }
  .ps-ipad-55 {
    padding-left: 3.5rem !important;
  }
  .ps-ipad-6 {
    padding-left: 3.85rem !important;
  }
  .ps-ipad-65 {
    padding-left: 5rem !important;
  }
  .ps-ipad-68 {
    padding-left: 5.5rem !important;
  }
  .ps-ipad-7 {
    padding-left: 6.5rem !important;
  }
  .ps-ipad-8 {
    padding-left: 8rem !important;
  }
  .ps-ipad-9 {
    padding-left: 10rem !important;
  }
  .ps-ipad-95 {
    padding-left: 12rem !important;
  }
  .ps-ipad-10 {
    padding-left: 14rem !important;
  }
  .ps-ipad-11 {
    padding-left: 21rem !important;
  }
  .ps-ipad-12 {
    padding-left: 16rem !important;
  }
  .ps-ipad-13 {
    padding-left: 17rem !important;
  }
  .ps-ipad-14 {
    padding-left: 18rem !important;
  }
  .ps-ipad-15 {
    padding-left: 19rem !important;
  }
  .ps-ipad-16 {
    padding-left: 20rem !important;
  }
  .gap-ipad-0 {
    gap: 0 !important;
  }
  .gap-ipad-1 {
    gap: 0.25rem !important;
  }
  .gap-ipad-2 {
    gap: 0.4rem !important;
  }
  .gap-ipad-3 {
    gap: 0.8rem !important;
  }
  .gap-ipad-35 {
    gap: 1.25rem !important;
  }
  .gap-ipad-4 {
    gap: 1.875rem !important;
  }
  .gap-ipad-45 {
    gap: 2.25rem !important;
  }
  .gap-ipad-5 {
    gap: 3rem !important;
  }
  .gap-ipad-55 {
    gap: 3.5rem !important;
  }
  .gap-ipad-6 {
    gap: 3.85rem !important;
  }
  .gap-ipad-65 {
    gap: 5rem !important;
  }
  .gap-ipad-68 {
    gap: 5.5rem !important;
  }
  .gap-ipad-7 {
    gap: 6.5rem !important;
  }
  .gap-ipad-8 {
    gap: 8rem !important;
  }
  .gap-ipad-9 {
    gap: 10rem !important;
  }
  .gap-ipad-95 {
    gap: 12rem !important;
  }
  .gap-ipad-10 {
    gap: 14rem !important;
  }
  .gap-ipad-11 {
    gap: 21rem !important;
  }
  .gap-ipad-12 {
    gap: 16rem !important;
  }
  .gap-ipad-13 {
    gap: 17rem !important;
  }
  .gap-ipad-14 {
    gap: 18rem !important;
  }
  .gap-ipad-15 {
    gap: 19rem !important;
  }
  .gap-ipad-16 {
    gap: 20rem !important;
  }
  .text-ipad-start {
    text-align: left !important;
  }
  .text-ipad-end {
    text-align: right !important;
  }
  .text-ipad-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.4rem !important;
  }
  .m-xl-3 {
    margin: 0.8rem !important;
  }
  .m-xl-35 {
    margin: 1.25rem !important;
  }
  .m-xl-4 {
    margin: 1.875rem !important;
  }
  .m-xl-45 {
    margin: 2.25rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-55 {
    margin: 3.5rem !important;
  }
  .m-xl-6 {
    margin: 3.85rem !important;
  }
  .m-xl-65 {
    margin: 5rem !important;
  }
  .m-xl-68 {
    margin: 5.5rem !important;
  }
  .m-xl-7 {
    margin: 6.5rem !important;
  }
  .m-xl-8 {
    margin: 8rem !important;
  }
  .m-xl-9 {
    margin: 10rem !important;
  }
  .m-xl-95 {
    margin: 12rem !important;
  }
  .m-xl-10 {
    margin: 14rem !important;
  }
  .m-xl-11 {
    margin: 21rem !important;
  }
  .m-xl-12 {
    margin: 16rem !important;
  }
  .m-xl-13 {
    margin: 17rem !important;
  }
  .m-xl-14 {
    margin: 18rem !important;
  }
  .m-xl-15 {
    margin: 19rem !important;
  }
  .m-xl-16 {
    margin: 20rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-xl-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-xl-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-xl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xl-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-xl-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xl-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-xl-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-xl-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-xl-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-xl-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-xl-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-xl-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-xl-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-xl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xl-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-xl-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xl-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-xl-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-xl-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-xl-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.4rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.8rem !important;
  }
  .mt-xl-35 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-45 {
    margin-top: 2.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-55 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-6 {
    margin-top: 3.85rem !important;
  }
  .mt-xl-65 {
    margin-top: 5rem !important;
  }
  .mt-xl-68 {
    margin-top: 5.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 6.5rem !important;
  }
  .mt-xl-8 {
    margin-top: 8rem !important;
  }
  .mt-xl-9 {
    margin-top: 10rem !important;
  }
  .mt-xl-95 {
    margin-top: 12rem !important;
  }
  .mt-xl-10 {
    margin-top: 14rem !important;
  }
  .mt-xl-11 {
    margin-top: 21rem !important;
  }
  .mt-xl-12 {
    margin-top: 16rem !important;
  }
  .mt-xl-13 {
    margin-top: 17rem !important;
  }
  .mt-xl-14 {
    margin-top: 18rem !important;
  }
  .mt-xl-15 {
    margin-top: 19rem !important;
  }
  .mt-xl-16 {
    margin-top: 20rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.4rem !important;
  }
  .me-xl-3 {
    margin-right: 0.8rem !important;
  }
  .me-xl-35 {
    margin-right: 1.25rem !important;
  }
  .me-xl-4 {
    margin-right: 1.875rem !important;
  }
  .me-xl-45 {
    margin-right: 2.25rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-55 {
    margin-right: 3.5rem !important;
  }
  .me-xl-6 {
    margin-right: 3.85rem !important;
  }
  .me-xl-65 {
    margin-right: 5rem !important;
  }
  .me-xl-68 {
    margin-right: 5.5rem !important;
  }
  .me-xl-7 {
    margin-right: 6.5rem !important;
  }
  .me-xl-8 {
    margin-right: 8rem !important;
  }
  .me-xl-9 {
    margin-right: 10rem !important;
  }
  .me-xl-95 {
    margin-right: 12rem !important;
  }
  .me-xl-10 {
    margin-right: 14rem !important;
  }
  .me-xl-11 {
    margin-right: 21rem !important;
  }
  .me-xl-12 {
    margin-right: 16rem !important;
  }
  .me-xl-13 {
    margin-right: 17rem !important;
  }
  .me-xl-14 {
    margin-right: 18rem !important;
  }
  .me-xl-15 {
    margin-right: 19rem !important;
  }
  .me-xl-16 {
    margin-right: 20rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-xl-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-xl-65 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-95 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 14rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 21rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 16rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 17rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 18rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 19rem !important;
  }
  .mb-xl-16 {
    margin-bottom: 20rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.4rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.8rem !important;
  }
  .ms-xl-35 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-45 {
    margin-left: 2.25rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-55 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-6 {
    margin-left: 3.85rem !important;
  }
  .ms-xl-65 {
    margin-left: 5rem !important;
  }
  .ms-xl-68 {
    margin-left: 5.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 6.5rem !important;
  }
  .ms-xl-8 {
    margin-left: 8rem !important;
  }
  .ms-xl-9 {
    margin-left: 10rem !important;
  }
  .ms-xl-95 {
    margin-left: 12rem !important;
  }
  .ms-xl-10 {
    margin-left: 14rem !important;
  }
  .ms-xl-11 {
    margin-left: 21rem !important;
  }
  .ms-xl-12 {
    margin-left: 16rem !important;
  }
  .ms-xl-13 {
    margin-left: 17rem !important;
  }
  .ms-xl-14 {
    margin-left: 18rem !important;
  }
  .ms-xl-15 {
    margin-left: 19rem !important;
  }
  .ms-xl-16 {
    margin-left: 20rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.4rem !important;
  }
  .p-xl-3 {
    padding: 0.8rem !important;
  }
  .p-xl-35 {
    padding: 1.25rem !important;
  }
  .p-xl-4 {
    padding: 1.875rem !important;
  }
  .p-xl-45 {
    padding: 2.25rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-55 {
    padding: 3.5rem !important;
  }
  .p-xl-6 {
    padding: 3.85rem !important;
  }
  .p-xl-65 {
    padding: 5rem !important;
  }
  .p-xl-68 {
    padding: 5.5rem !important;
  }
  .p-xl-7 {
    padding: 6.5rem !important;
  }
  .p-xl-8 {
    padding: 8rem !important;
  }
  .p-xl-9 {
    padding: 10rem !important;
  }
  .p-xl-95 {
    padding: 12rem !important;
  }
  .p-xl-10 {
    padding: 14rem !important;
  }
  .p-xl-11 {
    padding: 21rem !important;
  }
  .p-xl-12 {
    padding: 16rem !important;
  }
  .p-xl-13 {
    padding: 17rem !important;
  }
  .p-xl-14 {
    padding: 18rem !important;
  }
  .p-xl-15 {
    padding: 19rem !important;
  }
  .p-xl-16 {
    padding: 20rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-xl-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-xl-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-xl-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-xl-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-xl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-xl-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-xl-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xl-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-xl-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-xl-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-xl-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-xl-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-xl-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-xl-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-xl-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-xl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-xl-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-xl-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xl-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-xl-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-xl-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-xl-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.4rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.8rem !important;
  }
  .pt-xl-35 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-45 {
    padding-top: 2.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-55 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-6 {
    padding-top: 3.85rem !important;
  }
  .pt-xl-65 {
    padding-top: 5rem !important;
  }
  .pt-xl-68 {
    padding-top: 5.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 6.5rem !important;
  }
  .pt-xl-8 {
    padding-top: 8rem !important;
  }
  .pt-xl-9 {
    padding-top: 10rem !important;
  }
  .pt-xl-95 {
    padding-top: 12rem !important;
  }
  .pt-xl-10 {
    padding-top: 14rem !important;
  }
  .pt-xl-11 {
    padding-top: 21rem !important;
  }
  .pt-xl-12 {
    padding-top: 16rem !important;
  }
  .pt-xl-13 {
    padding-top: 17rem !important;
  }
  .pt-xl-14 {
    padding-top: 18rem !important;
  }
  .pt-xl-15 {
    padding-top: 19rem !important;
  }
  .pt-xl-16 {
    padding-top: 20rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.4rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.8rem !important;
  }
  .pe-xl-35 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-45 {
    padding-right: 2.25rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-55 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-6 {
    padding-right: 3.85rem !important;
  }
  .pe-xl-65 {
    padding-right: 5rem !important;
  }
  .pe-xl-68 {
    padding-right: 5.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 6.5rem !important;
  }
  .pe-xl-8 {
    padding-right: 8rem !important;
  }
  .pe-xl-9 {
    padding-right: 10rem !important;
  }
  .pe-xl-95 {
    padding-right: 12rem !important;
  }
  .pe-xl-10 {
    padding-right: 14rem !important;
  }
  .pe-xl-11 {
    padding-right: 21rem !important;
  }
  .pe-xl-12 {
    padding-right: 16rem !important;
  }
  .pe-xl-13 {
    padding-right: 17rem !important;
  }
  .pe-xl-14 {
    padding-right: 18rem !important;
  }
  .pe-xl-15 {
    padding-right: 19rem !important;
  }
  .pe-xl-16 {
    padding-right: 20rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-xl-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-xl-65 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-95 {
    padding-bottom: 12rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 14rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 21rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 16rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 17rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 18rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 19rem !important;
  }
  .pb-xl-16 {
    padding-bottom: 20rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.4rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.8rem !important;
  }
  .ps-xl-35 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-45 {
    padding-left: 2.25rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-55 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-6 {
    padding-left: 3.85rem !important;
  }
  .ps-xl-65 {
    padding-left: 5rem !important;
  }
  .ps-xl-68 {
    padding-left: 5.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 6.5rem !important;
  }
  .ps-xl-8 {
    padding-left: 8rem !important;
  }
  .ps-xl-9 {
    padding-left: 10rem !important;
  }
  .ps-xl-95 {
    padding-left: 12rem !important;
  }
  .ps-xl-10 {
    padding-left: 14rem !important;
  }
  .ps-xl-11 {
    padding-left: 21rem !important;
  }
  .ps-xl-12 {
    padding-left: 16rem !important;
  }
  .ps-xl-13 {
    padding-left: 17rem !important;
  }
  .ps-xl-14 {
    padding-left: 18rem !important;
  }
  .ps-xl-15 {
    padding-left: 19rem !important;
  }
  .ps-xl-16 {
    padding-left: 20rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.4rem !important;
  }
  .gap-xl-3 {
    gap: 0.8rem !important;
  }
  .gap-xl-35 {
    gap: 1.25rem !important;
  }
  .gap-xl-4 {
    gap: 1.875rem !important;
  }
  .gap-xl-45 {
    gap: 2.25rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-55 {
    gap: 3.5rem !important;
  }
  .gap-xl-6 {
    gap: 3.85rem !important;
  }
  .gap-xl-65 {
    gap: 5rem !important;
  }
  .gap-xl-68 {
    gap: 5.5rem !important;
  }
  .gap-xl-7 {
    gap: 6.5rem !important;
  }
  .gap-xl-8 {
    gap: 8rem !important;
  }
  .gap-xl-9 {
    gap: 10rem !important;
  }
  .gap-xl-95 {
    gap: 12rem !important;
  }
  .gap-xl-10 {
    gap: 14rem !important;
  }
  .gap-xl-11 {
    gap: 21rem !important;
  }
  .gap-xl-12 {
    gap: 16rem !important;
  }
  .gap-xl-13 {
    gap: 17rem !important;
  }
  .gap-xl-14 {
    gap: 18rem !important;
  }
  .gap-xl-15 {
    gap: 19rem !important;
  }
  .gap-xl-16 {
    gap: 20rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1281px) {
  .float-laptop-start {
    float: left !important;
  }
  .float-laptop-end {
    float: right !important;
  }
  .float-laptop-none {
    float: none !important;
  }
  .d-laptop-inline {
    display: inline !important;
  }
  .d-laptop-inline-block {
    display: inline-block !important;
  }
  .d-laptop-block {
    display: block !important;
  }
  .d-laptop-grid {
    display: grid !important;
  }
  .d-laptop-table {
    display: table !important;
  }
  .d-laptop-table-row {
    display: table-row !important;
  }
  .d-laptop-table-cell {
    display: table-cell !important;
  }
  .d-laptop-flex {
    display: flex !important;
  }
  .d-laptop-inline-flex {
    display: inline-flex !important;
  }
  .d-laptop-none {
    display: none !important;
  }
  .flex-laptop-fill {
    flex: 1 1 auto !important;
  }
  .flex-laptop-row {
    flex-direction: row !important;
  }
  .flex-laptop-column {
    flex-direction: column !important;
  }
  .flex-laptop-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-laptop-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-laptop-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-laptop-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-laptop-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-laptop-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-laptop-wrap {
    flex-wrap: wrap !important;
  }
  .flex-laptop-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-laptop-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-laptop-start {
    justify-content: flex-start !important;
  }
  .justify-content-laptop-end {
    justify-content: flex-end !important;
  }
  .justify-content-laptop-center {
    justify-content: center !important;
  }
  .justify-content-laptop-between {
    justify-content: space-between !important;
  }
  .justify-content-laptop-around {
    justify-content: space-around !important;
  }
  .justify-content-laptop-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-laptop-start {
    align-items: flex-start !important;
  }
  .align-items-laptop-end {
    align-items: flex-end !important;
  }
  .align-items-laptop-center {
    align-items: center !important;
  }
  .align-items-laptop-baseline {
    align-items: baseline !important;
  }
  .align-items-laptop-stretch {
    align-items: stretch !important;
  }
  .align-content-laptop-start {
    align-content: flex-start !important;
  }
  .align-content-laptop-end {
    align-content: flex-end !important;
  }
  .align-content-laptop-center {
    align-content: center !important;
  }
  .align-content-laptop-between {
    align-content: space-between !important;
  }
  .align-content-laptop-around {
    align-content: space-around !important;
  }
  .align-content-laptop-stretch {
    align-content: stretch !important;
  }
  .align-self-laptop-auto {
    align-self: auto !important;
  }
  .align-self-laptop-start {
    align-self: flex-start !important;
  }
  .align-self-laptop-end {
    align-self: flex-end !important;
  }
  .align-self-laptop-center {
    align-self: center !important;
  }
  .align-self-laptop-baseline {
    align-self: baseline !important;
  }
  .align-self-laptop-stretch {
    align-self: stretch !important;
  }
  .order-laptop-first {
    order: -1 !important;
  }
  .order-laptop-0 {
    order: 0 !important;
  }
  .order-laptop-1 {
    order: 1 !important;
  }
  .order-laptop-2 {
    order: 2 !important;
  }
  .order-laptop-3 {
    order: 3 !important;
  }
  .order-laptop-4 {
    order: 4 !important;
  }
  .order-laptop-5 {
    order: 5 !important;
  }
  .order-laptop-last {
    order: 6 !important;
  }
  .m-laptop-0 {
    margin: 0 !important;
  }
  .m-laptop-1 {
    margin: 0.25rem !important;
  }
  .m-laptop-2 {
    margin: 0.4rem !important;
  }
  .m-laptop-3 {
    margin: 0.8rem !important;
  }
  .m-laptop-35 {
    margin: 1.25rem !important;
  }
  .m-laptop-4 {
    margin: 1.875rem !important;
  }
  .m-laptop-45 {
    margin: 2.25rem !important;
  }
  .m-laptop-5 {
    margin: 3rem !important;
  }
  .m-laptop-55 {
    margin: 3.5rem !important;
  }
  .m-laptop-6 {
    margin: 3.85rem !important;
  }
  .m-laptop-65 {
    margin: 5rem !important;
  }
  .m-laptop-68 {
    margin: 5.5rem !important;
  }
  .m-laptop-7 {
    margin: 6.5rem !important;
  }
  .m-laptop-8 {
    margin: 8rem !important;
  }
  .m-laptop-9 {
    margin: 10rem !important;
  }
  .m-laptop-95 {
    margin: 12rem !important;
  }
  .m-laptop-10 {
    margin: 14rem !important;
  }
  .m-laptop-11 {
    margin: 21rem !important;
  }
  .m-laptop-12 {
    margin: 16rem !important;
  }
  .m-laptop-13 {
    margin: 17rem !important;
  }
  .m-laptop-14 {
    margin: 18rem !important;
  }
  .m-laptop-15 {
    margin: 19rem !important;
  }
  .m-laptop-16 {
    margin: 20rem !important;
  }
  .m-laptop-auto {
    margin: auto !important;
  }
  .mx-laptop-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-laptop-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-laptop-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-laptop-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-laptop-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-laptop-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-laptop-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-laptop-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-laptop-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-laptop-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-laptop-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-laptop-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-laptop-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-laptop-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-laptop-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-laptop-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-laptop-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-laptop-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-laptop-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-laptop-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-laptop-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-laptop-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-laptop-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-laptop-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-laptop-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-laptop-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-laptop-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-laptop-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-laptop-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-laptop-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-laptop-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-laptop-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-laptop-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-laptop-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-laptop-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-laptop-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-laptop-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-laptop-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-laptop-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-laptop-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-laptop-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-laptop-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-laptop-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-laptop-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-laptop-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-laptop-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-laptop-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-laptop-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-laptop-0 {
    margin-top: 0 !important;
  }
  .mt-laptop-1 {
    margin-top: 0.25rem !important;
  }
  .mt-laptop-2 {
    margin-top: 0.4rem !important;
  }
  .mt-laptop-3 {
    margin-top: 0.8rem !important;
  }
  .mt-laptop-35 {
    margin-top: 1.25rem !important;
  }
  .mt-laptop-4 {
    margin-top: 1.875rem !important;
  }
  .mt-laptop-45 {
    margin-top: 2.25rem !important;
  }
  .mt-laptop-5 {
    margin-top: 3rem !important;
  }
  .mt-laptop-55 {
    margin-top: 3.5rem !important;
  }
  .mt-laptop-6 {
    margin-top: 3.85rem !important;
  }
  .mt-laptop-65 {
    margin-top: 5rem !important;
  }
  .mt-laptop-68 {
    margin-top: 5.5rem !important;
  }
  .mt-laptop-7 {
    margin-top: 6.5rem !important;
  }
  .mt-laptop-8 {
    margin-top: 8rem !important;
  }
  .mt-laptop-9 {
    margin-top: 10rem !important;
  }
  .mt-laptop-95 {
    margin-top: 12rem !important;
  }
  .mt-laptop-10 {
    margin-top: 14rem !important;
  }
  .mt-laptop-11 {
    margin-top: 21rem !important;
  }
  .mt-laptop-12 {
    margin-top: 16rem !important;
  }
  .mt-laptop-13 {
    margin-top: 17rem !important;
  }
  .mt-laptop-14 {
    margin-top: 18rem !important;
  }
  .mt-laptop-15 {
    margin-top: 19rem !important;
  }
  .mt-laptop-16 {
    margin-top: 20rem !important;
  }
  .mt-laptop-auto {
    margin-top: auto !important;
  }
  .me-laptop-0 {
    margin-right: 0 !important;
  }
  .me-laptop-1 {
    margin-right: 0.25rem !important;
  }
  .me-laptop-2 {
    margin-right: 0.4rem !important;
  }
  .me-laptop-3 {
    margin-right: 0.8rem !important;
  }
  .me-laptop-35 {
    margin-right: 1.25rem !important;
  }
  .me-laptop-4 {
    margin-right: 1.875rem !important;
  }
  .me-laptop-45 {
    margin-right: 2.25rem !important;
  }
  .me-laptop-5 {
    margin-right: 3rem !important;
  }
  .me-laptop-55 {
    margin-right: 3.5rem !important;
  }
  .me-laptop-6 {
    margin-right: 3.85rem !important;
  }
  .me-laptop-65 {
    margin-right: 5rem !important;
  }
  .me-laptop-68 {
    margin-right: 5.5rem !important;
  }
  .me-laptop-7 {
    margin-right: 6.5rem !important;
  }
  .me-laptop-8 {
    margin-right: 8rem !important;
  }
  .me-laptop-9 {
    margin-right: 10rem !important;
  }
  .me-laptop-95 {
    margin-right: 12rem !important;
  }
  .me-laptop-10 {
    margin-right: 14rem !important;
  }
  .me-laptop-11 {
    margin-right: 21rem !important;
  }
  .me-laptop-12 {
    margin-right: 16rem !important;
  }
  .me-laptop-13 {
    margin-right: 17rem !important;
  }
  .me-laptop-14 {
    margin-right: 18rem !important;
  }
  .me-laptop-15 {
    margin-right: 19rem !important;
  }
  .me-laptop-16 {
    margin-right: 20rem !important;
  }
  .me-laptop-auto {
    margin-right: auto !important;
  }
  .mb-laptop-0 {
    margin-bottom: 0 !important;
  }
  .mb-laptop-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-laptop-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-laptop-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-laptop-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-laptop-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-laptop-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-laptop-5 {
    margin-bottom: 3rem !important;
  }
  .mb-laptop-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-laptop-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-laptop-65 {
    margin-bottom: 5rem !important;
  }
  .mb-laptop-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-laptop-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-laptop-8 {
    margin-bottom: 8rem !important;
  }
  .mb-laptop-9 {
    margin-bottom: 10rem !important;
  }
  .mb-laptop-95 {
    margin-bottom: 12rem !important;
  }
  .mb-laptop-10 {
    margin-bottom: 14rem !important;
  }
  .mb-laptop-11 {
    margin-bottom: 21rem !important;
  }
  .mb-laptop-12 {
    margin-bottom: 16rem !important;
  }
  .mb-laptop-13 {
    margin-bottom: 17rem !important;
  }
  .mb-laptop-14 {
    margin-bottom: 18rem !important;
  }
  .mb-laptop-15 {
    margin-bottom: 19rem !important;
  }
  .mb-laptop-16 {
    margin-bottom: 20rem !important;
  }
  .mb-laptop-auto {
    margin-bottom: auto !important;
  }
  .ms-laptop-0 {
    margin-left: 0 !important;
  }
  .ms-laptop-1 {
    margin-left: 0.25rem !important;
  }
  .ms-laptop-2 {
    margin-left: 0.4rem !important;
  }
  .ms-laptop-3 {
    margin-left: 0.8rem !important;
  }
  .ms-laptop-35 {
    margin-left: 1.25rem !important;
  }
  .ms-laptop-4 {
    margin-left: 1.875rem !important;
  }
  .ms-laptop-45 {
    margin-left: 2.25rem !important;
  }
  .ms-laptop-5 {
    margin-left: 3rem !important;
  }
  .ms-laptop-55 {
    margin-left: 3.5rem !important;
  }
  .ms-laptop-6 {
    margin-left: 3.85rem !important;
  }
  .ms-laptop-65 {
    margin-left: 5rem !important;
  }
  .ms-laptop-68 {
    margin-left: 5.5rem !important;
  }
  .ms-laptop-7 {
    margin-left: 6.5rem !important;
  }
  .ms-laptop-8 {
    margin-left: 8rem !important;
  }
  .ms-laptop-9 {
    margin-left: 10rem !important;
  }
  .ms-laptop-95 {
    margin-left: 12rem !important;
  }
  .ms-laptop-10 {
    margin-left: 14rem !important;
  }
  .ms-laptop-11 {
    margin-left: 21rem !important;
  }
  .ms-laptop-12 {
    margin-left: 16rem !important;
  }
  .ms-laptop-13 {
    margin-left: 17rem !important;
  }
  .ms-laptop-14 {
    margin-left: 18rem !important;
  }
  .ms-laptop-15 {
    margin-left: 19rem !important;
  }
  .ms-laptop-16 {
    margin-left: 20rem !important;
  }
  .ms-laptop-auto {
    margin-left: auto !important;
  }
  .p-laptop-0 {
    padding: 0 !important;
  }
  .p-laptop-1 {
    padding: 0.25rem !important;
  }
  .p-laptop-2 {
    padding: 0.4rem !important;
  }
  .p-laptop-3 {
    padding: 0.8rem !important;
  }
  .p-laptop-35 {
    padding: 1.25rem !important;
  }
  .p-laptop-4 {
    padding: 1.875rem !important;
  }
  .p-laptop-45 {
    padding: 2.25rem !important;
  }
  .p-laptop-5 {
    padding: 3rem !important;
  }
  .p-laptop-55 {
    padding: 3.5rem !important;
  }
  .p-laptop-6 {
    padding: 3.85rem !important;
  }
  .p-laptop-65 {
    padding: 5rem !important;
  }
  .p-laptop-68 {
    padding: 5.5rem !important;
  }
  .p-laptop-7 {
    padding: 6.5rem !important;
  }
  .p-laptop-8 {
    padding: 8rem !important;
  }
  .p-laptop-9 {
    padding: 10rem !important;
  }
  .p-laptop-95 {
    padding: 12rem !important;
  }
  .p-laptop-10 {
    padding: 14rem !important;
  }
  .p-laptop-11 {
    padding: 21rem !important;
  }
  .p-laptop-12 {
    padding: 16rem !important;
  }
  .p-laptop-13 {
    padding: 17rem !important;
  }
  .p-laptop-14 {
    padding: 18rem !important;
  }
  .p-laptop-15 {
    padding: 19rem !important;
  }
  .p-laptop-16 {
    padding: 20rem !important;
  }
  .px-laptop-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-laptop-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-laptop-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-laptop-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-laptop-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-laptop-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-laptop-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-laptop-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-laptop-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-laptop-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-laptop-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-laptop-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-laptop-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-laptop-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-laptop-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-laptop-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-laptop-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-laptop-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-laptop-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-laptop-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-laptop-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-laptop-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-laptop-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .py-laptop-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-laptop-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-laptop-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-laptop-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-laptop-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-laptop-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-laptop-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-laptop-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-laptop-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-laptop-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-laptop-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-laptop-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-laptop-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-laptop-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-laptop-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-laptop-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-laptop-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-laptop-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-laptop-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-laptop-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-laptop-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-laptop-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-laptop-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .pt-laptop-0 {
    padding-top: 0 !important;
  }
  .pt-laptop-1 {
    padding-top: 0.25rem !important;
  }
  .pt-laptop-2 {
    padding-top: 0.4rem !important;
  }
  .pt-laptop-3 {
    padding-top: 0.8rem !important;
  }
  .pt-laptop-35 {
    padding-top: 1.25rem !important;
  }
  .pt-laptop-4 {
    padding-top: 1.875rem !important;
  }
  .pt-laptop-45 {
    padding-top: 2.25rem !important;
  }
  .pt-laptop-5 {
    padding-top: 3rem !important;
  }
  .pt-laptop-55 {
    padding-top: 3.5rem !important;
  }
  .pt-laptop-6 {
    padding-top: 3.85rem !important;
  }
  .pt-laptop-65 {
    padding-top: 5rem !important;
  }
  .pt-laptop-68 {
    padding-top: 5.5rem !important;
  }
  .pt-laptop-7 {
    padding-top: 6.5rem !important;
  }
  .pt-laptop-8 {
    padding-top: 8rem !important;
  }
  .pt-laptop-9 {
    padding-top: 10rem !important;
  }
  .pt-laptop-95 {
    padding-top: 12rem !important;
  }
  .pt-laptop-10 {
    padding-top: 14rem !important;
  }
  .pt-laptop-11 {
    padding-top: 21rem !important;
  }
  .pt-laptop-12 {
    padding-top: 16rem !important;
  }
  .pt-laptop-13 {
    padding-top: 17rem !important;
  }
  .pt-laptop-14 {
    padding-top: 18rem !important;
  }
  .pt-laptop-15 {
    padding-top: 19rem !important;
  }
  .pt-laptop-16 {
    padding-top: 20rem !important;
  }
  .pe-laptop-0 {
    padding-right: 0 !important;
  }
  .pe-laptop-1 {
    padding-right: 0.25rem !important;
  }
  .pe-laptop-2 {
    padding-right: 0.4rem !important;
  }
  .pe-laptop-3 {
    padding-right: 0.8rem !important;
  }
  .pe-laptop-35 {
    padding-right: 1.25rem !important;
  }
  .pe-laptop-4 {
    padding-right: 1.875rem !important;
  }
  .pe-laptop-45 {
    padding-right: 2.25rem !important;
  }
  .pe-laptop-5 {
    padding-right: 3rem !important;
  }
  .pe-laptop-55 {
    padding-right: 3.5rem !important;
  }
  .pe-laptop-6 {
    padding-right: 3.85rem !important;
  }
  .pe-laptop-65 {
    padding-right: 5rem !important;
  }
  .pe-laptop-68 {
    padding-right: 5.5rem !important;
  }
  .pe-laptop-7 {
    padding-right: 6.5rem !important;
  }
  .pe-laptop-8 {
    padding-right: 8rem !important;
  }
  .pe-laptop-9 {
    padding-right: 10rem !important;
  }
  .pe-laptop-95 {
    padding-right: 12rem !important;
  }
  .pe-laptop-10 {
    padding-right: 14rem !important;
  }
  .pe-laptop-11 {
    padding-right: 21rem !important;
  }
  .pe-laptop-12 {
    padding-right: 16rem !important;
  }
  .pe-laptop-13 {
    padding-right: 17rem !important;
  }
  .pe-laptop-14 {
    padding-right: 18rem !important;
  }
  .pe-laptop-15 {
    padding-right: 19rem !important;
  }
  .pe-laptop-16 {
    padding-right: 20rem !important;
  }
  .pb-laptop-0 {
    padding-bottom: 0 !important;
  }
  .pb-laptop-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-laptop-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-laptop-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-laptop-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-laptop-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-laptop-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-laptop-5 {
    padding-bottom: 3rem !important;
  }
  .pb-laptop-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-laptop-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-laptop-65 {
    padding-bottom: 5rem !important;
  }
  .pb-laptop-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-laptop-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-laptop-8 {
    padding-bottom: 8rem !important;
  }
  .pb-laptop-9 {
    padding-bottom: 10rem !important;
  }
  .pb-laptop-95 {
    padding-bottom: 12rem !important;
  }
  .pb-laptop-10 {
    padding-bottom: 14rem !important;
  }
  .pb-laptop-11 {
    padding-bottom: 21rem !important;
  }
  .pb-laptop-12 {
    padding-bottom: 16rem !important;
  }
  .pb-laptop-13 {
    padding-bottom: 17rem !important;
  }
  .pb-laptop-14 {
    padding-bottom: 18rem !important;
  }
  .pb-laptop-15 {
    padding-bottom: 19rem !important;
  }
  .pb-laptop-16 {
    padding-bottom: 20rem !important;
  }
  .ps-laptop-0 {
    padding-left: 0 !important;
  }
  .ps-laptop-1 {
    padding-left: 0.25rem !important;
  }
  .ps-laptop-2 {
    padding-left: 0.4rem !important;
  }
  .ps-laptop-3 {
    padding-left: 0.8rem !important;
  }
  .ps-laptop-35 {
    padding-left: 1.25rem !important;
  }
  .ps-laptop-4 {
    padding-left: 1.875rem !important;
  }
  .ps-laptop-45 {
    padding-left: 2.25rem !important;
  }
  .ps-laptop-5 {
    padding-left: 3rem !important;
  }
  .ps-laptop-55 {
    padding-left: 3.5rem !important;
  }
  .ps-laptop-6 {
    padding-left: 3.85rem !important;
  }
  .ps-laptop-65 {
    padding-left: 5rem !important;
  }
  .ps-laptop-68 {
    padding-left: 5.5rem !important;
  }
  .ps-laptop-7 {
    padding-left: 6.5rem !important;
  }
  .ps-laptop-8 {
    padding-left: 8rem !important;
  }
  .ps-laptop-9 {
    padding-left: 10rem !important;
  }
  .ps-laptop-95 {
    padding-left: 12rem !important;
  }
  .ps-laptop-10 {
    padding-left: 14rem !important;
  }
  .ps-laptop-11 {
    padding-left: 21rem !important;
  }
  .ps-laptop-12 {
    padding-left: 16rem !important;
  }
  .ps-laptop-13 {
    padding-left: 17rem !important;
  }
  .ps-laptop-14 {
    padding-left: 18rem !important;
  }
  .ps-laptop-15 {
    padding-left: 19rem !important;
  }
  .ps-laptop-16 {
    padding-left: 20rem !important;
  }
  .gap-laptop-0 {
    gap: 0 !important;
  }
  .gap-laptop-1 {
    gap: 0.25rem !important;
  }
  .gap-laptop-2 {
    gap: 0.4rem !important;
  }
  .gap-laptop-3 {
    gap: 0.8rem !important;
  }
  .gap-laptop-35 {
    gap: 1.25rem !important;
  }
  .gap-laptop-4 {
    gap: 1.875rem !important;
  }
  .gap-laptop-45 {
    gap: 2.25rem !important;
  }
  .gap-laptop-5 {
    gap: 3rem !important;
  }
  .gap-laptop-55 {
    gap: 3.5rem !important;
  }
  .gap-laptop-6 {
    gap: 3.85rem !important;
  }
  .gap-laptop-65 {
    gap: 5rem !important;
  }
  .gap-laptop-68 {
    gap: 5.5rem !important;
  }
  .gap-laptop-7 {
    gap: 6.5rem !important;
  }
  .gap-laptop-8 {
    gap: 8rem !important;
  }
  .gap-laptop-9 {
    gap: 10rem !important;
  }
  .gap-laptop-95 {
    gap: 12rem !important;
  }
  .gap-laptop-10 {
    gap: 14rem !important;
  }
  .gap-laptop-11 {
    gap: 21rem !important;
  }
  .gap-laptop-12 {
    gap: 16rem !important;
  }
  .gap-laptop-13 {
    gap: 17rem !important;
  }
  .gap-laptop-14 {
    gap: 18rem !important;
  }
  .gap-laptop-15 {
    gap: 19rem !important;
  }
  .gap-laptop-16 {
    gap: 20rem !important;
  }
  .text-laptop-start {
    text-align: left !important;
  }
  .text-laptop-end {
    text-align: right !important;
  }
  .text-laptop-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.4rem !important;
  }
  .m-xxl-3 {
    margin: 0.8rem !important;
  }
  .m-xxl-35 {
    margin: 1.25rem !important;
  }
  .m-xxl-4 {
    margin: 1.875rem !important;
  }
  .m-xxl-45 {
    margin: 2.25rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-55 {
    margin: 3.5rem !important;
  }
  .m-xxl-6 {
    margin: 3.85rem !important;
  }
  .m-xxl-65 {
    margin: 5rem !important;
  }
  .m-xxl-68 {
    margin: 5.5rem !important;
  }
  .m-xxl-7 {
    margin: 6.5rem !important;
  }
  .m-xxl-8 {
    margin: 8rem !important;
  }
  .m-xxl-9 {
    margin: 10rem !important;
  }
  .m-xxl-95 {
    margin: 12rem !important;
  }
  .m-xxl-10 {
    margin: 14rem !important;
  }
  .m-xxl-11 {
    margin: 21rem !important;
  }
  .m-xxl-12 {
    margin: 16rem !important;
  }
  .m-xxl-13 {
    margin: 17rem !important;
  }
  .m-xxl-14 {
    margin: 18rem !important;
  }
  .m-xxl-15 {
    margin: 19rem !important;
  }
  .m-xxl-16 {
    margin: 20rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-xxl-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-xxl-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-xxl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xxl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xxl-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-xxl-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xxl-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-xxl-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-xxl-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-xxl-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-xxl-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-xxl-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-xxl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xxl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xxl-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-xxl-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xxl-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-xxl-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-xxl-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-xxl-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.4rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.8rem !important;
  }
  .mt-xxl-35 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-45 {
    margin-top: 2.25rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-55 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-6 {
    margin-top: 3.85rem !important;
  }
  .mt-xxl-65 {
    margin-top: 5rem !important;
  }
  .mt-xxl-68 {
    margin-top: 5.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 6.5rem !important;
  }
  .mt-xxl-8 {
    margin-top: 8rem !important;
  }
  .mt-xxl-9 {
    margin-top: 10rem !important;
  }
  .mt-xxl-95 {
    margin-top: 12rem !important;
  }
  .mt-xxl-10 {
    margin-top: 14rem !important;
  }
  .mt-xxl-11 {
    margin-top: 21rem !important;
  }
  .mt-xxl-12 {
    margin-top: 16rem !important;
  }
  .mt-xxl-13 {
    margin-top: 17rem !important;
  }
  .mt-xxl-14 {
    margin-top: 18rem !important;
  }
  .mt-xxl-15 {
    margin-top: 19rem !important;
  }
  .mt-xxl-16 {
    margin-top: 20rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.4rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.8rem !important;
  }
  .me-xxl-35 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-45 {
    margin-right: 2.25rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-55 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-6 {
    margin-right: 3.85rem !important;
  }
  .me-xxl-65 {
    margin-right: 5rem !important;
  }
  .me-xxl-68 {
    margin-right: 5.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 6.5rem !important;
  }
  .me-xxl-8 {
    margin-right: 8rem !important;
  }
  .me-xxl-9 {
    margin-right: 10rem !important;
  }
  .me-xxl-95 {
    margin-right: 12rem !important;
  }
  .me-xxl-10 {
    margin-right: 14rem !important;
  }
  .me-xxl-11 {
    margin-right: 21rem !important;
  }
  .me-xxl-12 {
    margin-right: 16rem !important;
  }
  .me-xxl-13 {
    margin-right: 17rem !important;
  }
  .me-xxl-14 {
    margin-right: 18rem !important;
  }
  .me-xxl-15 {
    margin-right: 19rem !important;
  }
  .me-xxl-16 {
    margin-right: 20rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-xxl-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-xxl-65 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-95 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 14rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 21rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 16rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 17rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 18rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 19rem !important;
  }
  .mb-xxl-16 {
    margin-bottom: 20rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.4rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.8rem !important;
  }
  .ms-xxl-35 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-45 {
    margin-left: 2.25rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-55 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-6 {
    margin-left: 3.85rem !important;
  }
  .ms-xxl-65 {
    margin-left: 5rem !important;
  }
  .ms-xxl-68 {
    margin-left: 5.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 6.5rem !important;
  }
  .ms-xxl-8 {
    margin-left: 8rem !important;
  }
  .ms-xxl-9 {
    margin-left: 10rem !important;
  }
  .ms-xxl-95 {
    margin-left: 12rem !important;
  }
  .ms-xxl-10 {
    margin-left: 14rem !important;
  }
  .ms-xxl-11 {
    margin-left: 21rem !important;
  }
  .ms-xxl-12 {
    margin-left: 16rem !important;
  }
  .ms-xxl-13 {
    margin-left: 17rem !important;
  }
  .ms-xxl-14 {
    margin-left: 18rem !important;
  }
  .ms-xxl-15 {
    margin-left: 19rem !important;
  }
  .ms-xxl-16 {
    margin-left: 20rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.4rem !important;
  }
  .p-xxl-3 {
    padding: 0.8rem !important;
  }
  .p-xxl-35 {
    padding: 1.25rem !important;
  }
  .p-xxl-4 {
    padding: 1.875rem !important;
  }
  .p-xxl-45 {
    padding: 2.25rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-55 {
    padding: 3.5rem !important;
  }
  .p-xxl-6 {
    padding: 3.85rem !important;
  }
  .p-xxl-65 {
    padding: 5rem !important;
  }
  .p-xxl-68 {
    padding: 5.5rem !important;
  }
  .p-xxl-7 {
    padding: 6.5rem !important;
  }
  .p-xxl-8 {
    padding: 8rem !important;
  }
  .p-xxl-9 {
    padding: 10rem !important;
  }
  .p-xxl-95 {
    padding: 12rem !important;
  }
  .p-xxl-10 {
    padding: 14rem !important;
  }
  .p-xxl-11 {
    padding: 21rem !important;
  }
  .p-xxl-12 {
    padding: 16rem !important;
  }
  .p-xxl-13 {
    padding: 17rem !important;
  }
  .p-xxl-14 {
    padding: 18rem !important;
  }
  .p-xxl-15 {
    padding: 19rem !important;
  }
  .p-xxl-16 {
    padding: 20rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-xxl-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-xxl-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-xxl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xxl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-xxl-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-xxl-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xxl-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-xxl-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-xxl-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-xxl-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-xxl-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-xxl-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-xxl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xxl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-xxl-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-xxl-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xxl-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-xxl-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-xxl-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-xxl-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.4rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.8rem !important;
  }
  .pt-xxl-35 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-45 {
    padding-top: 2.25rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-55 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-6 {
    padding-top: 3.85rem !important;
  }
  .pt-xxl-65 {
    padding-top: 5rem !important;
  }
  .pt-xxl-68 {
    padding-top: 5.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 6.5rem !important;
  }
  .pt-xxl-8 {
    padding-top: 8rem !important;
  }
  .pt-xxl-9 {
    padding-top: 10rem !important;
  }
  .pt-xxl-95 {
    padding-top: 12rem !important;
  }
  .pt-xxl-10 {
    padding-top: 14rem !important;
  }
  .pt-xxl-11 {
    padding-top: 21rem !important;
  }
  .pt-xxl-12 {
    padding-top: 16rem !important;
  }
  .pt-xxl-13 {
    padding-top: 17rem !important;
  }
  .pt-xxl-14 {
    padding-top: 18rem !important;
  }
  .pt-xxl-15 {
    padding-top: 19rem !important;
  }
  .pt-xxl-16 {
    padding-top: 20rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.4rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.8rem !important;
  }
  .pe-xxl-35 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-45 {
    padding-right: 2.25rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-55 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-6 {
    padding-right: 3.85rem !important;
  }
  .pe-xxl-65 {
    padding-right: 5rem !important;
  }
  .pe-xxl-68 {
    padding-right: 5.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 6.5rem !important;
  }
  .pe-xxl-8 {
    padding-right: 8rem !important;
  }
  .pe-xxl-9 {
    padding-right: 10rem !important;
  }
  .pe-xxl-95 {
    padding-right: 12rem !important;
  }
  .pe-xxl-10 {
    padding-right: 14rem !important;
  }
  .pe-xxl-11 {
    padding-right: 21rem !important;
  }
  .pe-xxl-12 {
    padding-right: 16rem !important;
  }
  .pe-xxl-13 {
    padding-right: 17rem !important;
  }
  .pe-xxl-14 {
    padding-right: 18rem !important;
  }
  .pe-xxl-15 {
    padding-right: 19rem !important;
  }
  .pe-xxl-16 {
    padding-right: 20rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-xxl-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-xxl-65 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-95 {
    padding-bottom: 12rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 14rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 21rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 16rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 17rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 18rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 19rem !important;
  }
  .pb-xxl-16 {
    padding-bottom: 20rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.4rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.8rem !important;
  }
  .ps-xxl-35 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-45 {
    padding-left: 2.25rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-55 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-6 {
    padding-left: 3.85rem !important;
  }
  .ps-xxl-65 {
    padding-left: 5rem !important;
  }
  .ps-xxl-68 {
    padding-left: 5.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 6.5rem !important;
  }
  .ps-xxl-8 {
    padding-left: 8rem !important;
  }
  .ps-xxl-9 {
    padding-left: 10rem !important;
  }
  .ps-xxl-95 {
    padding-left: 12rem !important;
  }
  .ps-xxl-10 {
    padding-left: 14rem !important;
  }
  .ps-xxl-11 {
    padding-left: 21rem !important;
  }
  .ps-xxl-12 {
    padding-left: 16rem !important;
  }
  .ps-xxl-13 {
    padding-left: 17rem !important;
  }
  .ps-xxl-14 {
    padding-left: 18rem !important;
  }
  .ps-xxl-15 {
    padding-left: 19rem !important;
  }
  .ps-xxl-16 {
    padding-left: 20rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.4rem !important;
  }
  .gap-xxl-3 {
    gap: 0.8rem !important;
  }
  .gap-xxl-35 {
    gap: 1.25rem !important;
  }
  .gap-xxl-4 {
    gap: 1.875rem !important;
  }
  .gap-xxl-45 {
    gap: 2.25rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-55 {
    gap: 3.5rem !important;
  }
  .gap-xxl-6 {
    gap: 3.85rem !important;
  }
  .gap-xxl-65 {
    gap: 5rem !important;
  }
  .gap-xxl-68 {
    gap: 5.5rem !important;
  }
  .gap-xxl-7 {
    gap: 6.5rem !important;
  }
  .gap-xxl-8 {
    gap: 8rem !important;
  }
  .gap-xxl-9 {
    gap: 10rem !important;
  }
  .gap-xxl-95 {
    gap: 12rem !important;
  }
  .gap-xxl-10 {
    gap: 14rem !important;
  }
  .gap-xxl-11 {
    gap: 21rem !important;
  }
  .gap-xxl-12 {
    gap: 16rem !important;
  }
  .gap-xxl-13 {
    gap: 17rem !important;
  }
  .gap-xxl-14 {
    gap: 18rem !important;
  }
  .gap-xxl-15 {
    gap: 19rem !important;
  }
  .gap-xxl-16 {
    gap: 20rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1441px) {
  .float-big_laptop-start {
    float: left !important;
  }
  .float-big_laptop-end {
    float: right !important;
  }
  .float-big_laptop-none {
    float: none !important;
  }
  .d-big_laptop-inline {
    display: inline !important;
  }
  .d-big_laptop-inline-block {
    display: inline-block !important;
  }
  .d-big_laptop-block {
    display: block !important;
  }
  .d-big_laptop-grid {
    display: grid !important;
  }
  .d-big_laptop-table {
    display: table !important;
  }
  .d-big_laptop-table-row {
    display: table-row !important;
  }
  .d-big_laptop-table-cell {
    display: table-cell !important;
  }
  .d-big_laptop-flex {
    display: flex !important;
  }
  .d-big_laptop-inline-flex {
    display: inline-flex !important;
  }
  .d-big_laptop-none {
    display: none !important;
  }
  .flex-big_laptop-fill {
    flex: 1 1 auto !important;
  }
  .flex-big_laptop-row {
    flex-direction: row !important;
  }
  .flex-big_laptop-column {
    flex-direction: column !important;
  }
  .flex-big_laptop-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-big_laptop-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-big_laptop-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-big_laptop-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-big_laptop-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-big_laptop-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-big_laptop-wrap {
    flex-wrap: wrap !important;
  }
  .flex-big_laptop-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-big_laptop-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-big_laptop-start {
    justify-content: flex-start !important;
  }
  .justify-content-big_laptop-end {
    justify-content: flex-end !important;
  }
  .justify-content-big_laptop-center {
    justify-content: center !important;
  }
  .justify-content-big_laptop-between {
    justify-content: space-between !important;
  }
  .justify-content-big_laptop-around {
    justify-content: space-around !important;
  }
  .justify-content-big_laptop-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-big_laptop-start {
    align-items: flex-start !important;
  }
  .align-items-big_laptop-end {
    align-items: flex-end !important;
  }
  .align-items-big_laptop-center {
    align-items: center !important;
  }
  .align-items-big_laptop-baseline {
    align-items: baseline !important;
  }
  .align-items-big_laptop-stretch {
    align-items: stretch !important;
  }
  .align-content-big_laptop-start {
    align-content: flex-start !important;
  }
  .align-content-big_laptop-end {
    align-content: flex-end !important;
  }
  .align-content-big_laptop-center {
    align-content: center !important;
  }
  .align-content-big_laptop-between {
    align-content: space-between !important;
  }
  .align-content-big_laptop-around {
    align-content: space-around !important;
  }
  .align-content-big_laptop-stretch {
    align-content: stretch !important;
  }
  .align-self-big_laptop-auto {
    align-self: auto !important;
  }
  .align-self-big_laptop-start {
    align-self: flex-start !important;
  }
  .align-self-big_laptop-end {
    align-self: flex-end !important;
  }
  .align-self-big_laptop-center {
    align-self: center !important;
  }
  .align-self-big_laptop-baseline {
    align-self: baseline !important;
  }
  .align-self-big_laptop-stretch {
    align-self: stretch !important;
  }
  .order-big_laptop-first {
    order: -1 !important;
  }
  .order-big_laptop-0 {
    order: 0 !important;
  }
  .order-big_laptop-1 {
    order: 1 !important;
  }
  .order-big_laptop-2 {
    order: 2 !important;
  }
  .order-big_laptop-3 {
    order: 3 !important;
  }
  .order-big_laptop-4 {
    order: 4 !important;
  }
  .order-big_laptop-5 {
    order: 5 !important;
  }
  .order-big_laptop-last {
    order: 6 !important;
  }
  .m-big_laptop-0 {
    margin: 0 !important;
  }
  .m-big_laptop-1 {
    margin: 0.25rem !important;
  }
  .m-big_laptop-2 {
    margin: 0.4rem !important;
  }
  .m-big_laptop-3 {
    margin: 0.8rem !important;
  }
  .m-big_laptop-35 {
    margin: 1.25rem !important;
  }
  .m-big_laptop-4 {
    margin: 1.875rem !important;
  }
  .m-big_laptop-45 {
    margin: 2.25rem !important;
  }
  .m-big_laptop-5 {
    margin: 3rem !important;
  }
  .m-big_laptop-55 {
    margin: 3.5rem !important;
  }
  .m-big_laptop-6 {
    margin: 3.85rem !important;
  }
  .m-big_laptop-65 {
    margin: 5rem !important;
  }
  .m-big_laptop-68 {
    margin: 5.5rem !important;
  }
  .m-big_laptop-7 {
    margin: 6.5rem !important;
  }
  .m-big_laptop-8 {
    margin: 8rem !important;
  }
  .m-big_laptop-9 {
    margin: 10rem !important;
  }
  .m-big_laptop-95 {
    margin: 12rem !important;
  }
  .m-big_laptop-10 {
    margin: 14rem !important;
  }
  .m-big_laptop-11 {
    margin: 21rem !important;
  }
  .m-big_laptop-12 {
    margin: 16rem !important;
  }
  .m-big_laptop-13 {
    margin: 17rem !important;
  }
  .m-big_laptop-14 {
    margin: 18rem !important;
  }
  .m-big_laptop-15 {
    margin: 19rem !important;
  }
  .m-big_laptop-16 {
    margin: 20rem !important;
  }
  .m-big_laptop-auto {
    margin: auto !important;
  }
  .mx-big_laptop-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-big_laptop-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-big_laptop-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-big_laptop-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-big_laptop-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-big_laptop-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-big_laptop-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-big_laptop-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-big_laptop-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-big_laptop-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-big_laptop-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-big_laptop-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-big_laptop-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-big_laptop-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-big_laptop-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-big_laptop-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-big_laptop-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-big_laptop-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-big_laptop-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-big_laptop-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-big_laptop-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-big_laptop-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-big_laptop-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-big_laptop-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-big_laptop-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-big_laptop-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-big_laptop-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-big_laptop-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-big_laptop-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-big_laptop-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-big_laptop-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-big_laptop-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-big_laptop-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-big_laptop-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-big_laptop-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-big_laptop-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-big_laptop-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-big_laptop-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-big_laptop-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-big_laptop-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-big_laptop-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-big_laptop-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-big_laptop-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-big_laptop-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-big_laptop-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-big_laptop-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-big_laptop-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-big_laptop-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-big_laptop-0 {
    margin-top: 0 !important;
  }
  .mt-big_laptop-1 {
    margin-top: 0.25rem !important;
  }
  .mt-big_laptop-2 {
    margin-top: 0.4rem !important;
  }
  .mt-big_laptop-3 {
    margin-top: 0.8rem !important;
  }
  .mt-big_laptop-35 {
    margin-top: 1.25rem !important;
  }
  .mt-big_laptop-4 {
    margin-top: 1.875rem !important;
  }
  .mt-big_laptop-45 {
    margin-top: 2.25rem !important;
  }
  .mt-big_laptop-5 {
    margin-top: 3rem !important;
  }
  .mt-big_laptop-55 {
    margin-top: 3.5rem !important;
  }
  .mt-big_laptop-6 {
    margin-top: 3.85rem !important;
  }
  .mt-big_laptop-65 {
    margin-top: 5rem !important;
  }
  .mt-big_laptop-68 {
    margin-top: 5.5rem !important;
  }
  .mt-big_laptop-7 {
    margin-top: 6.5rem !important;
  }
  .mt-big_laptop-8 {
    margin-top: 8rem !important;
  }
  .mt-big_laptop-9 {
    margin-top: 10rem !important;
  }
  .mt-big_laptop-95 {
    margin-top: 12rem !important;
  }
  .mt-big_laptop-10 {
    margin-top: 14rem !important;
  }
  .mt-big_laptop-11 {
    margin-top: 21rem !important;
  }
  .mt-big_laptop-12 {
    margin-top: 16rem !important;
  }
  .mt-big_laptop-13 {
    margin-top: 17rem !important;
  }
  .mt-big_laptop-14 {
    margin-top: 18rem !important;
  }
  .mt-big_laptop-15 {
    margin-top: 19rem !important;
  }
  .mt-big_laptop-16 {
    margin-top: 20rem !important;
  }
  .mt-big_laptop-auto {
    margin-top: auto !important;
  }
  .me-big_laptop-0 {
    margin-right: 0 !important;
  }
  .me-big_laptop-1 {
    margin-right: 0.25rem !important;
  }
  .me-big_laptop-2 {
    margin-right: 0.4rem !important;
  }
  .me-big_laptop-3 {
    margin-right: 0.8rem !important;
  }
  .me-big_laptop-35 {
    margin-right: 1.25rem !important;
  }
  .me-big_laptop-4 {
    margin-right: 1.875rem !important;
  }
  .me-big_laptop-45 {
    margin-right: 2.25rem !important;
  }
  .me-big_laptop-5 {
    margin-right: 3rem !important;
  }
  .me-big_laptop-55 {
    margin-right: 3.5rem !important;
  }
  .me-big_laptop-6 {
    margin-right: 3.85rem !important;
  }
  .me-big_laptop-65 {
    margin-right: 5rem !important;
  }
  .me-big_laptop-68 {
    margin-right: 5.5rem !important;
  }
  .me-big_laptop-7 {
    margin-right: 6.5rem !important;
  }
  .me-big_laptop-8 {
    margin-right: 8rem !important;
  }
  .me-big_laptop-9 {
    margin-right: 10rem !important;
  }
  .me-big_laptop-95 {
    margin-right: 12rem !important;
  }
  .me-big_laptop-10 {
    margin-right: 14rem !important;
  }
  .me-big_laptop-11 {
    margin-right: 21rem !important;
  }
  .me-big_laptop-12 {
    margin-right: 16rem !important;
  }
  .me-big_laptop-13 {
    margin-right: 17rem !important;
  }
  .me-big_laptop-14 {
    margin-right: 18rem !important;
  }
  .me-big_laptop-15 {
    margin-right: 19rem !important;
  }
  .me-big_laptop-16 {
    margin-right: 20rem !important;
  }
  .me-big_laptop-auto {
    margin-right: auto !important;
  }
  .mb-big_laptop-0 {
    margin-bottom: 0 !important;
  }
  .mb-big_laptop-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-big_laptop-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-big_laptop-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-big_laptop-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-big_laptop-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-big_laptop-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-big_laptop-5 {
    margin-bottom: 3rem !important;
  }
  .mb-big_laptop-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-big_laptop-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-big_laptop-65 {
    margin-bottom: 5rem !important;
  }
  .mb-big_laptop-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-big_laptop-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-big_laptop-8 {
    margin-bottom: 8rem !important;
  }
  .mb-big_laptop-9 {
    margin-bottom: 10rem !important;
  }
  .mb-big_laptop-95 {
    margin-bottom: 12rem !important;
  }
  .mb-big_laptop-10 {
    margin-bottom: 14rem !important;
  }
  .mb-big_laptop-11 {
    margin-bottom: 21rem !important;
  }
  .mb-big_laptop-12 {
    margin-bottom: 16rem !important;
  }
  .mb-big_laptop-13 {
    margin-bottom: 17rem !important;
  }
  .mb-big_laptop-14 {
    margin-bottom: 18rem !important;
  }
  .mb-big_laptop-15 {
    margin-bottom: 19rem !important;
  }
  .mb-big_laptop-16 {
    margin-bottom: 20rem !important;
  }
  .mb-big_laptop-auto {
    margin-bottom: auto !important;
  }
  .ms-big_laptop-0 {
    margin-left: 0 !important;
  }
  .ms-big_laptop-1 {
    margin-left: 0.25rem !important;
  }
  .ms-big_laptop-2 {
    margin-left: 0.4rem !important;
  }
  .ms-big_laptop-3 {
    margin-left: 0.8rem !important;
  }
  .ms-big_laptop-35 {
    margin-left: 1.25rem !important;
  }
  .ms-big_laptop-4 {
    margin-left: 1.875rem !important;
  }
  .ms-big_laptop-45 {
    margin-left: 2.25rem !important;
  }
  .ms-big_laptop-5 {
    margin-left: 3rem !important;
  }
  .ms-big_laptop-55 {
    margin-left: 3.5rem !important;
  }
  .ms-big_laptop-6 {
    margin-left: 3.85rem !important;
  }
  .ms-big_laptop-65 {
    margin-left: 5rem !important;
  }
  .ms-big_laptop-68 {
    margin-left: 5.5rem !important;
  }
  .ms-big_laptop-7 {
    margin-left: 6.5rem !important;
  }
  .ms-big_laptop-8 {
    margin-left: 8rem !important;
  }
  .ms-big_laptop-9 {
    margin-left: 10rem !important;
  }
  .ms-big_laptop-95 {
    margin-left: 12rem !important;
  }
  .ms-big_laptop-10 {
    margin-left: 14rem !important;
  }
  .ms-big_laptop-11 {
    margin-left: 21rem !important;
  }
  .ms-big_laptop-12 {
    margin-left: 16rem !important;
  }
  .ms-big_laptop-13 {
    margin-left: 17rem !important;
  }
  .ms-big_laptop-14 {
    margin-left: 18rem !important;
  }
  .ms-big_laptop-15 {
    margin-left: 19rem !important;
  }
  .ms-big_laptop-16 {
    margin-left: 20rem !important;
  }
  .ms-big_laptop-auto {
    margin-left: auto !important;
  }
  .p-big_laptop-0 {
    padding: 0 !important;
  }
  .p-big_laptop-1 {
    padding: 0.25rem !important;
  }
  .p-big_laptop-2 {
    padding: 0.4rem !important;
  }
  .p-big_laptop-3 {
    padding: 0.8rem !important;
  }
  .p-big_laptop-35 {
    padding: 1.25rem !important;
  }
  .p-big_laptop-4 {
    padding: 1.875rem !important;
  }
  .p-big_laptop-45 {
    padding: 2.25rem !important;
  }
  .p-big_laptop-5 {
    padding: 3rem !important;
  }
  .p-big_laptop-55 {
    padding: 3.5rem !important;
  }
  .p-big_laptop-6 {
    padding: 3.85rem !important;
  }
  .p-big_laptop-65 {
    padding: 5rem !important;
  }
  .p-big_laptop-68 {
    padding: 5.5rem !important;
  }
  .p-big_laptop-7 {
    padding: 6.5rem !important;
  }
  .p-big_laptop-8 {
    padding: 8rem !important;
  }
  .p-big_laptop-9 {
    padding: 10rem !important;
  }
  .p-big_laptop-95 {
    padding: 12rem !important;
  }
  .p-big_laptop-10 {
    padding: 14rem !important;
  }
  .p-big_laptop-11 {
    padding: 21rem !important;
  }
  .p-big_laptop-12 {
    padding: 16rem !important;
  }
  .p-big_laptop-13 {
    padding: 17rem !important;
  }
  .p-big_laptop-14 {
    padding: 18rem !important;
  }
  .p-big_laptop-15 {
    padding: 19rem !important;
  }
  .p-big_laptop-16 {
    padding: 20rem !important;
  }
  .px-big_laptop-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-big_laptop-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-big_laptop-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-big_laptop-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-big_laptop-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-big_laptop-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-big_laptop-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-big_laptop-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-big_laptop-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-big_laptop-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-big_laptop-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-big_laptop-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-big_laptop-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-big_laptop-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-big_laptop-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-big_laptop-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-big_laptop-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-big_laptop-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-big_laptop-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-big_laptop-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-big_laptop-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-big_laptop-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-big_laptop-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .py-big_laptop-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-big_laptop-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-big_laptop-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-big_laptop-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-big_laptop-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-big_laptop-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-big_laptop-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-big_laptop-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-big_laptop-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-big_laptop-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-big_laptop-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-big_laptop-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-big_laptop-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-big_laptop-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-big_laptop-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-big_laptop-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-big_laptop-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-big_laptop-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-big_laptop-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-big_laptop-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-big_laptop-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-big_laptop-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-big_laptop-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .pt-big_laptop-0 {
    padding-top: 0 !important;
  }
  .pt-big_laptop-1 {
    padding-top: 0.25rem !important;
  }
  .pt-big_laptop-2 {
    padding-top: 0.4rem !important;
  }
  .pt-big_laptop-3 {
    padding-top: 0.8rem !important;
  }
  .pt-big_laptop-35 {
    padding-top: 1.25rem !important;
  }
  .pt-big_laptop-4 {
    padding-top: 1.875rem !important;
  }
  .pt-big_laptop-45 {
    padding-top: 2.25rem !important;
  }
  .pt-big_laptop-5 {
    padding-top: 3rem !important;
  }
  .pt-big_laptop-55 {
    padding-top: 3.5rem !important;
  }
  .pt-big_laptop-6 {
    padding-top: 3.85rem !important;
  }
  .pt-big_laptop-65 {
    padding-top: 5rem !important;
  }
  .pt-big_laptop-68 {
    padding-top: 5.5rem !important;
  }
  .pt-big_laptop-7 {
    padding-top: 6.5rem !important;
  }
  .pt-big_laptop-8 {
    padding-top: 8rem !important;
  }
  .pt-big_laptop-9 {
    padding-top: 10rem !important;
  }
  .pt-big_laptop-95 {
    padding-top: 12rem !important;
  }
  .pt-big_laptop-10 {
    padding-top: 14rem !important;
  }
  .pt-big_laptop-11 {
    padding-top: 21rem !important;
  }
  .pt-big_laptop-12 {
    padding-top: 16rem !important;
  }
  .pt-big_laptop-13 {
    padding-top: 17rem !important;
  }
  .pt-big_laptop-14 {
    padding-top: 18rem !important;
  }
  .pt-big_laptop-15 {
    padding-top: 19rem !important;
  }
  .pt-big_laptop-16 {
    padding-top: 20rem !important;
  }
  .pe-big_laptop-0 {
    padding-right: 0 !important;
  }
  .pe-big_laptop-1 {
    padding-right: 0.25rem !important;
  }
  .pe-big_laptop-2 {
    padding-right: 0.4rem !important;
  }
  .pe-big_laptop-3 {
    padding-right: 0.8rem !important;
  }
  .pe-big_laptop-35 {
    padding-right: 1.25rem !important;
  }
  .pe-big_laptop-4 {
    padding-right: 1.875rem !important;
  }
  .pe-big_laptop-45 {
    padding-right: 2.25rem !important;
  }
  .pe-big_laptop-5 {
    padding-right: 3rem !important;
  }
  .pe-big_laptop-55 {
    padding-right: 3.5rem !important;
  }
  .pe-big_laptop-6 {
    padding-right: 3.85rem !important;
  }
  .pe-big_laptop-65 {
    padding-right: 5rem !important;
  }
  .pe-big_laptop-68 {
    padding-right: 5.5rem !important;
  }
  .pe-big_laptop-7 {
    padding-right: 6.5rem !important;
  }
  .pe-big_laptop-8 {
    padding-right: 8rem !important;
  }
  .pe-big_laptop-9 {
    padding-right: 10rem !important;
  }
  .pe-big_laptop-95 {
    padding-right: 12rem !important;
  }
  .pe-big_laptop-10 {
    padding-right: 14rem !important;
  }
  .pe-big_laptop-11 {
    padding-right: 21rem !important;
  }
  .pe-big_laptop-12 {
    padding-right: 16rem !important;
  }
  .pe-big_laptop-13 {
    padding-right: 17rem !important;
  }
  .pe-big_laptop-14 {
    padding-right: 18rem !important;
  }
  .pe-big_laptop-15 {
    padding-right: 19rem !important;
  }
  .pe-big_laptop-16 {
    padding-right: 20rem !important;
  }
  .pb-big_laptop-0 {
    padding-bottom: 0 !important;
  }
  .pb-big_laptop-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-big_laptop-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-big_laptop-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-big_laptop-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-big_laptop-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-big_laptop-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-big_laptop-5 {
    padding-bottom: 3rem !important;
  }
  .pb-big_laptop-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-big_laptop-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-big_laptop-65 {
    padding-bottom: 5rem !important;
  }
  .pb-big_laptop-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-big_laptop-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-big_laptop-8 {
    padding-bottom: 8rem !important;
  }
  .pb-big_laptop-9 {
    padding-bottom: 10rem !important;
  }
  .pb-big_laptop-95 {
    padding-bottom: 12rem !important;
  }
  .pb-big_laptop-10 {
    padding-bottom: 14rem !important;
  }
  .pb-big_laptop-11 {
    padding-bottom: 21rem !important;
  }
  .pb-big_laptop-12 {
    padding-bottom: 16rem !important;
  }
  .pb-big_laptop-13 {
    padding-bottom: 17rem !important;
  }
  .pb-big_laptop-14 {
    padding-bottom: 18rem !important;
  }
  .pb-big_laptop-15 {
    padding-bottom: 19rem !important;
  }
  .pb-big_laptop-16 {
    padding-bottom: 20rem !important;
  }
  .ps-big_laptop-0 {
    padding-left: 0 !important;
  }
  .ps-big_laptop-1 {
    padding-left: 0.25rem !important;
  }
  .ps-big_laptop-2 {
    padding-left: 0.4rem !important;
  }
  .ps-big_laptop-3 {
    padding-left: 0.8rem !important;
  }
  .ps-big_laptop-35 {
    padding-left: 1.25rem !important;
  }
  .ps-big_laptop-4 {
    padding-left: 1.875rem !important;
  }
  .ps-big_laptop-45 {
    padding-left: 2.25rem !important;
  }
  .ps-big_laptop-5 {
    padding-left: 3rem !important;
  }
  .ps-big_laptop-55 {
    padding-left: 3.5rem !important;
  }
  .ps-big_laptop-6 {
    padding-left: 3.85rem !important;
  }
  .ps-big_laptop-65 {
    padding-left: 5rem !important;
  }
  .ps-big_laptop-68 {
    padding-left: 5.5rem !important;
  }
  .ps-big_laptop-7 {
    padding-left: 6.5rem !important;
  }
  .ps-big_laptop-8 {
    padding-left: 8rem !important;
  }
  .ps-big_laptop-9 {
    padding-left: 10rem !important;
  }
  .ps-big_laptop-95 {
    padding-left: 12rem !important;
  }
  .ps-big_laptop-10 {
    padding-left: 14rem !important;
  }
  .ps-big_laptop-11 {
    padding-left: 21rem !important;
  }
  .ps-big_laptop-12 {
    padding-left: 16rem !important;
  }
  .ps-big_laptop-13 {
    padding-left: 17rem !important;
  }
  .ps-big_laptop-14 {
    padding-left: 18rem !important;
  }
  .ps-big_laptop-15 {
    padding-left: 19rem !important;
  }
  .ps-big_laptop-16 {
    padding-left: 20rem !important;
  }
  .gap-big_laptop-0 {
    gap: 0 !important;
  }
  .gap-big_laptop-1 {
    gap: 0.25rem !important;
  }
  .gap-big_laptop-2 {
    gap: 0.4rem !important;
  }
  .gap-big_laptop-3 {
    gap: 0.8rem !important;
  }
  .gap-big_laptop-35 {
    gap: 1.25rem !important;
  }
  .gap-big_laptop-4 {
    gap: 1.875rem !important;
  }
  .gap-big_laptop-45 {
    gap: 2.25rem !important;
  }
  .gap-big_laptop-5 {
    gap: 3rem !important;
  }
  .gap-big_laptop-55 {
    gap: 3.5rem !important;
  }
  .gap-big_laptop-6 {
    gap: 3.85rem !important;
  }
  .gap-big_laptop-65 {
    gap: 5rem !important;
  }
  .gap-big_laptop-68 {
    gap: 5.5rem !important;
  }
  .gap-big_laptop-7 {
    gap: 6.5rem !important;
  }
  .gap-big_laptop-8 {
    gap: 8rem !important;
  }
  .gap-big_laptop-9 {
    gap: 10rem !important;
  }
  .gap-big_laptop-95 {
    gap: 12rem !important;
  }
  .gap-big_laptop-10 {
    gap: 14rem !important;
  }
  .gap-big_laptop-11 {
    gap: 21rem !important;
  }
  .gap-big_laptop-12 {
    gap: 16rem !important;
  }
  .gap-big_laptop-13 {
    gap: 17rem !important;
  }
  .gap-big_laptop-14 {
    gap: 18rem !important;
  }
  .gap-big_laptop-15 {
    gap: 19rem !important;
  }
  .gap-big_laptop-16 {
    gap: 20rem !important;
  }
  .text-big_laptop-start {
    text-align: left !important;
  }
  .text-big_laptop-end {
    text-align: right !important;
  }
  .text-big_laptop-center {
    text-align: center !important;
  }
}
@media (min-width: 1700px) {
  .float-laptop_xxl-start {
    float: left !important;
  }
  .float-laptop_xxl-end {
    float: right !important;
  }
  .float-laptop_xxl-none {
    float: none !important;
  }
  .d-laptop_xxl-inline {
    display: inline !important;
  }
  .d-laptop_xxl-inline-block {
    display: inline-block !important;
  }
  .d-laptop_xxl-block {
    display: block !important;
  }
  .d-laptop_xxl-grid {
    display: grid !important;
  }
  .d-laptop_xxl-table {
    display: table !important;
  }
  .d-laptop_xxl-table-row {
    display: table-row !important;
  }
  .d-laptop_xxl-table-cell {
    display: table-cell !important;
  }
  .d-laptop_xxl-flex {
    display: flex !important;
  }
  .d-laptop_xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-laptop_xxl-none {
    display: none !important;
  }
  .flex-laptop_xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-laptop_xxl-row {
    flex-direction: row !important;
  }
  .flex-laptop_xxl-column {
    flex-direction: column !important;
  }
  .flex-laptop_xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-laptop_xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-laptop_xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-laptop_xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-laptop_xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-laptop_xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-laptop_xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-laptop_xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-laptop_xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-laptop_xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-laptop_xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-laptop_xxl-center {
    justify-content: center !important;
  }
  .justify-content-laptop_xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-laptop_xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-laptop_xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-laptop_xxl-start {
    align-items: flex-start !important;
  }
  .align-items-laptop_xxl-end {
    align-items: flex-end !important;
  }
  .align-items-laptop_xxl-center {
    align-items: center !important;
  }
  .align-items-laptop_xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-laptop_xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-laptop_xxl-start {
    align-content: flex-start !important;
  }
  .align-content-laptop_xxl-end {
    align-content: flex-end !important;
  }
  .align-content-laptop_xxl-center {
    align-content: center !important;
  }
  .align-content-laptop_xxl-between {
    align-content: space-between !important;
  }
  .align-content-laptop_xxl-around {
    align-content: space-around !important;
  }
  .align-content-laptop_xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-laptop_xxl-auto {
    align-self: auto !important;
  }
  .align-self-laptop_xxl-start {
    align-self: flex-start !important;
  }
  .align-self-laptop_xxl-end {
    align-self: flex-end !important;
  }
  .align-self-laptop_xxl-center {
    align-self: center !important;
  }
  .align-self-laptop_xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-laptop_xxl-stretch {
    align-self: stretch !important;
  }
  .order-laptop_xxl-first {
    order: -1 !important;
  }
  .order-laptop_xxl-0 {
    order: 0 !important;
  }
  .order-laptop_xxl-1 {
    order: 1 !important;
  }
  .order-laptop_xxl-2 {
    order: 2 !important;
  }
  .order-laptop_xxl-3 {
    order: 3 !important;
  }
  .order-laptop_xxl-4 {
    order: 4 !important;
  }
  .order-laptop_xxl-5 {
    order: 5 !important;
  }
  .order-laptop_xxl-last {
    order: 6 !important;
  }
  .m-laptop_xxl-0 {
    margin: 0 !important;
  }
  .m-laptop_xxl-1 {
    margin: 0.25rem !important;
  }
  .m-laptop_xxl-2 {
    margin: 0.4rem !important;
  }
  .m-laptop_xxl-3 {
    margin: 0.8rem !important;
  }
  .m-laptop_xxl-35 {
    margin: 1.25rem !important;
  }
  .m-laptop_xxl-4 {
    margin: 1.875rem !important;
  }
  .m-laptop_xxl-45 {
    margin: 2.25rem !important;
  }
  .m-laptop_xxl-5 {
    margin: 3rem !important;
  }
  .m-laptop_xxl-55 {
    margin: 3.5rem !important;
  }
  .m-laptop_xxl-6 {
    margin: 3.85rem !important;
  }
  .m-laptop_xxl-65 {
    margin: 5rem !important;
  }
  .m-laptop_xxl-68 {
    margin: 5.5rem !important;
  }
  .m-laptop_xxl-7 {
    margin: 6.5rem !important;
  }
  .m-laptop_xxl-8 {
    margin: 8rem !important;
  }
  .m-laptop_xxl-9 {
    margin: 10rem !important;
  }
  .m-laptop_xxl-95 {
    margin: 12rem !important;
  }
  .m-laptop_xxl-10 {
    margin: 14rem !important;
  }
  .m-laptop_xxl-11 {
    margin: 21rem !important;
  }
  .m-laptop_xxl-12 {
    margin: 16rem !important;
  }
  .m-laptop_xxl-13 {
    margin: 17rem !important;
  }
  .m-laptop_xxl-14 {
    margin: 18rem !important;
  }
  .m-laptop_xxl-15 {
    margin: 19rem !important;
  }
  .m-laptop_xxl-16 {
    margin: 20rem !important;
  }
  .m-laptop_xxl-auto {
    margin: auto !important;
  }
  .mx-laptop_xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-laptop_xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-laptop_xxl-2 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }
  .mx-laptop_xxl-3 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
  .mx-laptop_xxl-35 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-laptop_xxl-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-laptop_xxl-45 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-laptop_xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-laptop_xxl-55 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-laptop_xxl-6 {
    margin-right: 3.85rem !important;
    margin-left: 3.85rem !important;
  }
  .mx-laptop_xxl-65 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-laptop_xxl-68 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-laptop_xxl-7 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-laptop_xxl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-laptop_xxl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-laptop_xxl-95 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-laptop_xxl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-laptop_xxl-11 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-laptop_xxl-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-laptop_xxl-13 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-laptop_xxl-14 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-laptop_xxl-15 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-laptop_xxl-16 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-laptop_xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-laptop_xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-laptop_xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-laptop_xxl-2 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .my-laptop_xxl-3 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
  }
  .my-laptop_xxl-35 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-laptop_xxl-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-laptop_xxl-45 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-laptop_xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-laptop_xxl-55 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-laptop_xxl-6 {
    margin-top: 3.85rem !important;
    margin-bottom: 3.85rem !important;
  }
  .my-laptop_xxl-65 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-laptop_xxl-68 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-laptop_xxl-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-laptop_xxl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-laptop_xxl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-laptop_xxl-95 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-laptop_xxl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-laptop_xxl-11 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-laptop_xxl-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-laptop_xxl-13 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-laptop_xxl-14 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-laptop_xxl-15 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-laptop_xxl-16 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-laptop_xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-laptop_xxl-0 {
    margin-top: 0 !important;
  }
  .mt-laptop_xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-laptop_xxl-2 {
    margin-top: 0.4rem !important;
  }
  .mt-laptop_xxl-3 {
    margin-top: 0.8rem !important;
  }
  .mt-laptop_xxl-35 {
    margin-top: 1.25rem !important;
  }
  .mt-laptop_xxl-4 {
    margin-top: 1.875rem !important;
  }
  .mt-laptop_xxl-45 {
    margin-top: 2.25rem !important;
  }
  .mt-laptop_xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-laptop_xxl-55 {
    margin-top: 3.5rem !important;
  }
  .mt-laptop_xxl-6 {
    margin-top: 3.85rem !important;
  }
  .mt-laptop_xxl-65 {
    margin-top: 5rem !important;
  }
  .mt-laptop_xxl-68 {
    margin-top: 5.5rem !important;
  }
  .mt-laptop_xxl-7 {
    margin-top: 6.5rem !important;
  }
  .mt-laptop_xxl-8 {
    margin-top: 8rem !important;
  }
  .mt-laptop_xxl-9 {
    margin-top: 10rem !important;
  }
  .mt-laptop_xxl-95 {
    margin-top: 12rem !important;
  }
  .mt-laptop_xxl-10 {
    margin-top: 14rem !important;
  }
  .mt-laptop_xxl-11 {
    margin-top: 21rem !important;
  }
  .mt-laptop_xxl-12 {
    margin-top: 16rem !important;
  }
  .mt-laptop_xxl-13 {
    margin-top: 17rem !important;
  }
  .mt-laptop_xxl-14 {
    margin-top: 18rem !important;
  }
  .mt-laptop_xxl-15 {
    margin-top: 19rem !important;
  }
  .mt-laptop_xxl-16 {
    margin-top: 20rem !important;
  }
  .mt-laptop_xxl-auto {
    margin-top: auto !important;
  }
  .me-laptop_xxl-0 {
    margin-right: 0 !important;
  }
  .me-laptop_xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-laptop_xxl-2 {
    margin-right: 0.4rem !important;
  }
  .me-laptop_xxl-3 {
    margin-right: 0.8rem !important;
  }
  .me-laptop_xxl-35 {
    margin-right: 1.25rem !important;
  }
  .me-laptop_xxl-4 {
    margin-right: 1.875rem !important;
  }
  .me-laptop_xxl-45 {
    margin-right: 2.25rem !important;
  }
  .me-laptop_xxl-5 {
    margin-right: 3rem !important;
  }
  .me-laptop_xxl-55 {
    margin-right: 3.5rem !important;
  }
  .me-laptop_xxl-6 {
    margin-right: 3.85rem !important;
  }
  .me-laptop_xxl-65 {
    margin-right: 5rem !important;
  }
  .me-laptop_xxl-68 {
    margin-right: 5.5rem !important;
  }
  .me-laptop_xxl-7 {
    margin-right: 6.5rem !important;
  }
  .me-laptop_xxl-8 {
    margin-right: 8rem !important;
  }
  .me-laptop_xxl-9 {
    margin-right: 10rem !important;
  }
  .me-laptop_xxl-95 {
    margin-right: 12rem !important;
  }
  .me-laptop_xxl-10 {
    margin-right: 14rem !important;
  }
  .me-laptop_xxl-11 {
    margin-right: 21rem !important;
  }
  .me-laptop_xxl-12 {
    margin-right: 16rem !important;
  }
  .me-laptop_xxl-13 {
    margin-right: 17rem !important;
  }
  .me-laptop_xxl-14 {
    margin-right: 18rem !important;
  }
  .me-laptop_xxl-15 {
    margin-right: 19rem !important;
  }
  .me-laptop_xxl-16 {
    margin-right: 20rem !important;
  }
  .me-laptop_xxl-auto {
    margin-right: auto !important;
  }
  .mb-laptop_xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-laptop_xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-laptop_xxl-2 {
    margin-bottom: 0.4rem !important;
  }
  .mb-laptop_xxl-3 {
    margin-bottom: 0.8rem !important;
  }
  .mb-laptop_xxl-35 {
    margin-bottom: 1.25rem !important;
  }
  .mb-laptop_xxl-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-laptop_xxl-45 {
    margin-bottom: 2.25rem !important;
  }
  .mb-laptop_xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-laptop_xxl-55 {
    margin-bottom: 3.5rem !important;
  }
  .mb-laptop_xxl-6 {
    margin-bottom: 3.85rem !important;
  }
  .mb-laptop_xxl-65 {
    margin-bottom: 5rem !important;
  }
  .mb-laptop_xxl-68 {
    margin-bottom: 5.5rem !important;
  }
  .mb-laptop_xxl-7 {
    margin-bottom: 6.5rem !important;
  }
  .mb-laptop_xxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-laptop_xxl-9 {
    margin-bottom: 10rem !important;
  }
  .mb-laptop_xxl-95 {
    margin-bottom: 12rem !important;
  }
  .mb-laptop_xxl-10 {
    margin-bottom: 14rem !important;
  }
  .mb-laptop_xxl-11 {
    margin-bottom: 21rem !important;
  }
  .mb-laptop_xxl-12 {
    margin-bottom: 16rem !important;
  }
  .mb-laptop_xxl-13 {
    margin-bottom: 17rem !important;
  }
  .mb-laptop_xxl-14 {
    margin-bottom: 18rem !important;
  }
  .mb-laptop_xxl-15 {
    margin-bottom: 19rem !important;
  }
  .mb-laptop_xxl-16 {
    margin-bottom: 20rem !important;
  }
  .mb-laptop_xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-laptop_xxl-0 {
    margin-left: 0 !important;
  }
  .ms-laptop_xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-laptop_xxl-2 {
    margin-left: 0.4rem !important;
  }
  .ms-laptop_xxl-3 {
    margin-left: 0.8rem !important;
  }
  .ms-laptop_xxl-35 {
    margin-left: 1.25rem !important;
  }
  .ms-laptop_xxl-4 {
    margin-left: 1.875rem !important;
  }
  .ms-laptop_xxl-45 {
    margin-left: 2.25rem !important;
  }
  .ms-laptop_xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-laptop_xxl-55 {
    margin-left: 3.5rem !important;
  }
  .ms-laptop_xxl-6 {
    margin-left: 3.85rem !important;
  }
  .ms-laptop_xxl-65 {
    margin-left: 5rem !important;
  }
  .ms-laptop_xxl-68 {
    margin-left: 5.5rem !important;
  }
  .ms-laptop_xxl-7 {
    margin-left: 6.5rem !important;
  }
  .ms-laptop_xxl-8 {
    margin-left: 8rem !important;
  }
  .ms-laptop_xxl-9 {
    margin-left: 10rem !important;
  }
  .ms-laptop_xxl-95 {
    margin-left: 12rem !important;
  }
  .ms-laptop_xxl-10 {
    margin-left: 14rem !important;
  }
  .ms-laptop_xxl-11 {
    margin-left: 21rem !important;
  }
  .ms-laptop_xxl-12 {
    margin-left: 16rem !important;
  }
  .ms-laptop_xxl-13 {
    margin-left: 17rem !important;
  }
  .ms-laptop_xxl-14 {
    margin-left: 18rem !important;
  }
  .ms-laptop_xxl-15 {
    margin-left: 19rem !important;
  }
  .ms-laptop_xxl-16 {
    margin-left: 20rem !important;
  }
  .ms-laptop_xxl-auto {
    margin-left: auto !important;
  }
  .p-laptop_xxl-0 {
    padding: 0 !important;
  }
  .p-laptop_xxl-1 {
    padding: 0.25rem !important;
  }
  .p-laptop_xxl-2 {
    padding: 0.4rem !important;
  }
  .p-laptop_xxl-3 {
    padding: 0.8rem !important;
  }
  .p-laptop_xxl-35 {
    padding: 1.25rem !important;
  }
  .p-laptop_xxl-4 {
    padding: 1.875rem !important;
  }
  .p-laptop_xxl-45 {
    padding: 2.25rem !important;
  }
  .p-laptop_xxl-5 {
    padding: 3rem !important;
  }
  .p-laptop_xxl-55 {
    padding: 3.5rem !important;
  }
  .p-laptop_xxl-6 {
    padding: 3.85rem !important;
  }
  .p-laptop_xxl-65 {
    padding: 5rem !important;
  }
  .p-laptop_xxl-68 {
    padding: 5.5rem !important;
  }
  .p-laptop_xxl-7 {
    padding: 6.5rem !important;
  }
  .p-laptop_xxl-8 {
    padding: 8rem !important;
  }
  .p-laptop_xxl-9 {
    padding: 10rem !important;
  }
  .p-laptop_xxl-95 {
    padding: 12rem !important;
  }
  .p-laptop_xxl-10 {
    padding: 14rem !important;
  }
  .p-laptop_xxl-11 {
    padding: 21rem !important;
  }
  .p-laptop_xxl-12 {
    padding: 16rem !important;
  }
  .p-laptop_xxl-13 {
    padding: 17rem !important;
  }
  .p-laptop_xxl-14 {
    padding: 18rem !important;
  }
  .p-laptop_xxl-15 {
    padding: 19rem !important;
  }
  .p-laptop_xxl-16 {
    padding: 20rem !important;
  }
  .px-laptop_xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-laptop_xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-laptop_xxl-2 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
  .px-laptop_xxl-3 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
  }
  .px-laptop_xxl-35 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-laptop_xxl-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-laptop_xxl-45 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-laptop_xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-laptop_xxl-55 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-laptop_xxl-6 {
    padding-right: 3.85rem !important;
    padding-left: 3.85rem !important;
  }
  .px-laptop_xxl-65 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-laptop_xxl-68 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-laptop_xxl-7 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-laptop_xxl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-laptop_xxl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-laptop_xxl-95 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-laptop_xxl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-laptop_xxl-11 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-laptop_xxl-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-laptop_xxl-13 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-laptop_xxl-14 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-laptop_xxl-15 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-laptop_xxl-16 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .py-laptop_xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-laptop_xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-laptop_xxl-2 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }
  .py-laptop_xxl-3 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
  .py-laptop_xxl-35 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-laptop_xxl-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-laptop_xxl-45 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-laptop_xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-laptop_xxl-55 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-laptop_xxl-6 {
    padding-top: 3.85rem !important;
    padding-bottom: 3.85rem !important;
  }
  .py-laptop_xxl-65 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-laptop_xxl-68 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-laptop_xxl-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-laptop_xxl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-laptop_xxl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-laptop_xxl-95 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-laptop_xxl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-laptop_xxl-11 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-laptop_xxl-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-laptop_xxl-13 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-laptop_xxl-14 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-laptop_xxl-15 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-laptop_xxl-16 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .pt-laptop_xxl-0 {
    padding-top: 0 !important;
  }
  .pt-laptop_xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-laptop_xxl-2 {
    padding-top: 0.4rem !important;
  }
  .pt-laptop_xxl-3 {
    padding-top: 0.8rem !important;
  }
  .pt-laptop_xxl-35 {
    padding-top: 1.25rem !important;
  }
  .pt-laptop_xxl-4 {
    padding-top: 1.875rem !important;
  }
  .pt-laptop_xxl-45 {
    padding-top: 2.25rem !important;
  }
  .pt-laptop_xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-laptop_xxl-55 {
    padding-top: 3.5rem !important;
  }
  .pt-laptop_xxl-6 {
    padding-top: 3.85rem !important;
  }
  .pt-laptop_xxl-65 {
    padding-top: 5rem !important;
  }
  .pt-laptop_xxl-68 {
    padding-top: 5.5rem !important;
  }
  .pt-laptop_xxl-7 {
    padding-top: 6.5rem !important;
  }
  .pt-laptop_xxl-8 {
    padding-top: 8rem !important;
  }
  .pt-laptop_xxl-9 {
    padding-top: 10rem !important;
  }
  .pt-laptop_xxl-95 {
    padding-top: 12rem !important;
  }
  .pt-laptop_xxl-10 {
    padding-top: 14rem !important;
  }
  .pt-laptop_xxl-11 {
    padding-top: 21rem !important;
  }
  .pt-laptop_xxl-12 {
    padding-top: 16rem !important;
  }
  .pt-laptop_xxl-13 {
    padding-top: 17rem !important;
  }
  .pt-laptop_xxl-14 {
    padding-top: 18rem !important;
  }
  .pt-laptop_xxl-15 {
    padding-top: 19rem !important;
  }
  .pt-laptop_xxl-16 {
    padding-top: 20rem !important;
  }
  .pe-laptop_xxl-0 {
    padding-right: 0 !important;
  }
  .pe-laptop_xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-laptop_xxl-2 {
    padding-right: 0.4rem !important;
  }
  .pe-laptop_xxl-3 {
    padding-right: 0.8rem !important;
  }
  .pe-laptop_xxl-35 {
    padding-right: 1.25rem !important;
  }
  .pe-laptop_xxl-4 {
    padding-right: 1.875rem !important;
  }
  .pe-laptop_xxl-45 {
    padding-right: 2.25rem !important;
  }
  .pe-laptop_xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-laptop_xxl-55 {
    padding-right: 3.5rem !important;
  }
  .pe-laptop_xxl-6 {
    padding-right: 3.85rem !important;
  }
  .pe-laptop_xxl-65 {
    padding-right: 5rem !important;
  }
  .pe-laptop_xxl-68 {
    padding-right: 5.5rem !important;
  }
  .pe-laptop_xxl-7 {
    padding-right: 6.5rem !important;
  }
  .pe-laptop_xxl-8 {
    padding-right: 8rem !important;
  }
  .pe-laptop_xxl-9 {
    padding-right: 10rem !important;
  }
  .pe-laptop_xxl-95 {
    padding-right: 12rem !important;
  }
  .pe-laptop_xxl-10 {
    padding-right: 14rem !important;
  }
  .pe-laptop_xxl-11 {
    padding-right: 21rem !important;
  }
  .pe-laptop_xxl-12 {
    padding-right: 16rem !important;
  }
  .pe-laptop_xxl-13 {
    padding-right: 17rem !important;
  }
  .pe-laptop_xxl-14 {
    padding-right: 18rem !important;
  }
  .pe-laptop_xxl-15 {
    padding-right: 19rem !important;
  }
  .pe-laptop_xxl-16 {
    padding-right: 20rem !important;
  }
  .pb-laptop_xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-laptop_xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-laptop_xxl-2 {
    padding-bottom: 0.4rem !important;
  }
  .pb-laptop_xxl-3 {
    padding-bottom: 0.8rem !important;
  }
  .pb-laptop_xxl-35 {
    padding-bottom: 1.25rem !important;
  }
  .pb-laptop_xxl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-laptop_xxl-45 {
    padding-bottom: 2.25rem !important;
  }
  .pb-laptop_xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-laptop_xxl-55 {
    padding-bottom: 3.5rem !important;
  }
  .pb-laptop_xxl-6 {
    padding-bottom: 3.85rem !important;
  }
  .pb-laptop_xxl-65 {
    padding-bottom: 5rem !important;
  }
  .pb-laptop_xxl-68 {
    padding-bottom: 5.5rem !important;
  }
  .pb-laptop_xxl-7 {
    padding-bottom: 6.5rem !important;
  }
  .pb-laptop_xxl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-laptop_xxl-9 {
    padding-bottom: 10rem !important;
  }
  .pb-laptop_xxl-95 {
    padding-bottom: 12rem !important;
  }
  .pb-laptop_xxl-10 {
    padding-bottom: 14rem !important;
  }
  .pb-laptop_xxl-11 {
    padding-bottom: 21rem !important;
  }
  .pb-laptop_xxl-12 {
    padding-bottom: 16rem !important;
  }
  .pb-laptop_xxl-13 {
    padding-bottom: 17rem !important;
  }
  .pb-laptop_xxl-14 {
    padding-bottom: 18rem !important;
  }
  .pb-laptop_xxl-15 {
    padding-bottom: 19rem !important;
  }
  .pb-laptop_xxl-16 {
    padding-bottom: 20rem !important;
  }
  .ps-laptop_xxl-0 {
    padding-left: 0 !important;
  }
  .ps-laptop_xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-laptop_xxl-2 {
    padding-left: 0.4rem !important;
  }
  .ps-laptop_xxl-3 {
    padding-left: 0.8rem !important;
  }
  .ps-laptop_xxl-35 {
    padding-left: 1.25rem !important;
  }
  .ps-laptop_xxl-4 {
    padding-left: 1.875rem !important;
  }
  .ps-laptop_xxl-45 {
    padding-left: 2.25rem !important;
  }
  .ps-laptop_xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-laptop_xxl-55 {
    padding-left: 3.5rem !important;
  }
  .ps-laptop_xxl-6 {
    padding-left: 3.85rem !important;
  }
  .ps-laptop_xxl-65 {
    padding-left: 5rem !important;
  }
  .ps-laptop_xxl-68 {
    padding-left: 5.5rem !important;
  }
  .ps-laptop_xxl-7 {
    padding-left: 6.5rem !important;
  }
  .ps-laptop_xxl-8 {
    padding-left: 8rem !important;
  }
  .ps-laptop_xxl-9 {
    padding-left: 10rem !important;
  }
  .ps-laptop_xxl-95 {
    padding-left: 12rem !important;
  }
  .ps-laptop_xxl-10 {
    padding-left: 14rem !important;
  }
  .ps-laptop_xxl-11 {
    padding-left: 21rem !important;
  }
  .ps-laptop_xxl-12 {
    padding-left: 16rem !important;
  }
  .ps-laptop_xxl-13 {
    padding-left: 17rem !important;
  }
  .ps-laptop_xxl-14 {
    padding-left: 18rem !important;
  }
  .ps-laptop_xxl-15 {
    padding-left: 19rem !important;
  }
  .ps-laptop_xxl-16 {
    padding-left: 20rem !important;
  }
  .gap-laptop_xxl-0 {
    gap: 0 !important;
  }
  .gap-laptop_xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-laptop_xxl-2 {
    gap: 0.4rem !important;
  }
  .gap-laptop_xxl-3 {
    gap: 0.8rem !important;
  }
  .gap-laptop_xxl-35 {
    gap: 1.25rem !important;
  }
  .gap-laptop_xxl-4 {
    gap: 1.875rem !important;
  }
  .gap-laptop_xxl-45 {
    gap: 2.25rem !important;
  }
  .gap-laptop_xxl-5 {
    gap: 3rem !important;
  }
  .gap-laptop_xxl-55 {
    gap: 3.5rem !important;
  }
  .gap-laptop_xxl-6 {
    gap: 3.85rem !important;
  }
  .gap-laptop_xxl-65 {
    gap: 5rem !important;
  }
  .gap-laptop_xxl-68 {
    gap: 5.5rem !important;
  }
  .gap-laptop_xxl-7 {
    gap: 6.5rem !important;
  }
  .gap-laptop_xxl-8 {
    gap: 8rem !important;
  }
  .gap-laptop_xxl-9 {
    gap: 10rem !important;
  }
  .gap-laptop_xxl-95 {
    gap: 12rem !important;
  }
  .gap-laptop_xxl-10 {
    gap: 14rem !important;
  }
  .gap-laptop_xxl-11 {
    gap: 21rem !important;
  }
  .gap-laptop_xxl-12 {
    gap: 16rem !important;
  }
  .gap-laptop_xxl-13 {
    gap: 17rem !important;
  }
  .gap-laptop_xxl-14 {
    gap: 18rem !important;
  }
  .gap-laptop_xxl-15 {
    gap: 19rem !important;
  }
  .gap-laptop_xxl-16 {
    gap: 20rem !important;
  }
  .text-laptop_xxl-start {
    text-align: left !important;
  }
  .text-laptop_xxl-end {
    text-align: right !important;
  }
  .text-laptop_xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
@media (min-width: 1280px) {
  .container-xl, .container-ipad, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
}
@media (min-width: 1440px) {
  .container-xxl, .container-laptop, .container-xl, .container-ipad, .container-lg, .container-md, .container-sm, .container {
    max-width: 1600px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1025px) {
  .col-ipad {
    flex: 1 0 0%;
  }
  .row-cols-ipad-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-ipad-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-ipad-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-ipad-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-ipad-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-ipad-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-ipad-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-ipad-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-ipad-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-ipad-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-ipad-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-ipad-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-ipad-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-ipad-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-ipad-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-ipad-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-ipad-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-ipad-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-ipad-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-ipad-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-ipad-0 {
    margin-left: 0;
  }
  .offset-ipad-1 {
    margin-left: 8.33333333%;
  }
  .offset-ipad-2 {
    margin-left: 16.66666667%;
  }
  .offset-ipad-3 {
    margin-left: 25%;
  }
  .offset-ipad-4 {
    margin-left: 33.33333333%;
  }
  .offset-ipad-5 {
    margin-left: 41.66666667%;
  }
  .offset-ipad-6 {
    margin-left: 50%;
  }
  .offset-ipad-7 {
    margin-left: 58.33333333%;
  }
  .offset-ipad-8 {
    margin-left: 66.66666667%;
  }
  .offset-ipad-9 {
    margin-left: 75%;
  }
  .offset-ipad-10 {
    margin-left: 83.33333333%;
  }
  .offset-ipad-11 {
    margin-left: 91.66666667%;
  }
  .g-ipad-0,
  .gx-ipad-0 {
    --bs-gutter-x: 0;
  }
  .g-ipad-0,
  .gy-ipad-0 {
    --bs-gutter-y: 0;
  }
  .g-ipad-1,
  .gx-ipad-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-ipad-1,
  .gy-ipad-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-ipad-2,
  .gx-ipad-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-ipad-2,
  .gy-ipad-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-ipad-3,
  .gx-ipad-3 {
    --bs-gutter-x: 1rem;
  }
  .g-ipad-3,
  .gy-ipad-3 {
    --bs-gutter-y: 1rem;
  }
  .g-ipad-4,
  .gx-ipad-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-ipad-4,
  .gy-ipad-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-ipad-5,
  .gx-ipad-5 {
    --bs-gutter-x: 3rem;
  }
  .g-ipad-5,
  .gy-ipad-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1280px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1281px) {
  .col-laptop {
    flex: 1 0 0%;
  }
  .row-cols-laptop-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-laptop-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-laptop-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-laptop-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-laptop-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-laptop-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-laptop-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-laptop-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-laptop-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-laptop-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-laptop-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-laptop-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-laptop-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-laptop-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-laptop-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-laptop-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-laptop-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-laptop-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-laptop-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-laptop-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-laptop-0 {
    margin-left: 0;
  }
  .offset-laptop-1 {
    margin-left: 8.33333333%;
  }
  .offset-laptop-2 {
    margin-left: 16.66666667%;
  }
  .offset-laptop-3 {
    margin-left: 25%;
  }
  .offset-laptop-4 {
    margin-left: 33.33333333%;
  }
  .offset-laptop-5 {
    margin-left: 41.66666667%;
  }
  .offset-laptop-6 {
    margin-left: 50%;
  }
  .offset-laptop-7 {
    margin-left: 58.33333333%;
  }
  .offset-laptop-8 {
    margin-left: 66.66666667%;
  }
  .offset-laptop-9 {
    margin-left: 75%;
  }
  .offset-laptop-10 {
    margin-left: 83.33333333%;
  }
  .offset-laptop-11 {
    margin-left: 91.66666667%;
  }
  .g-laptop-0,
  .gx-laptop-0 {
    --bs-gutter-x: 0;
  }
  .g-laptop-0,
  .gy-laptop-0 {
    --bs-gutter-y: 0;
  }
  .g-laptop-1,
  .gx-laptop-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-laptop-1,
  .gy-laptop-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-laptop-2,
  .gx-laptop-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-laptop-2,
  .gy-laptop-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-laptop-3,
  .gx-laptop-3 {
    --bs-gutter-x: 1rem;
  }
  .g-laptop-3,
  .gy-laptop-3 {
    --bs-gutter-y: 1rem;
  }
  .g-laptop-4,
  .gx-laptop-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-laptop-4,
  .gy-laptop-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-laptop-5,
  .gx-laptop-5 {
    --bs-gutter-x: 3rem;
  }
  .g-laptop-5,
  .gy-laptop-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1440px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1441px) {
  .col-big_laptop {
    flex: 1 0 0%;
  }
  .row-cols-big_laptop-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-big_laptop-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-big_laptop-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-big_laptop-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-big_laptop-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-big_laptop-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-big_laptop-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-big_laptop-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-big_laptop-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-big_laptop-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-big_laptop-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-big_laptop-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-big_laptop-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-big_laptop-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-big_laptop-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-big_laptop-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-big_laptop-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-big_laptop-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-big_laptop-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-big_laptop-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-big_laptop-0 {
    margin-left: 0;
  }
  .offset-big_laptop-1 {
    margin-left: 8.33333333%;
  }
  .offset-big_laptop-2 {
    margin-left: 16.66666667%;
  }
  .offset-big_laptop-3 {
    margin-left: 25%;
  }
  .offset-big_laptop-4 {
    margin-left: 33.33333333%;
  }
  .offset-big_laptop-5 {
    margin-left: 41.66666667%;
  }
  .offset-big_laptop-6 {
    margin-left: 50%;
  }
  .offset-big_laptop-7 {
    margin-left: 58.33333333%;
  }
  .offset-big_laptop-8 {
    margin-left: 66.66666667%;
  }
  .offset-big_laptop-9 {
    margin-left: 75%;
  }
  .offset-big_laptop-10 {
    margin-left: 83.33333333%;
  }
  .offset-big_laptop-11 {
    margin-left: 91.66666667%;
  }
  .g-big_laptop-0,
  .gx-big_laptop-0 {
    --bs-gutter-x: 0;
  }
  .g-big_laptop-0,
  .gy-big_laptop-0 {
    --bs-gutter-y: 0;
  }
  .g-big_laptop-1,
  .gx-big_laptop-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-big_laptop-1,
  .gy-big_laptop-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-big_laptop-2,
  .gx-big_laptop-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-big_laptop-2,
  .gy-big_laptop-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-big_laptop-3,
  .gx-big_laptop-3 {
    --bs-gutter-x: 1rem;
  }
  .g-big_laptop-3,
  .gy-big_laptop-3 {
    --bs-gutter-y: 1rem;
  }
  .g-big_laptop-4,
  .gx-big_laptop-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-big_laptop-4,
  .gy-big_laptop-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-big_laptop-5,
  .gx-big_laptop-5 {
    --bs-gutter-x: 3rem;
  }
  .g-big_laptop-5,
  .gy-big_laptop-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1700px) {
  .col-laptop_xxl {
    flex: 1 0 0%;
  }
  .row-cols-laptop_xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-laptop_xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-laptop_xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-laptop_xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-laptop_xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-laptop_xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-laptop_xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-laptop_xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-laptop_xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-laptop_xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-laptop_xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-laptop_xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-laptop_xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-laptop_xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-laptop_xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-laptop_xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-laptop_xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-laptop_xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-laptop_xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-laptop_xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-laptop_xxl-0 {
    margin-left: 0;
  }
  .offset-laptop_xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-laptop_xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-laptop_xxl-3 {
    margin-left: 25%;
  }
  .offset-laptop_xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-laptop_xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-laptop_xxl-6 {
    margin-left: 50%;
  }
  .offset-laptop_xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-laptop_xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-laptop_xxl-9 {
    margin-left: 75%;
  }
  .offset-laptop_xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-laptop_xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-laptop_xxl-0,
  .gx-laptop_xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-laptop_xxl-0,
  .gy-laptop_xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-laptop_xxl-1,
  .gx-laptop_xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-laptop_xxl-1,
  .gy-laptop_xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-laptop_xxl-2,
  .gx-laptop_xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-laptop_xxl-2,
  .gy-laptop_xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-laptop_xxl-3,
  .gx-laptop_xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-laptop_xxl-3,
  .gy-laptop_xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-laptop_xxl-4,
  .gx-laptop_xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-laptop_xxl-4,
  .gy-laptop_xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-laptop_xxl-5,
  .gx-laptop_xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-laptop_xxl-5,
  .gy-laptop_xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.51875rem + 3.225vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 3.9375rem;
  }
}

.display-4 {
  font-size: calc(1.46875rem + 2.625vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.4375rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.display-7 {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-7 {
    font-size: 2rem;
  }
}

.display-8 {
  font-size: calc(1.2875rem + 0.45vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-8 {
    font-size: 1.625rem;
  }
}

.display-9 {
  font-size: calc(1.26rem + 0.12vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-9 {
    font-size: 1.35rem;
  }
}

.display-10 {
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-11 {
  font-size: calc(1.2875rem + 0.45vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-11 {
    font-size: 1.625rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  -webkit-border-radius: var(--bs-card-border-radius);
  -moz-border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
  -webkit-border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
  -moz-border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
  -webkit-border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
  -moz-border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  -webkit-border-radius: var(--bs-card-inner-border-radius);
  -moz-border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  -webkit-border-radius: var(--bs-nav-pills-border-radius);
  -moz-border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

* {
  /* antialiased, none, subpixel-antialiased*/
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-smooth: always;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  --bs-gutter-x: 2.5em !important;
}
@media (min-width: 1280px) {
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    --bs-gutter-x: 6em !important;
  }
}

:root {
  --bs-border-radius: 0.75rem;
  --bs-border-radius-sm: 0.438rem;
}

body {
  min-width: 320px;
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  font-family: "Calibri Regular";
}
body.open-menu {
  overflow: hidden;
}

body,
.site-container {
  overflow-x: hidden;
}

a:hover, a.simple-link:hover {
  color: #F6922A !important;
}

@media (max-width: 1024.98px) {
  a:hover, a.simple-link:hover {
    color: #000 !important;
  }
}
select, button {
  appearance: none;
  border: 0;
  box-shadow: none;
  background: none;
  padding: 0;
  outline: 0;
}

.anim-scale-img {
  overflow: hidden;
}
.anim-scale-img img {
  -moz-transition: all 600ms;
  -o-transition: all 600ms;
  -webkit-transition: all 600ms;
  transition: all 600ms;
}
.anim-scale-img:hover img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.card {
  --bs-card-border-radius: 0;
}

.field__title.active > span,
.field__title.active strong, .field__title.active strong > p, .field__title.is-active > span,
.field__title.is-active strong, .field__title.is-active strong > p {
  background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 0) calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px);
}
@media (min-width: 992px) {
  .field__title.active > span,
  .field__title.active strong, .field__title.active strong > p, .field__title.is-active > span,
  .field__title.is-active strong, .field__title.is-active strong > p {
    background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 0) calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px);
  }
}
.field__title-global {
  z-index: 0;
}
.field__title-global > span {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.field__title-global > span.remove-after::after {
  content: none;
}
.field__title-global > span > p {
  position: relative;
  display: inline-flex;
  margin-bottom: -0.625rem;
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.field__title-global > span > p::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0.625rem;
  background-color: #F7A754;
  overflow: hidden;
  width: 100%;
  transform-origin: left;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -moz-transition: transform 1.25s;
  -o-transition: transform 1.25s;
  -webkit-transition: transform 1.25s;
  transition: transform 1.25s;
  height: 0.6875rem;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
@media (min-width: 992px) {
  .field__title-global > span > p::after {
    height: 1.25rem;
  }
}
.field__title-global > span::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0.875rem;
  background-color: #F7A754;
  overflow: hidden;
  width: 100%;
  transform-origin: left;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -moz-transition: transform 1.25s;
  -o-transition: transform 1.25s;
  -webkit-transition: transform 1.25s;
  transition: transform 1.25s;
  height: 0.6875rem;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
@media (min-width: 992px) {
  .field__title-global > span::after {
    height: 1.25rem;
  }
}
.field__title-white {
  color: #fff;
}
.field__title, .field__title strong {
  text-transform: uppercase;
  font-family: "Calibri Bold";
  font-weight: normal;
  font-size: 1.875rem;
}
@media (min-width: 992px) {
  .field__title, .field__title strong {
    font-size: 3.4375rem;
  }
}
@media (min-width: 1441px) {
  .field__title, .field__title strong {
    font-size: 4rem;
  }
}
.field__title.text-no-uppercase, .field__title.text-no-uppercase strong {
  text-transform: none !important;
}
.field__title strong, .field__title strong > p {
  display: inline;
  --underline-width-scale: calc(var(--underline-width) / var(--underline-intrinsic-width));
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-repeat: no-repeat;
  background-image: linear-gradient(0, #F7A754, #F7A754);
  background-position-y: calc(100% - var(--underline-offset-y) * -2);
  background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 200) calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px);
  -moz-transition: background-size 1.5s;
  -o-transition: background-size 1.5s;
  -webkit-transition: background-size 1.5s;
  transition: background-size 1.5s;
}
@media (min-width: 992px) {
  .field__title strong, .field__title strong > p {
    background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 200) calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px);
  }
}
.field__title-submenu, .field__title-item {
  color: #000;
  position: relative;
  display: inline-flex;
  font-family: "Calibri Bold";
  font-weight: normal;
  padding-left: 0.125rem;
  padding-right: 0.125rem;
  font-size: 2rem;
}
@media (min-width: 992px) {
  .field__title-submenu, .field__title-item {
    font-size: 4rem;
  }
}
.field__title-submenu::after, .field__title-item::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0.875rem;
  background-color: #F7A754;
  overflow: hidden;
  width: 100%;
  transform-origin: left;
  height: 0.6875rem;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
@media (min-width: 992px) {
  .field__title-submenu::after, .field__title-item::after {
    height: 1.25rem;
  }
}
.field__link {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Calibri Bold";
  font-weight: normal;
  padding-left: 0.125rem;
  padding-right: 0.125rem;
  font-size: 2rem;
}
@media (min-width: 992px) {
  .field__link {
    font-size: 2.8125rem;
  }
}
@media (min-width: 1441px) {
  .field__link {
    font-size: 4rem;
  }
}
.field__link::after, .field__link > span:not(.icon)::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0.375rem;
  background-color: #F7A754;
  overflow: hidden;
  width: 100%;
  transform-origin: left;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -moz-transition: transform 1.25s;
  -o-transition: transform 1.25s;
  -webkit-transition: transform 1.25s;
  transition: transform 1.25s;
  height: 0.6875rem;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
@media (min-width: 992px) {
  .field__link::after, .field__link > span:not(.icon)::after {
    height: 1.25rem;
  }
}
.field__link > span:not(.icon) {
  position: relative;
  display: inline-flex;
}
.field__link:hover {
  color: #000 !important;
}
.field__link:hover::after, .field__link:hover > span::after {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.field__text-gray {
  color: #898989;
}
.field__text-20 {
  font-size: 1.25rem;
}
.field__text > p:last-child {
  margin-bottom: 0;
}
.field__text.strong-orange strong {
  color: #F6922A;
}

.fs-15 {
  font-size: 0.9375rem;
}
.fs-16 {
  font-size: 1rem;
}
.fs-18 {
  font-size: 1.125rem;
}
.fs-19 {
  font-size: 1.1875rem;
}
.fs-20 {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .fs-20 {
    font-size: 1.25rem;
  }
}
.fs-23 {
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .fs-23 {
    font-size: 1.4375rem;
  }
}
.fs-26 {
  font-size: 1.25rem;
}
@media (min-width: 1280px) {
  .fs-26 {
    font-size: 1.625rem;
  }
}
.fs-30 {
  font-size: 1.5rem;
}
@media (min-width: 1280px) {
  .fs-30 {
    font-size: 1.875rem;
  }
}
.fs-38 {
  font-size: 2.375rem;
}
.fs-60 {
  font-size: 2.5rem;
}
@media (min-width: 1280px) {
  .fs-60 {
    font-size: 3.4375rem;
  }
}
@media (min-width: 1440px) {
  .fs-60 {
    font-size: 3.75rem;
  }
}
.fs-64 {
  font-size: 4rem;
}
.fs-100 {
  font-size: 3.125rem;
}
@media (min-width: 768px) {
  .fs-100 {
    font-size: 6.25rem;
  }
}

.fw-bold {
  font-family: "Calibri Bold" !important;
  font-weight: bold !important;
}

@media (max-width: 991.98px) {
  .vh-100 {
    height: auto !important;
  }
  .vh-100.map__wrapper {
    height: 100vh !important;
  }
}
.social-medias__icon {
  width: 1.3125rem;
  height: 1.3125rem;
}
body.open-menu .social-medias__icon {
  width: 2.125rem;
  height: 2.125rem;
}
.footer .social-medias__icon {
  width: 1.75rem;
  height: 1.75rem;
}
.social-medias__icon .icon-svg {
  -moz-transition: border-color 225ms;
  -o-transition: border-color 225ms;
  -webkit-transition: border-color 225ms;
  transition: border-color 225ms;
}
.social-medias__icon .icon-svg svg {
  width: 0.625rem;
  height: 0.625rem;
  object-fit: contain;
}
body.open-menu .social-medias__icon .icon-svg svg {
  width: 1.125rem;
  height: 1.125rem;
}
body.open-menu .social-medias__icon .icon-svg svg path {
  fill: #000;
}
.footer .social-medias__icon .icon-svg svg {
  width: 0.875rem;
  height: 0.875rem;
}
.social-medias__icon .icon-svg svg path {
  fill: #fff;
  -moz-transition: fill 225ms;
  -o-transition: fill 225ms;
  -webkit-transition: fill 225ms;
  transition: fill 225ms;
}
.social-medias__icon .icon-svg:hover {
  border-color: #F6922A !important;
}
.social-medias__icon .icon-svg:hover svg path {
  fill: #F6922A;
}

.list__lang:hover .list__lang-drop {
  opacity: 1 !important;
  pointer-events: auto !important;
}
.list__lang:hover .list__lang-drop .item__lang {
  display: block;
}
.list__lang .list__lang-drop {
  -webkit-transform: translateX(-80%);
  -moz-transform: translateX(-80%);
  -ms-transform: translateX(-80%);
  transform: translateX(-80%);
  top: 1.25rem;
}
.list__lang > .item__lang {
  padding-right: 1.5625rem;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 15px;
  background-image: url("../img/icons/arrow-down-orange.svg");
  display: none;
  cursor: pointer;
}
.list__lang > .item__lang.active {
  display: block;
}

.bg-blue {
  background-color: #5471BA;
}
.bg-blue-opacity {
  background-color: rgba(84, 113, 186, 0.22);
}
.bg-blue-light {
  background-color: #E7EBF5;
}
.bg-orange {
  background-color: #F6922A;
}
.bg-orange-2 {
  background-color: #F7A754;
}
.bg-orange-light {
  background-color: #FFF8F0;
}
.bg-gray-light {
  background-color: #D9D9D9;
}
.bg-gray-light-2 {
  background-color: #F5F5F5;
}

.text-orange {
  color: #F6922A;
}
.text-orange-light {
  color: #F9BD7F;
}
.text-blue {
  color: #5471BA;
}
.text-gray {
  color: #898989;
}
.text-gray2 {
  color: #A8A8A8;
}
.text-gray3 {
  color: #BFBFBF;
}

.layouts {
  overflow: hidden;
  z-index: 1;
}

.layout-left {
  z-index: -1;
  width: 50vw;
  left: -10vw;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
}
.layout-left:before {
  content: "";
  position: absolute;
  z-index: -2;
  top: 1.25rem;
  right: -1.25rem;
  border: 1px solid #F6922A;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.layout-square {
  position: absolute;
  z-index: -1;
  display: inline-flex;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media (min-width: 992px) {
  .layout-square.active:before {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -moz-transition: all 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition: all 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}
.layout-square__color-gray {
  background-color: rgba(0, 0, 0, 0.06);
}
.layout-square__color-orange {
  background-color: #F6922A;
}
.layout-square__color-blue {
  background-color: #5471BA;
}
.layout-square__image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.layout-square__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transform: scale(1.6) rotate(-45deg);
  -moz-transform: scale(1.6) rotate(-45deg);
  -ms-transform: scale(1.6) rotate(-45deg);
  transform: scale(1.6) rotate(-45deg);
}
.layout-square__shadow:before {
  content: "";
  position: absolute;
  z-index: -1;
  border: 1px solid;
  width: 100%;
  height: 100%;
  top: 1.25rem;
  border-radius: 1.25rem;
  -moz-transition: all 1.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 1.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition: all 1.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 1.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.layout-square__shadow-bg:before {
  border: 0 !important;
}
.layout-square__shadow-bg-orange:before {
  background-color: #F6922A;
}
.layout-square__shadow-blue:before {
  border-color: #5471BA;
}
.layout-square__shadow-orange:before {
  border-color: #F6922A;
}
.layout-square__shadow-left:before {
  left: -1.5625rem;
}
.layout-square__shadow-right:before {
  right: -1.5625rem;
}
.layout-square__size-1 {
  border-radius: 1.625rem;
  width: 10.125rem;
  height: 10.125rem;
}
.layout-square__size-2 {
  border-radius: 0.8125rem;
  width: 5.8125rem;
  height: 5.8125rem;
}
.layout-square__size-3 {
  border-radius: 1.375rem;
  width: 8.25rem;
  height: 8.25rem;
}
.layout-square__size-4 {
  width: 8.875rem;
  height: 8.875rem;
}
@media (min-width: 992px) {
  .layout-square__size-4 {
    width: 23rem;
    height: 23rem;
  }
}
.layout-square__size-4,
.layout-square__size-4 .layout-square__image-wrapper, .layout-square__size-4:before {
  border-radius: 1.25rem;
}
.layout-square__size-4:before {
  top: 1.5625rem;
  left: 0.9375rem;
}
.layout-square__size-4 .layout-square__image-wrapper img {
  object-position: left;
}
@media (min-width: 992px) {
  .layout-square__size-4 .layout-square__image-wrapper img {
    object-position: -165px;
  }
}
.layout-square__size-5 {
  border-radius: 1.625rem;
  width: 17.625rem;
  height: 17.625rem;
}
.layout-square__size-6 {
  border-radius: 1.25rem;
  width: 20.875rem;
  height: 20.875rem;
}
.layout-square__size-6:before {
  top: -1.25rem;
  right: -1.25rem;
}
.layout-square__size-7 {
  border-radius: 1.5625rem;
  width: 40.375rem;
  height: 40.375rem;
}
.layout-square__size-7,
.layout-square__size-7 .layout-square__image-wrapper, .layout-square__size-7:before {
  border-radius: 1.5625rem;
}
.layout-square__size-7 .layout-square__image-wrapper img {
  object-position: left;
}
.layout-square__size-8 {
  border-radius: 1.875rem;
  width: 20.875rem;
  height: 20.875rem;
}
.layout-square__size-8,
.layout-square__size-8 .layout-square__image-wrapper, .layout-square__size-8:before {
  border-radius: 1.875rem;
}
.layout-square__size-9 {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  border-radius: 0.875rem;
  width: 8.4375rem;
  height: 8.4375rem;
}
.layout-square__size-10 {
  border-radius: 0.875rem;
  width: 10.625rem;
  height: 10.625rem;
}
.layout-square__size-11 {
  border-radius: 0.875rem;
  width: 8.4375rem;
  height: 8.4375rem;
}
.layout-square__size-11:before {
  left: -0.9375rem;
}
.layout-square__size-12 {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  border-radius: 0.875rem;
  width: 15.625rem;
  height: 15.625rem;
}
.layout-square__size-13 {
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
  border-radius: 0.625rem;
  width: 7.5rem;
  height: 7.5rem;
}
.layout-square__size-14 {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  width: 17.8125rem;
  height: 17.8125rem;
}
.layout-square__size-14,
.layout-square__size-14 .layout-square__image-wrapper, .layout-square__size-14:before {
  border-radius: 1.25rem;
}
.layout-square__size-14 .layout-square__image-wrapper img {
  object-position: right;
}
.layout-square__size-15 {
  border-radius: 1.625rem;
  width: 13.1875rem;
  height: 13.1875rem;
}
.layout-square__size-16 {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  border-radius: 1.25rem;
  width: 15.625rem;
  height: 15.625rem;
}
.layout-square__size-17 {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  border-radius: 0.875rem;
  width: 10.625rem;
  height: 10.625rem;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important;
}

a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none;
}

.gm-bundled-control {
  bottom: 110px !important;
  right: 70px !important;
}

.gm-style {
  font-family: "Calibri Regular" !important;
}
.gm-style .gm-style-iw {
  font-weight: 400 !important;
  font-size: 16px !important;
}

.gm-style-iw {
  border-radius: 0 !important;
  padding: 20px !important;
  min-width: 300px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
}
.gm-style-iw .gm-ui-hover-effect {
  top: 0 !important;
  right: -5px !important;
}
.gm-style-iw .gm-ui-hover-effect span {
  width: 20px !important;
  height: 20px !important;
  padding: 5px;
  margin: 0 !important;
}
.gm-style-iw .gm-style-iw-d {
  max-height: 100% !important;
  overflow: visible !important;
  word-break: break-all;
}
.gm-style-iw .gm-style-iw-d div {
  display: flex;
  flex-direction: column;
}

.gmnoprint > div {
  box-shadow: none !important;
  background: none !important;
}
.gmnoprint > div > div {
  background: none !important;
}
.gmnoprint > div > button {
  margin: 5px 0 !important;
  background-color: #fff !important;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  -moz-transition: all 225ms;
  -o-transition: all 225ms;
  -webkit-transition: all 225ms;
  transition: all 225ms;
}
.gmnoprint > div > button:hover {
  background-color: #F6922A !important;
}
.gmnoprint > div > button:hover:first-child:before {
  background-image: url("../img/icons/plus-white.svg");
}
.gmnoprint > div > button:hover:last-child:before {
  background-image: url("../img/icons/less-white.svg");
}
.gmnoprint > div > button:before {
  content: "";
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 19px;
  width: 1.1875rem;
  height: 1.1875rem;
}
.gmnoprint > div > button:first-child:before {
  background-image: url("../img/icons/plus.svg");
}
.gmnoprint > div > button:last-child:before {
  background-image: url("../img/icons/less.svg");
}
.gmnoprint > div > button > img {
  display: none !important;
}

.border-black {
  --bs-border-color: #000;
}
.border-gray {
  --bs-border-color: #898989;
}
.border-gray-light {
  --bs-border-color: #D9D9D9;
}
.border-orange {
  --bs-border-color: #F6922A;
}

.scrollbar-track-y {
  width: 20px !important;
  background-color: #fff !important;
  border-left: 1px solid rgba(0, 0, 0, 0.16);
}
.scrollbar-track-y .scrollbar-thumb {
  background-color: #F6922A !important;
  width: 100% !important;
  border-radius: 0 !important;
}

.shadow-border {
  position: relative;
}
.shadow-border:before {
  content: "";
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  background-color: #fff;
  border: 1px solid;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
.shadow-border-blue:before {
  border-color: #5471BA;
}
.shadow-border-orange:before {
  border-color: #F6922A;
}

@media (min-width: 992px) {
  .column-count-2 {
    column-count: 2;
  }
}
@media (min-width: 1280px) {
  .column-gap-xl {
    column-gap: 200px;
  }
}
.plyr {
  width: 100%;
  height: 23.3125rem;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #fff;
}
.plyr .plyr__control--overlaid {
  background: #F6922A;
  padding: calc(var(--plyr-control-spacing, 10px) * 2.5);
}
.plyr .plyr__control--overlaid:hover {
  background: #000 !important;
}
.plyr .plyr__control--overlaid svg {
  height: var(--plyr-control-icon-size, 30px);
  width: var(--plyr-control-icon-size, 30px);
}
.plyr .plyr__video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 0;
  height: 0;
}
.plyr .plyr__video-wrapper .plyr__poster {
  background-size: cover;
}
.plyr .plyr__video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 1280px) {
  .deco-image {
    position: relative;
    width: 36.6875rem;
    height: 36.6875rem;
  }
  .deco-image:before, .deco-image:after {
    content: "";
    position: absolute;
    border-radius: 14px;
    overflow: hidden;
  }
  .deco-image:before {
    z-index: -1;
    transform: rotate(70deg);
    top: 70px;
    left: -100px;
    border: 1px solid #000;
    width: 40.9375rem;
    height: 32.5rem;
  }
  .deco-image:after {
    z-index: -2;
    transform: rotate(100deg);
    top: -70px;
    left: 120px;
    background-color: #F6922A;
    width: 39.6875rem;
    height: 36.25rem;
  }
  .deco-duplicate-color-rotate {
    position: relative;
  }
  .deco-duplicate-color-rotate:before {
    content: "";
    position: absolute;
    top: -3.75rem;
    left: 0;
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
    border-radius: var(--bs-border-radius-sm);
    overflow: hidden;
    z-index: -1;
    background-color: #F6922A;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .deco-duplicate-color-rotate:before {
    width: 30rem;
    height: 27.5rem;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .deco-duplicate-color-rotate:before {
    top: 1.25rem;
    width: 30rem;
    height: 27.5rem;
  }
}
@media (min-width: 1280px) {
  .deco-duplicate-border {
    position: relative;
  }
  .deco-duplicate-border:hover:before {
    top: 0;
    left: 0;
  }
  .deco-duplicate-border:before {
    content: "";
    position: absolute;
    top: 1.25rem;
    left: -1.875rem;
    border-radius: var(--bs-border-radius-sm);
    overflow: hidden;
    z-index: -1;
    border: 1px solid #000;
    width: 100%;
    height: 100%;
    -moz-transition: all 600ms;
    -o-transition: all 600ms;
    -webkit-transition: all 600ms;
    transition: all 600ms;
  }
}
@media (min-width: 1280px) {
  .deco-duplicate-color {
    position: relative;
  }
  .deco-duplicate-color:before {
    content: "";
    position: absolute;
    top: 3.125rem;
    left: -4.375rem;
    border-radius: var(--bs-border-radius-sm);
    overflow: hidden;
    z-index: -1;
    background-color: #F7A754;
    width: 100%;
    height: 100%;
  }
  .deco-duplicate-color.is-active:before {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -moz-transition: all 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition: all 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .deco-duplicate-color-bis:before {
    border-radius: var(--bs-border-radius-xl);
  }
  .deco-duplicate-color-right:before {
    left: auto;
    top: 2.1875rem;
    right: -2.1875rem;
    width: 39.375rem;
  }
}
b, strong {
  font-family: "Calibri Bold" !important;
  font-weight: bold !important;
}

.view-more,
.view-less {
  -moz-transition: color 225ms;
  -o-transition: color 225ms;
  -webkit-transition: color 225ms;
  transition: color 225ms;
}
.view-more.hover-white:hover,
.view-less.hover-white:hover {
  color: #fff;
}

select option:checked {
  background-color: transparent !important;
  color: #000 !important;
}

.selected-filters__item {
  cursor: pointer;
  border: 1px solid transparent;
  -moz-transition: border-color 600ms;
  -o-transition: border-color 600ms;
  -webkit-transition: border-color 600ms;
  transition: border-color 600ms;
}
.selected-filters__item.level_studies:hover, .selected-filters__item.level_studies_trainings:hover {
  border-color: #5471BA;
}
.selected-filters__item.sectors:hover {
  border-color: #F6922A;
}

.bullet-deco {
  position: relative;
}
.bullet-deco:after {
  content: "";
  position: relative;
  display: inline-flex;
  background-color: #F6922A;
  border-radius: 50%;
  width: 0.625rem;
  height: 0.625rem;
  top: -0.125rem;
  margin-left: 0.9375rem;
}

.job {
  min-height: 25rem;
}
@media (max-width: 767.98px) {
  .job__image {
    height: 200px !important;
  }
}

@media (min-width: 1280px) {
  .w-xl-85 {
    width: 85% !important;
  }
}
:root {
  /* The intrinsic width of the underline stroke (in pixels). This is
   * the same as the height of the cap images. Don't specify the
   * units! This is because of some of the calculations we do later on. */
  --underline-intrinsic-width: 8;
  /* The actual width of the underline stroke we want to render (in pixels).
   * You can modify this, and the sizing and positioning should be calculated
   * accordingly. Again, Don't specify the units! */
  --underline-width: 9;
  /* The colour used to draw the underline. It should match the colour
   * used in the cap images... unfortunately we can't modify the SVG
   * fill via CSS because it's a background image. */
  --underline-color: #F7A754;
  /* We need to know the width of the cap images so that we
   * can position everything on the x axis accordingly. */
  --underline-cap-width: 4px;
  /* The border is positioned relative to the bottom of the line.
   * We can move it upwards a little to create an overlap effect. */
  --underline-offset-y: -2px;
  /* The padding to add to the x axis. By default, the caps would be
   * aligned with the beginning and end of the line. */
  --underline-padding-x: 0.12em;
}

.slider-trainings__items .training__image {
  height: 11.25rem;
}
@media (min-width: 768px) {
  .slider-trainings__items .training__image {
    height: 20.625rem;
  }
}

.toggle-content {
  position: relative;
}
.toggle-content:before {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #898989;
  transform-origin: left;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -moz-transition: transform 600ms;
  -o-transition: transform 600ms;
  -webkit-transition: transform 600ms;
  transition: transform 600ms;
}
.toggle-content:hover:before {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.slider-button:hover svg circle {
  fill: #F6922A;
}
.slider-button:hover svg path {
  stroke: #fff;
}
.slider-button svg circle {
  -moz-transition: fill 225ms;
  -o-transition: fill 225ms;
  -webkit-transition: fill 225ms;
  transition: fill 225ms;
}
.slider-button svg path {
  -moz-transition: stroke 225ms;
  -o-transition: stroke 225ms;
  -webkit-transition: stroke 225ms;
  transition: stroke 225ms;
}

@media (min-width: 1441px) {
  .slider-trainings .container,
  .slider-jobs .container {
    --bs-gutter-x: 2em !important;
  }
}

#go-to-iam-page[disabled],
#go-to-iam-page-mobile[disabled] {
  pointer-events: none;
  opacity: 0.5;
  background-color: #F6922A;
  border-color: #F6922A;
}

.selectric {
  border: 0 !important;
  background: transparent !important;
}
.selectric .label {
  margin: 0 38px 0 0 !important;
  font-size: 18px !important;
  color: #898989 !important;
}
.selectric .button {
  display: none !important;
}

.selectric-wrapper.selectric-multiple .selectric-items li.selected {
  padding-left: 30px;
  background-color: #F6922A;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxOSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgNy41TDcgMTNMMTcgMiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  background-size: 14px;
  background-position: center left 10px;
  background-repeat: no-repeat;
  color: #ffffff;
}

.selectric-wrapper.selectric-multiple .selectric-items li.selected:hover {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuNSAxM0wxMi41IDIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0yIDIuNUwxMyAxMi41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
  background-color: #898989;
  background-position: center left 10px;
  background-size: 14px;
}

.anim-from-left, .anim-from-right {
  opacity: 0;
}
.anim-from-left:nth-child(1), .anim-from-right:nth-child(1) {
  transition: all 1s 0.08s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(2), .anim-from-right:nth-child(2) {
  transition: all 1s 0.16s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(3), .anim-from-right:nth-child(3) {
  transition: all 1s 0.24s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(4), .anim-from-right:nth-child(4) {
  transition: all 1s 0.32s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(5), .anim-from-right:nth-child(5) {
  transition: all 1s 0.4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(6), .anim-from-right:nth-child(6) {
  transition: all 1s 0.48s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(7), .anim-from-right:nth-child(7) {
  transition: all 1s 0.56s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(8), .anim-from-right:nth-child(8) {
  transition: all 1s 0.64s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(9), .anim-from-right:nth-child(9) {
  transition: all 1s 0.72s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(10), .anim-from-right:nth-child(10) {
  transition: all 1s 0.8s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(11), .anim-from-right:nth-child(11) {
  transition: all 1s 0.88s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(12), .anim-from-right:nth-child(12) {
  transition: all 1s 0.96s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(13), .anim-from-right:nth-child(13) {
  transition: all 1s 1.04s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(14), .anim-from-right:nth-child(14) {
  transition: all 1s 1.12s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(15), .anim-from-right:nth-child(15) {
  transition: all 1s 1.2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(16), .anim-from-right:nth-child(16) {
  transition: all 1s 1.28s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(17), .anim-from-right:nth-child(17) {
  transition: all 1s 1.36s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(18), .anim-from-right:nth-child(18) {
  transition: all 1s 1.44s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(19), .anim-from-right:nth-child(19) {
  transition: all 1s 1.52s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(20), .anim-from-right:nth-child(20) {
  transition: all 1s 1.6s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(21), .anim-from-right:nth-child(21) {
  transition: all 1s 1.68s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(22), .anim-from-right:nth-child(22) {
  transition: all 1s 1.76s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(23), .anim-from-right:nth-child(23) {
  transition: all 1s 1.84s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(24), .anim-from-right:nth-child(24) {
  transition: all 1s 1.92s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(25), .anim-from-right:nth-child(25) {
  transition: all 1s 2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(26), .anim-from-right:nth-child(26) {
  transition: all 1s 2.08s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(27), .anim-from-right:nth-child(27) {
  transition: all 1s 2.16s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(28), .anim-from-right:nth-child(28) {
  transition: all 1s 2.24s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(29), .anim-from-right:nth-child(29) {
  transition: all 1s 2.32s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(30), .anim-from-right:nth-child(30) {
  transition: all 1s 2.4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(31), .anim-from-right:nth-child(31) {
  transition: all 1s 2.48s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(32), .anim-from-right:nth-child(32) {
  transition: all 1s 2.56s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(33), .anim-from-right:nth-child(33) {
  transition: all 1s 2.64s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(34), .anim-from-right:nth-child(34) {
  transition: all 1s 2.72s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(35), .anim-from-right:nth-child(35) {
  transition: all 1s 2.8s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(36), .anim-from-right:nth-child(36) {
  transition: all 1s 2.88s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(37), .anim-from-right:nth-child(37) {
  transition: all 1s 2.96s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(38), .anim-from-right:nth-child(38) {
  transition: all 1s 3.04s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(39), .anim-from-right:nth-child(39) {
  transition: all 1s 3.12s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(40), .anim-from-right:nth-child(40) {
  transition: all 1s 3.2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(41), .anim-from-right:nth-child(41) {
  transition: all 1s 3.28s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(42), .anim-from-right:nth-child(42) {
  transition: all 1s 3.36s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(43), .anim-from-right:nth-child(43) {
  transition: all 1s 3.44s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(44), .anim-from-right:nth-child(44) {
  transition: all 1s 3.52s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(45), .anim-from-right:nth-child(45) {
  transition: all 1s 3.6s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(46), .anim-from-right:nth-child(46) {
  transition: all 1s 3.68s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(47), .anim-from-right:nth-child(47) {
  transition: all 1s 3.76s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(48), .anim-from-right:nth-child(48) {
  transition: all 1s 3.84s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(49), .anim-from-right:nth-child(49) {
  transition: all 1s 3.92s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left:nth-child(50), .anim-from-right:nth-child(50) {
  transition: all 1s 4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.anim-from-left.is-active, .anim-from-right.is-active {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.anim-from-left {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
.anim-from-right {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.double_column__action {
  -moz-transition: color 225ms;
  -o-transition: color 225ms;
  -webkit-transition: color 225ms;
  transition: color 225ms;
}
@media (min-width: 1025px) {
  .double_column__action:hover {
    color: #F6922A;
  }
}
@media (max-width: 1024.98px) {
  .double_column__action {
    color: #000;
  }
  .double_column__action:hover {
    color: #000;
  }
}

.double_column_color__rich-text ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 0.9375rem;
}
@media (min-width: 768px) {
  .double_column_color__rich-text ul {
    column-count: 2;
  }
}
.double_column_color__rich-text ul li {
  position: relative;
  margin-bottom: 1.125rem;
  padding-left: 0.625rem;
}
.double_column_color__rich-text ul li:last-child {
  margin-bottom: 0;
}
.double_column_color__rich-text ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.625rem;
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 50%;
  background-color: #F6922A;
}
.double_column_color__rich-text ul li strong {
  font-family: "Calibri Bold";
  font-weight: bold;
}
.double_column_color .layouts #layout-double_column_color-0 {
  bottom: 60px;
  right: -80px;
}
@media (min-width: 1440px) {
  .double_column_color .layouts #layout-double_column_color-0 {
    right: 0;
  }
}

.double_column_twisted_bg__bg {
  z-index: -1;
  left: -10%;
  -webkit-transform: rotate(1deg);
  -moz-transform: rotate(1deg);
  -ms-transform: rotate(1deg);
  transform: rotate(1deg);
}

@media (min-width: 1280px) {
  .double_column_lists__image {
    height: 35.3125rem;
  }
}
.double_column_lists__items-item > p {
  position: relative;
}
.double_column_lists__items-item > p:before {
  content: "";
  position: relative;
  display: inline-flex;
  top: -0.1875rem;
  margin-right: 0.3125rem;
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 50%;
  background-color: #000;
}
.double_column_lists__box-item {
  position: relative;
}
.double_column_lists__box-item:before {
  content: "";
  position: relative;
  display: inline-flex;
  top: -0.1875rem;
  margin-right: 0.3125rem;
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 50%;
  background-color: #000;
}

.label__image {
  width: 11.5625rem;
  height: 11.5625rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.label__image img {
  width: 9.375rem;
  height: 9.375rem;
}

.infos_1__infos-info-icon {
  width: 4.375rem;
  height: 4.375rem;
}
.infos_1__infos-info-text {
  position: relative;
}
.infos_1__infos-info-text:before {
  content: "";
  position: relative;
  display: inline-flex;
  background-color: #F6922A;
  border-radius: 50%;
  width: 0.3125rem;
  height: 0.3125rem;
  top: -0.125rem;
  margin-right: 0.3125rem;
}

.infos_2__infos-info {
  width: 21.375rem;
}
.infos_2__infos-info-counter {
  right: 1.5625rem;
}

.infos_3__infos-info-title {
  position: relative;
}
.infos_3__infos-info-title:after {
  content: "";
  position: relative;
  display: inline-flex;
  background-color: #F6922A;
  border-radius: 50%;
  width: 0.625rem;
  height: 0.625rem;
  top: -0.125rem;
  margin-left: 0.9375rem;
}

@media (min-width: 992px) {
  .infos_4__infos-info {
    width: 28%;
  }
}
.infos_4__infos-info-icon {
  width: 5rem;
  height: 5rem;
}
.infos_4__infos-info-text {
  position: relative;
}
.infos_4__infos-info-text:before {
  content: "";
  position: relative;
  display: inline-flex;
  background-color: #5471BA;
  border-radius: 50%;
  width: 0.3125rem;
  height: 0.3125rem;
  top: -0.125rem;
  margin-right: 0.3125rem;
}

.black_push a {
  -moz-transition: background-color 600ms;
  -o-transition: background-color 600ms;
  -webkit-transition: background-color 600ms;
  transition: background-color 600ms;
}
.black_push a:hover {
  background-color: #F7A754 !important;
}
.black_push a:hover .black_push__icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.black_push a:hover .black_push__image svg path {
  fill: #000;
}
.black_push__link {
  min-height: 20.9375rem;
}
.black_push__icon {
  right: 4.375rem;
  bottom: 4.375rem;
  -moz-transition: transform 225ms;
  -o-transition: transform 225ms;
  -webkit-transition: transform 225ms;
  transition: transform 225ms;
}
@media (max-width: 767.98px) {
  .black_push__icon {
    width: 4.375rem;
    height: 4.375rem;
  }
}
.black_push__image {
  right: 15.625rem;
  bottom: -0.5rem;
}
.black_push__image svg path {
  -moz-transition: fill 225ms;
  -o-transition: fill 225ms;
  -webkit-transition: fill 225ms;
  transition: fill 225ms;
}

.logos__logos-logo {
  width: 6.875rem;
  height: 6.875rem;
}
@media (min-width: 768px) {
  .logos__logos-logo {
    width: 12.5rem;
    height: 12.5rem;
  }
}

@media (max-width: 767.98px) {
  .slider__items {
    display: flex;
  }
}
@media (min-width: 768px) {
  .slider__item {
    height: 20rem;
  }
}

.logos_grid__logos-item,
.logos_grid_2__logos-item {
  width: 100%;
}
@media (min-width: 768px) {
  .logos_grid__logos-item,
  .logos_grid_2__logos-item {
    height: 11.875rem;
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .logos_grid__logos-item,
  .logos_grid_2__logos-item {
    width: 20%;
  }
}
.logos_grid__logos-item img,
.logos_grid_2__logos-item img {
  max-height: 4.375rem;
}
@media (min-width: 768px) {
  .logos_grid__logos-item img,
  .logos_grid_2__logos-item img {
    max-height: 7.5rem;
  }
}

.logos_grid_2__logos-item {
  width: 100%;
}
@media (min-width: 768px) {
  .logos_grid_2__logos-item {
    height: 11.875rem;
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .logos_grid_2__logos-item {
    width: 25%;
  }
}
.logos_grid_2__logos-item img {
  max-height: 4.375rem;
}
@media (min-width: 768px) {
  .logos_grid_2__logos-item img {
    max-height: 7.5rem;
  }
}

.projects_slider__items {
  display: flex;
}
.projects_slider__item-box-text {
  position: relative;
}
.projects_slider__item-box-text:before {
  content: "";
  position: relative;
  display: inline-flex;
  top: -0.1875rem;
  margin-right: 0.3125rem;
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 50%;
  background-color: #F6922A;
}

.navigation-mini {
  z-index: 6;
}
.navigation-mini .simple-link:hover .icon {
  -webkit-transform: scale(1.35);
  -moz-transform: scale(1.35);
  -ms-transform: scale(1.35);
  transform: scale(1.35);
}
.navigation-mini .icon {
  top: -1px;
  position: relative;
  -moz-transition: transform 600ms;
  -o-transition: transform 600ms;
  -webkit-transition: transform 600ms;
  transition: transform 600ms;
}

.navigation-top {
  z-index: 5;
  height: 8.375rem;
}
.navigation-top .menu-item:hover.menu-item-has-children > .item-link > .icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.navigation-top .menu-item.menu-item-has-children > .item-link > .icon {
  display: inline-flex;
  margin-left: 0.5rem;
  width: 1rem;
  height: 1rem;
  -moz-transition: transform 225ms;
  -o-transition: transform 225ms;
  -webkit-transition: transform 225ms;
  transition: transform 225ms;
}
body.open-menu .navigation-top .menu-item.menu-item-has-children > .item-link > .icon {
  margin-left: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
}
.navigation-top .menu-item.menu-item-has-children > .item-link > .icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.navigation-top .menu-item.menu-item-has-children:hover > .item-link {
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.navigation-top .menu-item.menu-item-has-children:hover .navigation-top__submenu {
  opacity: 1 !important;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  pointer-events: auto !important;
}
.navigation-top .menu-item.menu-item-has-children.active > .nav-drop {
  max-height: 50vh;
  transition: max-height 0.6s ease-in;
}
.navigation-top .menu-item.menu-item-has-children > .nav-drop {
  position: relative;
  z-index: 0;
}
@media (max-width: 1279.98px) {
  .navigation-top .menu-item.menu-item-has-children > .nav-drop {
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
  }
}
.navigation-top__lang-mobile {
  position: relative;
  z-index: 5;
}
.navigation-top__lang-mobile .item__lang, .navigation-top__lang-mobile .item__lang span {
  color: #000 !important;
}
body.open-menu .navigation-top__lang-mobile {
  display: flex !important;
}
.navigation-top #navigation-top__logo {
  position: relative;
  z-index: 1;
  display: flex;
  width: 150px;
  height: auto;
}
@media (min-width: 1280px) {
  .navigation-top #navigation-top__logo {
    width: 13.75rem;
    height: 8.375rem;
  }
}
body.open-menu .navigation-top #navigation-top__logo {
  display: none !important;
}
.navigation-top #navigation-top__logo > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.navigation-top__submenu {
  top: 6.25rem;
  height: 50rem;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -moz-transition: opacity 225ms, transform 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  -o-transition: opacity 225ms, transform 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  -webkit-transition: opacity 225ms, transform 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  transition: opacity 225ms, transform 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 1000;
}
.navigation-top__submenu .menu-item:hover.menu-item-specific > .icon-sm {
  width: 4.125rem;
}
.navigation-top__submenu .menu-item:hover.menu-item-specific > .item-link {
  font-family: "Calibri Bold";
}
.navigation-top__submenu .menu-item:hover.menu-item-specific > .item-link:hover {
  color: #000 !important;
}
.navigation-top__submenu .menu-item:hover > .item-link {
  padding-left: 130px;
}
.navigation-top__submenu .menu-item:hover > .item-link .anim-underline {
  color: #000 !important;
  background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 0) calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px);
}
@media (min-width: 992px) {
  .navigation-top__submenu .menu-item:hover > .item-link .anim-underline {
    background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 0) calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px);
  }
}
.navigation-top__submenu .menu-item:hover > .item-link .icon {
  width: 6.875rem;
}
.navigation-top__submenu .menu-item.menu-item-specific > .icon-sm {
  width: 0;
}
.navigation-top__submenu .menu-item > .item-link {
  display: inline;
  position: relative;
  padding-left: 45px;
  -moz-transition: padding-left 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  -o-transition: padding-left 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  -webkit-transition: padding-left 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  transition: padding-left 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}
.navigation-top__submenu .menu-item > .item-link .anim-underline {
  display: inline;
  font-family: "Calibri Bold";
  font-weight: normal;
  padding-left: 0.125rem;
  padding-right: 0.125rem;
  font-size: 2rem;
  --underline-width-scale: calc(var(--underline-width) / var(--underline-intrinsic-width));
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-repeat: no-repeat;
  background-image: linear-gradient(0, #F7A754, #F7A754);
  background-position-y: calc(100% - var(--underline-offset-y) * -2);
  background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 200) calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px), auto calc(var(--underline-width) * 1.5px);
  -moz-transition: background-size 1.5s;
  -o-transition: background-size 1.5s;
  -webkit-transition: background-size 1.5s;
  transition: background-size 1.5s;
}
@media (min-width: 992px) {
  .navigation-top__submenu .menu-item > .item-link .anim-underline {
    font-size: 2.8125rem;
  }
}
@media (min-width: 1441px) {
  .navigation-top__submenu .menu-item > .item-link .anim-underline {
    font-size: 4rem;
  }
}
@media (min-width: 992px) {
  .navigation-top__submenu .menu-item > .item-link .anim-underline {
    background-size: calc(100% - var(--underline-cap-width) * var(--underline-width-scale) * 200) calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px), auto calc(var(--underline-width) * 2.5px);
  }
}
.navigation-top__submenu .menu-item > .item-link .icon {
  position: absolute;
  left: 0;
  top: 0.25rem;
  overflow: hidden;
  width: 2.0625rem;
  height: 2.0625rem;
  margin-right: 1.25rem;
  -moz-transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  -o-transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  -webkit-transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  background-image: url("../img/icons/arrow-right-long.svg");
  background-repeat: no-repeat;
  background-position: center right;
}
.navigation-top__submenu .menu-item > .icon-sm {
  overflow: hidden;
  width: 2.0625rem;
  height: 2.0625rem;
  margin-right: 1.25rem;
  -moz-transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  -o-transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  -webkit-transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  transition: width 1s cubic-bezier(0.29, 1.4, 0.44, 0.96);
  background-image: url("../img/icons/arrow-right-long-sm.svg");
  background-repeat: no-repeat;
  background-position: center right;
}
.navigation-top__submenu .sectors {
  margin-left: -1.25rem;
}

#burger-menu {
  position: relative;
  z-index: 1;
  width: 2.1875rem;
  height: 2.1875rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -moz-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  -webkit-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
  cursor: pointer;
}
#burger-menu.open span:nth-child(1) {
  top: 0.625rem;
  width: 0;
  left: 50%;
}
#burger-menu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#burger-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#burger-menu.open span:nth-child(4) {
  top: 0.625rem;
  width: 0;
  left: 50%;
}
#burger-menu span {
  display: block;
  position: absolute;
  height: 0.125rem;
  width: 100%;
  background-color: #000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
#burger-menu span:nth-child(1) {
  top: 0.3125rem;
}
#burger-menu span:nth-child(2), #burger-menu span:nth-child(3) {
  top: 1.0625rem;
}
#burger-menu span:nth-child(4) {
  top: 1.8125rem;
}

#site-navigation-top__mobile {
  z-index: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -moz-transition: transform 600ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: transform 600ms cubic-bezier(0.42, 0, 0.58, 1);
  -webkit-transition: transform 600ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: transform 600ms cubic-bezier(0.42, 0, 0.58, 1);
}
#site-navigation-top__mobile.open {
  pointer-events: auto !important;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.header {
  z-index: 0;
}
.header#site-header-home .layouts {
  left: -80px !important;
  overflow: visible;
}
@media (min-width: 1441px) {
  .header#site-header-home .layouts {
    left: 0 !important;
    overflow: hidden;
  }
}
.header#site-header-home .layouts #layout-header-0 {
  display: none;
  left: 37%;
  top: -135px;
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-0 {
    display: block;
  }
}
.header#site-header-home .layouts #layout-header-1 {
  display: none;
  left: 90%;
  top: -80px;
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-1 {
    display: block;
  }
}
.header#site-header-home .layouts #layout-header-2 {
  top: 220px;
  left: 30px;
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-2 {
    top: 200px;
    left: auto;
    right: -45px;
  }
}
.header#site-header-home .layouts #layout-header-3 {
  display: none;
  right: -100px;
  top: 540px;
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-3 {
    display: block;
  }
}
.header#site-header-home .layouts #layout-header-4 {
  top: 240px;
  right: -120px;
}
@media (min-width: 768px) {
  .header#site-header-home .layouts #layout-header-4 {
    top: 200px;
  }
}
@media (min-width: 992px) {
  .header#site-header-home .layouts #layout-header-4 {
    right: -350px;
  }
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-4 {
    right: -300px;
    top: 680px;
  }
}
.header#site-header-home .layouts #layout-header-5 {
  display: none;
  top: -150px;
  left: -65px;
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-5 {
    display: block;
  }
}
@media (min-width: 1441px) {
  .header#site-header-home .layouts #layout-header-5 {
    left: -80px;
  }
}
@media (min-width: 1700px) {
  .header#site-header-home .layouts #layout-header-5 {
    left: -20px;
  }
}
.header#site-header-home .layouts #layout-header-6 {
  display: none;
  top: 40px;
  left: -375px;
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-6 {
    display: block;
  }
}
@media (min-width: 1441px) {
  .header#site-header-home .layouts #layout-header-6 {
    top: 25px;
    left: -380px;
  }
}
@media (min-width: 1700px) {
  .header#site-header-home .layouts #layout-header-6 {
    top: 50px;
    left: -300px;
  }
}
.header#site-header-home .layouts #layout-header-7 {
  display: none;
  top: 335px;
  left: -225px;
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-7 {
    display: block;
    left: -280px;
  }
}
@media (min-width: 1700px) {
  .header#site-header-home .layouts #layout-header-7 {
    top: 340px;
    left: -170px;
  }
}
.header#site-header-home .layouts #layout-header-8 {
  display: none;
  top: 260px;
  left: 310px;
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-8 {
    display: block;
  }
}
@media (min-width: 1441px) {
  .header#site-header-home .layouts #layout-header-8 {
    left: 350px;
  }
}
.header#site-header-home .layouts #layout-header-9 {
  display: none;
  top: 950px;
  left: -340px;
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-9 {
    display: block;
  }
}
@media (min-width: 1441px) {
  .header#site-header-home .layouts #layout-header-9 {
    left: -380px;
    top: 960px;
  }
}
@media (min-width: 1700px) {
  .header#site-header-home .layouts #layout-header-9 {
    left: -290px;
  }
}
.header#site-header-home .layouts #layout-header-10 {
  display: none;
  top: 960px;
  left: 210px;
}
@media (min-width: 1280px) {
  .header#site-header-home .layouts #layout-header-10 {
    display: block;
  }
}
@media (min-width: 1441px) {
  .header#site-header-home .layouts #layout-header-10 {
    top: 965px;
    left: 130px;
  }
}
@media (min-width: 1700px) {
  .header#site-header-home .layouts #layout-header-10 {
    left: 250px;
  }
}
.header#site-header-home .words > .word {
  position: relative;
}
.header#site-header-home .words > .word:last-child:after {
  content: none;
}
.header#site-header-home .words > .word:after {
  content: "";
  position: absolute;
  top: 0.4375rem;
  right: -2px;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background-color: #F6922A;
}
@media (min-width: 768px) {
  .header#site-header-home .words > .word:after {
    top: 0.75rem;
    right: 0;
    width: 0.375rem;
    height: 0.375rem;
  }
}
.header#site-header-home .fake-form select {
  padding-right: 1.875rem;
  background-image: url("../img/icons/arrow-down.svg");
  background-size: 20px;
  background-position: center right;
  background-repeat: no-repeat;
}
.header#site-header-home .push__wrapper {
  position: relative;
}
@media (max-width: 767.98px) {
  .header#site-header-home .push__wrapper {
    transform: scale(1.25);
  }
}
@media (min-width: 1441px) {
  .header#site-header-home .push__wrapper {
    right: -4.375rem;
  }
}
.header#site-header-home .push__wrapper .push {
  position: relative;
  width: 45.8125rem;
  height: 33.9375rem;
  background-image: url("../img/global/bg-push.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
  .header#site-header-home .push__wrapper .push {
    width: 100%;
    height: 300px;
  }
}
.header#site-header-home .push__wrapper .push:hover .btn-link-icon-big {
  color: #648267 !important;
}
.header#site-header-home .push__wrapper .push:hover .icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header#site-header-home .push__wrapper .push:hover .icon svg circle {
  fill: #648267;
}
.header#site-header-home .push__wrapper .push:hover .icon svg path {
  stroke: #fff;
}
.header#site-header-home .push__wrapper .push .wrapper {
  position: absolute;
  -webkit-transform: rotate(6.5deg) translate(-40%, -50%);
  -moz-transform: rotate(6.5deg) translate(-40%, -50%);
  -ms-transform: rotate(6.5deg) translate(-40%, -50%);
  transform: rotate(6.5deg) translate(-40%, -50%);
  top: 50%;
  left: 50%;
  padding-top: 1.875rem;
  padding-left: 6.25rem;
}
@media (max-width: 767.98px) {
  .header#site-header-home .push__wrapper .push .wrapper {
    padding-top: 0;
    padding-left: 0;
    transform: scale(0.6) rotate(6.5deg) translate(-50%, -70%);
    width: 70%;
  }
}

@media (max-width: 767.98px) {
  #form-search-iam-page {
    font-family: "Calibri Bold";
    color: #000 !important;
  }
}

.pre-footer__animate-title {
  margin-bottom: -0.25rem;
  color: #F6922A;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 20s linear infinite;
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

#footer__logo {
  width: 150px;
  height: auto;
}
@media (min-width: 1280px) {
  #footer__logo {
    width: 21.375rem;
    height: 13rem;
  }
}

.footer__bg {
  z-index: -1;
  transform: scaleX(1.3);
}
@media (max-width: 767.98px) {
  .footer__links {
    border: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .footer .simple-link {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .footer #menu-footer-2 .menu-item:not(:last-child) {
    margin-bottom: 1.5625rem;
  }
  .footer #menu-footer-3 .menu-item:not(:last-child) {
    margin-bottom: 3.4375rem;
  }
}
@media (min-width: 1280px) {
  .footer #menu-footer-4 .menu-item:last-child {
    margin-top: 4.4375rem;
  }
}
.footer #menu-footer-4 .menu-item.icon-arrow .item-link {
  padding-right: 2.8125rem;
  background-image: url("../img/icons/arrow-rotate.svg");
  background-size: 20px;
  background-position: top 7px right;
  background-repeat: no-repeat;
}
.footer > .container > .row > .col-12 {
  position: relative;
  margin-bottom: 30px;
}
.footer > .container > .row > .col-12:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 1px;
}
@media (min-width: 1280px) {
  .footer > .container > .row > .col-12 {
    margin-bottom: 0;
  }
  .footer > .container > .row > .col-12:after {
    content: "";
    position: absolute;
    top: 50%;
    bottom: auto;
    transform: translateY(-58%);
    left: auto;
    right: 0;
    background-color: #000;
    width: 1px;
    height: 80%;
  }
}
.footer > .container > .row > .col-12:last-child:after {
  content: none;
}
@media (max-width: 991.98px) {
  .footer__links {
    padding-bottom: 30px;
    border: 0 !important;
    border-bottom: 1px solid #fff !important;
  }
}
.footer .item-link[href="#"] {
  pointer-events: none;
}

.establishments .establishment.active {
  background-color: rgba(217, 217, 217, 0.18);
}
.establishments .establishment__link {
  right: 1.875rem;
  bottom: 1.875rem;
}
.establishments .establishment__sectors {
  column-count: 2;
}
.establishments .establishment__sectors-item {
  position: relative;
  padding-left: 0.75rem;
}
.establishments .establishment__sectors-item:before {
  content: "";
  position: absolute;
  background-color: #9EB3E6;
  top: 0.5625rem;
  left: 0rem;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
}

.newsletter__bg {
  z-index: -1;
  left: -10%;
  -webkit-transform: rotate(1deg);
  -moz-transform: rotate(1deg);
  -ms-transform: rotate(1deg);
  transform: rotate(1deg);
}
.newsletter__input {
  height: 5rem;
}
.newsletter__checkbox {
  cursor: pointer;
}
.newsletter input {
  outline: 0;
  cursor: pointer;
}
.newsletter input[type=checkbox] {
  position: relative;
  appearance: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  border-radius: 5px;
  border: 1px solid #000;
  top: 0.1875rem;
  width: 1.1875rem;
  height: 1.1875rem;
}
.newsletter input[type=checkbox]:checked {
  background-color: #000;
  background-image: url("../img/icons/check.svg");
}
.newsletter button[type=submit] {
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newsletter button[type=submit] > .icon {
  display: inline-flex;
  width: 1.0625rem;
  height: 1.0625rem;
  background-image: url("../img/icons/arrow-rotate.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -moz-transition: transform 225ms;
  -o-transition: transform 225ms;
  -webkit-transition: transform 225ms;
  transition: transform 225ms;
}
.newsletter button[type=submit]:hover > .icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.breadcrumb.before-white ul li:after {
  background-color: #fff;
}
.breadcrumb ul li {
  position: relative;
  padding-right: 0.625rem;
  margin-right: 0.625rem;
  font-size: 1rem;
}
.breadcrumb ul li:last-child {
  padding: 0;
  margin: 0;
}
.breadcrumb ul li:last-child:after {
  content: none;
}
.breadcrumb ul li:after {
  content: "";
  position: absolute;
  top: 0.5625rem;
  right: -0.125rem;
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: #898989;
  border-radius: 50%;
}

.training__list li {
  position: relative;
  padding-left: 0.5rem;
}
.training__list li:after {
  content: "";
  position: absolute;
  top: 0.625rem;
  left: -0.125rem;
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: #F6922A;
  border-radius: 50%;
}
.training__choice_establishment-wrapper {
  position: relative;
  z-index: 5;
}
.training__image {
  height: 25.9375rem;
}
@media (max-width: 767.98px) {
  .training__image {
    height: 200px !important;
  }
}
@media (max-width: 991.98px) {
  .training__link {
    position: relative !important;
  }
}

@media (max-width: 767.98px) {
  .more_establishment {
    font-size: 14px;
    text-align: left;
  }
  .more_establishment:focus, .more_establishment:visited {
    color: #000 !important;
  }
}

.job__image {
  height: 100%;
}
.slider-jobs .job__image {
  height: 23.75rem;
}

.trainings .initial-trainings__level_studies-item > a {
  min-width: 14.375rem;
}
.trainings #initial-trainings__image-2 {
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
@media (max-width: 767.98px) {
  .trainings__title {
    text-align: left !important;
  }
}

.news__item:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 1279.98px) {
  .news-home__link {
    color: #F6922A !important;
  }
  .news-home__link .icon svg circle, .news-home__link .icon svg path {
    stroke: #F6922A;
  }
}
@media (max-width: 1279.98px) {
  .news-home__title {
    color: #000 !important;
  }
}

.campus-life {
  position: relative;
}
.campus-life #campus-life__push-2 {
  position: relative;
  z-index: 1;
}
.campus-life #layout-campus-life-1 {
  z-index: 0;
  top: -3.125rem;
  left: 28.125rem;
}
.campus-life #layout-campus-life-2 {
  z-index: 0;
  bottom: 18.75rem;
  left: -9.375rem;
}
@media (min-width: 1440px) {
  .campus-life #layout-campus-life-2 {
    bottom: 25rem;
    left: -3.125rem;
  }
}
.campus-life #layout-campus-life-3 {
  z-index: 0;
  bottom: 9.375rem;
  right: 0;
}
@media (min-width: 1440px) {
  .campus-life #layout-campus-life-3 {
    right: 6.25rem;
  }
}
.campus-life__video > .plyr {
  width: 100%;
  height: 23.3125rem;
  border-radius: 16px;
  overflow: hidden;
  border: 0;
}
.campus-life__video > .plyr .plyr__video-wrapper {
  position: relative;
  height: 100%;
}
.campus-life__video > .plyr .plyr__video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.network__array {
  position: relative;
  z-index: 1;
}
@media (max-width: 1279.98px) {
  .network__array * {
    border: 0 !important;
  }
}
@media (min-width: 1280px) {
  .network__array {
    height: 30.8125rem;
  }
}
.network__our-partners-cta {
  top: -0.625rem;
}
.network__our-partners-title {
  position: relative;
  z-index: 1;
}
.network #layout-network {
  z-index: 0;
  top: -6.25rem;
  right: -17.1875rem;
}
@media (min-width: 768px) {
  .network #layout-network {
    top: -6.25rem;
    left: -6.5625rem;
  }
}

.next-steps__bg {
  z-index: -1;
  left: -10%;
  -webkit-transform: rotate(1deg);
  -moz-transform: rotate(1deg);
  -ms-transform: rotate(1deg);
  transform: rotate(1deg);
}
.next-steps .field__title strong {
  color: #F6922A;
}
.next-steps__item:hover .next-steps__icon svg {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.next-steps__icon {
  left: 0;
  bottom: 1.5625rem;
}
.next-steps__icon svg {
  -moz-transition: transform 225ms;
  -o-transition: transform 225ms;
  -webkit-transition: transform 225ms;
  transition: transform 225ms;
}

.key-figures__item {
  margin: 0 65px;
  white-space: nowrap;
}
@media (max-width: 767.98px) {
  .key-figures__item {
    margin: 0 20px;
  }
}
@media (max-width: 767.98px) {
  .key-figures__title {
    text-align: left !important;
  }
}

#marquee > div,
.js-marquee {
  display: flex;
  align-items: center;
}

.template-iam .icon-35 {
  width: 2.1875rem;
  height: 2.1875rem;
}
.template-iam .icon-35 svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.template-iam__custom-pages a .icon {
  -moz-transition: transform 225ms;
  -o-transition: transform 225ms;
  -webkit-transition: transform 225ms;
  transition: transform 225ms;
}
.template-iam__custom-pages a:hover .icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.template-iam .layouts #layout-template-iam-0 {
  left: -11%;
  top: 320px;
}
@media (min-width: 1440px) {
  .template-iam .layouts #layout-template-iam-0 {
    left: -5%;
  }
}
.template-iam .layouts #layout-template-iam-1 {
  left: 95%;
  top: 200px;
}

.template-master-page__image {
  width: 100%;
  height: auto;
}
@media (min-width: 1280px) {
  .template-master-page__image {
    width: 36.5625rem;
    height: 36.5625rem;
  }
}

.template-where-are-we .tab-pane {
  display: none !important;
}
.template-where-are-we .tab-pane.active {
  display: block !important;
}
.template-where-are-we ul.nav-tabs {
  border: 0;
}
.template-where-are-we ul.nav-tabs > li.active a svg * {
  fill: #F6922A;
}
.filters-establishments {
  position: relative;
  z-index: 1;
}
.filters-establishments__select-wrapper {
  padding-right: calc(var(--bs-gutter-x) * 0.9);
  padding-left: calc(var(--bs-gutter-x) * 0.9);
}
.filters-establishments__select-wrapper .selectric-wrapper {
  top: -32px;
}
.filters-establishments__select-wrapper .icon {
  position: relative;
  top: 0.375rem;
}
.filters-establishments__select-wrapper select {
  cursor: pointer;
  font-size: 1rem;
}
.filters-establishments__select-wrapper.js-select select {
  overflow: hidden;
  top: 2.0625rem;
}

.template-initial-trainings__items {
  position: relative;
  z-index: 2;
}
.template-initial-trainings__items .training__image {
  height: 25.9375rem;
}
@media (max-width: 767.98px) {
  .template-initial-trainings__items .training__image {
    height: 200px !important;
  }
}
.template-initial-trainings__items .training .more_establishment.active .icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.template-initial-trainings__items .training .more_establishment .icon {
  -moz-transition: transform 225ms;
  -o-transition: transform 225ms;
  -webkit-transition: transform 225ms;
  transition: transform 225ms;
}
.template-initial-trainings__items .training__choice_establishment {
  display: none;
  overflow: auto;
  top: 2.1875rem;
  height: 5rem;
}
.template-initial-trainings__items .training__choice_establishment.active {
  display: block;
}

#site-header-base .layouts {
  height: 17.5rem;
}
.layouts #layout-header-0 {
  top: -100px;
  left: -120px;
}
@media (min-width: 1440px) {
  .layouts #layout-header-0 {
    left: 0;
  }
}
.layouts #layout-header-1 {
  top: -10px;
  left: 99%;
}
@media (min-width: 1440px) {
  .layouts #layout-header-1 {
    left: 94%;
  }
}
.layouts #layout-template-initial-trainings-0 {
  left: 93%;
  top: 900px;
}
.layouts #layout-template-initial-trainings-1 {
  top: 550px;
  left: -200px;
}
@media (min-width: 1440px) {
  .layouts #layout-template-initial-trainings-1 {
    top: 650px;
    left: -150px;
  }
}
.layouts #layout-template-initial-trainings-2 {
  top: 920px;
  left: -40px;
}
@media (min-width: 1440px) {
  .layouts #layout-template-initial-trainings-2 {
    left: 80px;
  }
}

.filters-trainings {
  position: relative;
  z-index: 3;
}
@media (max-width: 767.98px) {
  .filters-trainings__text {
    font-size: 11px !important;
  }
}
@media (min-width: 768px) {
  .filters-trainings__form {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}
.filters-trainings__select-wrapper {
  padding-right: calc(var(--bs-gutter-x) * 0.9);
  padding-left: calc(var(--bs-gutter-x) * 0.9);
}
.filters-trainings__select-wrapper .icon {
  position: relative;
  z-index: 2;
  pointer-events: none;
  top: 2.625rem;
}
.filters-trainings__select-wrapper .icon svg path {
  stroke: #000;
}
.filters-trainings__select-wrapper select {
  cursor: pointer;
}
.filters-trainings__select-wrapper.js-select select {
  overflow: hidden;
  top: 2.1875rem;
}

.template-jobs__items {
  position: relative;
  z-index: 2;
}

.layouts #layout-template-jobs-0 {
  top: 450px;
  left: -250px;
}
.layouts #layout-template-jobs-1 {
  top: 820px;
  left: -40px;
}
@media (min-width: 1440px) {
  .layouts #layout-template-jobs-1 {
    left: 80px;
  }
}

.filters-jobs {
  position: relative;
  z-index: 3;
}
@media (min-width: 768px) {
  .filters-jobs__form {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}
.filters-jobs__select-wrapper {
  padding-right: calc(var(--bs-gutter-x) * 0.9);
  padding-left: calc(var(--bs-gutter-x) * 0.9);
}
.filters-jobs__select-wrapper .icon {
  position: relative;
  z-index: 2;
  pointer-events: none;
  top: 2.625rem;
}
.filters-jobs__select-wrapper .icon svg path {
  stroke: #000;
}
.filters-jobs__select-wrapper select {
  cursor: pointer;
}
.filters-jobs__select-wrapper.js-select select {
  overflow: hidden;
  top: 2.1875rem;
}

.template-our-network__categories {
  position: relative;
  z-index: 2;
}
.template-our-network__categories-item {
  pointer-events: none;
}
.template-our-network__categories-item .layouts {
  pointer-events: auto;
  z-index: -1;
}
.template-our-network .nav-tabs > li.active a, .template-our-network .nav-tabs > li:hover a {
  background-color: #E7EBF5;
}
.template-our-network .nav-tabs > li a {
  -moz-transition: all 600ms;
  -o-transition: all 600ms;
  -webkit-transition: all 600ms;
  transition: all 600ms;
}
.template-our-network .nav-tabs > li a:hover {
  color: #5471BA !important;
}
.template-our-network select#choice-tab {
  background-image: url("../img/icons/arrow-down-white.svg");
  background-size: 16px;
  background-position: center right 20px;
  background-repeat: no-repeat;
}
.template-our-network__networks-item {
  pointer-events: auto;
  height: 22.1875rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.template-our-network__networks-item-image {
  width: 18.75rem;
  height: 5rem;
}
.template-our-network__networks-item-image img {
  object-position: left;
}

.layouts #layout-template-our-network-0 {
  top: 30px;
  left: 30px;
}

.template-news__news {
  position: relative;
  z-index: 2;
}

.news__image {
  height: 25.6875rem;
}
.news-others-items .news__image {
  height: 12.375rem;
}

.layouts #layout-template-news-0 {
  top: 250px;
  left: 87%;
}
.layouts #layout-template-news-1 {
  top: 850px;
  left: -90px;
}
@media (min-width: 1440px) {
  .layouts #layout-template-news-1 {
    top: 900px;
    left: -20px;
  }
}
.layouts #layout-template-news-2 {
  bottom: 200px;
  right: 20px;
}

.template-my-job-search__image {
  height: 29.0625rem;
}

.template-contact form .row {
  margin-bottom: 2.5rem;
  /*&.file-wrapper {
    .wpcf7-form-control-wrap {
      border: 1px solid #707070;
      border-radius: 7px;
      height: 77px;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;

      &::before {
        content: 'Ajouter une pièce jointe';
        display: inline-flex;
        align-items: center;
        background: #EAEAEA;
        border-right: 1px solid #707070;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        padding: 0 20px;
        height: 100%;
        width: 220px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        font-size: 18px;
        color: $black;
        pointer-events: none;
        z-index: 0;
        position: relative;
      }

      .wpcf7-file {
        padding-top: 21px;
        padding-left: 97px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border: 0;

        &::-webkit-file-upload-button {
          visibility: hidden;
        }
      }
    }
  }*/
}
.template-contact form .row label {
  position: relative;
  display: inline-flex;
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
}
.template-contact form .row label > sup {
  position: relative;
  top: 0.8125rem;
  margin-left: 0.125rem;
  font-size: 1.125rem;
}
.template-contact form .row > * > p {
  margin-bottom: 0;
  overflow: hidden;
}
.template-contact form .row > .col-12 > p {
  display: flex;
  flex-direction: column;
}
.template-contact form .row > .col-12 > p .wpcf7-form-control-wrap {
  width: 100%;
}
.template-contact form .row > .col-12 > p .wpcf7-form-control-wrap input {
  position: relative;
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #707070;
  outline: 0;
}
.template-contact form .row > .col-12 > p .wpcf7-form-control-wrap input, .template-contact form .row > .col-12 > p .wpcf7-form-control-wrap input::placeholder {
  color: #898989;
  font-size: 1.125rem;
}
.template-contact form .row > .col-12 > p .wpcf7-form-control-wrap input[type=checkbox] {
  width: 1.1875rem;
  height: 1.1875rem;
  top: 0.125rem;
  margin-right: 0.9375rem;
  border-radius: 3px;
}
.template-contact form .row.civility {
  margin-bottom: 2.8125rem;
  font-family: "Calibri Bold";
}
.template-contact form .row.civility > .col-12 > p {
  flex-direction: row;
}
.template-contact form .row.civility label {
  margin-bottom: 0;
  font-family: "Calibri Regular";
}
.template-contact form .row.civility .wpcf7-radio .wpcf7-list-item {
  margin-left: 2.1875rem;
}
.template-contact form .row.civility .wpcf7-radio .wpcf7-list-item label {
  display: flex;
  align-items: center;
}
.template-contact form .row.civility .wpcf7-radio .wpcf7-list-item input {
  position: relative;
  appearance: none;
  outline: 0;
  top: -0.0625rem;
  margin-right: 0.9375rem;
  border: 0;
  height: 0.6875rem;
}
.template-contact form .row.civility .wpcf7-radio .wpcf7-list-item input:checked:after {
  opacity: 1;
}
.template-contact form .row.civility .wpcf7-radio .wpcf7-list-item input:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  width: 0.6875rem;
  height: 100%;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
}
.template-contact form .row.civility .wpcf7-radio .wpcf7-list-item input:after {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  opacity: 0;
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: #000;
  border-radius: 50%;
}
.template-contact form .row.select-wrapper {
  border-bottom: 1px solid #707070;
  background-repeat: no-repeat;
  background-position: top 5px right calc(var(--bs-gutter-x) * 0.5);
  background-size: 17px;
  background-image: url("../img/icons/icon-arrow-down-black.svg");
}
.template-contact form .row.other, .template-contact form .row.establishment-name, .template-contact form .row.society-name, .template-contact form .row.siret {
  border-bottom: 1px solid #707070;
}
.template-contact form .row.society-name label, .template-contact form .row.establishment-name label {
  font-size: 1rem;
}
.template-contact form .row.object-message {
  border: 1px solid #707070;
  border-radius: 7px;
  padding: 15px;
  align-items: center;
}
.template-contact form .row.object-message label {
  margin-bottom: 0;
}
.template-contact form .row.message textarea {
  width: 100%;
  resize: none;
  padding: 25px 20px;
  height: 15.125rem;
  border: 1px solid #707070;
  border-radius: 7px;
}
.template-contact form .row.message textarea, .template-contact form .row.message textarea::placeholder {
  color: #898989;
  font-size: 1.125rem;
}
.template-contact form .row .wpcf7-text {
  outline: 0;
  border: 0;
}
.template-contact form .row .wpcf7-select,
.template-contact form .row .wpcf7-text {
  color: #898989;
  font-size: 1.125rem;
}
.template-contact form .row .wpcf7-select {
  padding-right: 3rem !important;
  width: 100% !important;
}
.template-contact form .row .wpcf7-acceptance .wpcf7-list-item {
  margin: 0;
}
.template-contact form .row .wpcf7-acceptance .wpcf7-list-item input[name=acceptance] {
  position: relative;
  appearance: none;
  outline: 0;
  top: -0.0625rem;
  margin-right: 0.5rem;
  border: 0;
}
.template-contact form .row .wpcf7-acceptance .wpcf7-list-item input[name=acceptance]:checked:after {
  transform: scale(1) translate(-50%, -50%);
}
.template-contact form .row .wpcf7-acceptance .wpcf7-list-item input[name=acceptance]:before {
  content: "";
  position: relative;
  display: inline-flex;
  width: 1.1875rem;
  height: 1.1875rem;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 3px;
}
.template-contact form .row .wpcf7-acceptance .wpcf7-list-item input[name=acceptance]:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: scale(0) translate(-50%, -50%);
  width: 1.1875rem;
  height: 1.1875rem;
  background-image: url("../img/icons/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  background-color: #000;
  border-radius: 3px;
}

@media (max-width: 1279.98px) {
  .establishment__header__buttons {
    width: 100% !important;
  }
}
.establishment-testimonials__items-item .plyr, .establishment-testimonials__items-item-image {
  height: 30.3125rem;
}
.establishment-testimonials__items-item .plyr {
  border: 0;
  width: 100%;
}
.establishment-testimonials__items-item .plyr .plyr__control {
  background: #F6922A;
  opacity: 1;
  -moz-transition: background 600ms;
  -o-transition: background 600ms;
  -webkit-transition: background 600ms;
  transition: background 600ms;
}
@media (min-width: 992px) {
  .establishment-testimonials__items-item .plyr .plyr__control {
    padding: calc(var(--plyr-control-spacing, 10px) * 2.25);
  }
}
@media (min-width: 992px) {
  .establishment-testimonials__items-item .plyr .plyr__control svg {
    height: var(--plyr-control-icon-size, 35px);
    width: var(--plyr-control-icon-size, 35px);
  }
}
.establishment-testimonials__items-item .plyr .plyr__control:hover {
  background: #5471BA;
}
.establishment-testimonials__items-item .plyr.plyr--playing .plyr__poster {
  display: none;
}
.establishment-testimonials__items-item .plyr .plyr__progress__container,
.establishment-testimonials__items-item .plyr .plyr__time,
.establishment-testimonials__items-item .plyr .plyr__volume,
.establishment-testimonials__items-item .plyr .plyr__menu {
  display: none;
}
.establishment-testimonials__items-item .plyr .plyr__video-wrapper {
  padding-bottom: 0;
  padding-top: 0;
  height: 100%;
}
.establishment-testimonials__items-item .plyr .plyr__video-wrapper .plyr__poster {
  background-size: cover;
  transform: scale(1.01);
}
.establishment-testimonials__slider-nav {
  position: relative;
  top: auto;
}
@media (min-width: 992px) {
  .establishment-testimonials__slider-nav {
    top: -3.125rem;
  }
}
@media (min-width: 1280px) {
  .establishment-testimonials__slider-nav {
    top: -10.9375rem;
  }
}
.establishment__push-logo {
  width: 15.125rem;
  height: 15.125rem;
  right: -6.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.establishment__push-logo img {
  width: 9.375rem;
  height: 9.375rem;
}
.establishment__others-item {
  height: 14.6875rem;
}
@media (max-width: 991.98px) {
  .establishment__schema .icon {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

#layout-establishment__schema-0 {
  left: -17%;
  top: 0;
}
@media (min-width: 1440px) {
  #layout-establishment__schema-0 {
    left: -10%;
    top: 50px;
  }
}
#layout-establishment__schema-1 {
  left: 5%;
  top: 620px;
}
@media (min-width: 1440px) {
  #layout-establishment__schema-1 {
    left: 10%;
  }
}
#layout-establishment__schema-2 {
  left: 94%;
  top: 500px;
}
@media (min-width: 1440px) {
  #layout-establishment__schema-2 {
    left: 90%;
  }
}

.job-header__description em {
  font-size: 1rem;
  color: #898989;
}
.job-header__video {
  height: 25.9375rem;
}
.job-header__video .plyr {
  height: 100%;
  border: 0;
  border-radius: var(--bs-border-radius-sm);
}
.job-header__video .plyr .plyr__video-wrapper {
  padding-top: 0;
  height: 100%;
}

.news-header {
  position: relative;
  z-index: 2;
}
.news-header__image {
  height: 25.6875rem;
}

.layouts #layout-single-news-0 {
  top: 250px;
  left: 95%;
}
.layouts #layout-single-news-1 {
  top: 850px;
  left: -90px;
}
@media (min-width: 1440px) {
  .layouts #layout-single-news-1 {
    top: 900px;
    left: -20px;
  }
}

.training-header__description em {
  font-size: 1rem;
  color: #898989;
}
.training-header__video {
  height: 25.9375rem;
}
@media (min-width: 1440px) {
  .training-header__video {
    height: 30rem;
  }
}
.training-header__video .plyr {
  height: 100%;
  border: 0;
  border-radius: var(--bs-border-radius-sm);
}
.training-header__video .plyr .plyr__video-wrapper {
  padding-top: 0;
  height: 100%;
}
.training-testimonials__items-item .plyr, .training-testimonials__items-item-image {
  height: 30.3125rem;
}
.training-testimonials__items-item .plyr {
  border: 0;
  width: 100%;
}
.training-testimonials__items-item .plyr .plyr__control {
  background: #F6922A;
  opacity: 1;
  -moz-transition: background 600ms;
  -o-transition: background 600ms;
  -webkit-transition: background 600ms;
  transition: background 600ms;
}
@media (min-width: 992px) {
  .training-testimonials__items-item .plyr .plyr__control {
    padding: calc(var(--plyr-control-spacing, 10px) * 2.25);
  }
}
@media (min-width: 992px) {
  .training-testimonials__items-item .plyr .plyr__control svg {
    height: var(--plyr-control-icon-size, 35px);
    width: var(--plyr-control-icon-size, 35px);
  }
}
.training-testimonials__items-item .plyr .plyr__control:hover {
  background: #5471BA;
}
.training-testimonials__items-item .plyr.plyr--playing .plyr__poster {
  display: none;
}
.training-testimonials__items-item .plyr .plyr__progress__container,
.training-testimonials__items-item .plyr .plyr__time,
.training-testimonials__items-item .plyr .plyr__volume,
.training-testimonials__items-item .plyr .plyr__menu {
  display: none;
}
.training-testimonials__items-item .plyr .plyr__video-wrapper {
  padding-bottom: 0;
  padding-top: 0;
  height: 100%;
}
.training-testimonials__items-item .plyr .plyr__video-wrapper .plyr__poster {
  background-size: cover;
  transform: scale(1.01);
}
@media (min-width: 1280px) {
  .training-testimonials__slider-nav {
    position: relative;
    top: -10.9375rem;
  }
}
.training__choice_establishment {
  display: none;
  overflow: auto;
  top: 2.1875rem;
  height: 5rem;
}
.training__choice_establishment.active {
  display: block;
}

.more_establishment.active .icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.more_establishment .icon {
  -moz-transition: transform 225ms;
  -o-transition: transform 225ms;
  -webkit-transition: transform 225ms;
  transition: transform 225ms;
}

@media (max-width: 767.98px) {
  .network__block-1-title, .network__block-1-text {
    text-align: left !important;
  }
}