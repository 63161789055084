//Map
.establishments {
  .establishment {
    &.active {
      background-color: transparentize($gray_light, .82);
    }

    &__link {
      @include toRem(right, 30);
      @include toRem(bottom, 30);
    }

    &__sectors {
      column-count: 2;

      &-item {
        position: relative;
        @include toRem(padding-left, 12);

        &:before {
          content: '';
          position: absolute;
          background-color: $blue2;
          @include toRem(top, 9);
          @include toRem(left, 0);
          @include toRem(width, 6);
          @include toRem(height, 6);
          border-radius: 50%;
        }
      }
    }
  }
}