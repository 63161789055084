//Newsletter
.newsletter {
  &__bg {
    z-index: -1;
    left: -10%;
    @include transform(rotate(1deg));
  }

  &__input {
    @include toRem(height, 80);
  }

  &__checkbox {
    cursor: pointer;
  }

  input {
    outline: 0;
    cursor: pointer;

    &[type="checkbox"] {
      position: relative;
      appearance: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;
      border-radius: 5px;
      border: 1px solid $black;
      @include toRem(top, 3);
      @include toRem(width, 19);
      @include toRem(height, 19);

      &:checked {
        background-color: $black;
        background-image: url("../img/icons/check.svg");
      }
    }
  }

  button[type="submit"] {
    @include toRem(width, 30);
    @include toRem(height, 30);
    display: flex;
    justify-content: center;
    align-items: center;

    > .icon {
      display: inline-flex;
      @include toRem(width, 17);
      @include toRem(height, 17);
      background-image: url("../img/icons/arrow-rotate.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @include transition(transform 225ms);
    }

    &:hover > .icon {
      @include transform(rotate(45deg));
    }
  }
}