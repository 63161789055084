//Template "Our network"
.template-our-network {
  &__categories {
    position: relative;
    z-index: 2;

    &-item {
      pointer-events: none;

      .layouts {
        pointer-events: auto;
        z-index: -1;
      }
    }
  }

  .nav-tabs {
    > li {
      &.active,
      &:hover {
        a {
          background-color: $blue3;
        }
      }

      a {
        @include transition(all 600ms);

        &:hover {
          color: $blue !important;
        }
      }
    }
  }

  select#choice-tab {
    background-image: url("../img/icons/arrow-down-white.svg");
    background-size: 16px;
    background-position: center right 20px;
    background-repeat: no-repeat;
  }

  &__networks-item {
    pointer-events: auto;
    @include toRem(height, 355);
    box-shadow: 0 4px 8px transparentize($black, .85);

    &-image {
      @include toRem(width, 300);
      @include toRem(height, 80);

      img {
        object-position: left;
      }
    }
  }
}

.layouts {
  #layout {
    &-template-our-network {
      &-0 {
        top: 30px;
        left: 30px;
      }
    }
  }
}