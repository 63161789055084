// Bloc "Informations 3"
.infos_3 {
  &__infos-info-title {
    position: relative;

    &:after {
      content: '';
      position: relative;
      display: inline-flex;
      background-color: $orange;
      border-radius: 50%;
      @include toRem(width, 10);
      @include toRem(height, 10);
      @include toRem(top, -2);
      @include toRem(margin-left, 15);
    }
  }
}